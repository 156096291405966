import { fetchJobDetailsByShareUrl } from 'apppages/Home/jobs/jobServices';
import axiosInstance from '../utils/axiosConfig';

// Auth APIs
export const authService = {
  login: (data) => axiosInstance.post('auth/login', data),
  refreshToken: () => axiosInstance.post('auth/refresh'),
  logout: () => axiosInstance.post('auth/logout'),
  signout: (memberUuid) => axiosInstance.post('auth/logout', { memberuuid: memberUuid }),
  authenticateToken: () => axiosInstance.get('auth/authenticatetoken'),
};

// Member APIs
export const memberService = {
  getProfile: (memberId) => axiosInstance.get(`members/short/${memberId}`),
  getPhotoUrl: (fileName) => axiosInstance.get(`members/filepath/${fileName}`),
  updateProfile: (data) => axiosInstance.put('members/update', data),
  getPreferences: (memberId) => axiosInstance.get(`preference/fullpreference/${memberId}`),
  getAllianceSeekers: (pageNo, pageSize, searchCriteria) => 
    axiosInstance.post('membersmgmt/getAllianceSeekers', {
      pageNo,
      pageSize,
      searchCriteria
    }),
  getPartnerPreferences: (memberUUID) => 
    axiosInstance.get(`preference/fullpreference/${memberUUID}`),
  getProfileDetails: (memberUUID) => 
    axiosInstance.get(`members/${memberUUID}`),
  getCommunityMembers: (pageNo, pageSize, searchCriteria) => 
    axiosInstance.post('membersmgmt/getCommunityMembers', {
      pageNo,
      pageSize,
      searchCriteria
    }),
  getFullDetails: (memberUUID) => 
    axiosInstance.get(`members/fulldetails/${memberUUID}`),
  getConnectedMembers: (pagination) => axiosInstance.post('membersmgmt/getConnectedMembers', pagination), 
};

// Connection APIs
export const connectionService = {
  requestConnection: (data) => axiosInstance.post('connection/requestforconnection', data),
  getConnections: () => axiosInstance.get('connection/list'),
  acceptConnection: (id) => axiosInstance.put(`connection/accept/${id}`),
  sendConnectionRequest: (request) => 
    axiosInstance.post('connection/requestforconnection', request),
  checkConnectionStatus: (memberUUID) => 
    axiosInstance.get(`connection/status/${memberUUID}`),
  getConnectedMembers: (data) => axiosInstance.post('connection/connectedmembers', data),
  getPendingConnections: (data) => axiosInstance.post('connection/pendingconnections', data),
  updateConnection: (data) => axiosInstance.post('connection/updateconnection', data),
  getFullMemberDetails: (memberUuid) => axiosInstance.get(`members/fulldetails/${memberUuid}`)
};

// Search APIs
export const searchService = {
  searchMembers: (criteria) => axiosInstance.post('search/members', criteria),
  searchByFilters: (filters) => axiosInstance.post('search/filters', filters),
  getSearchSuggestions: (query) => axiosInstance.get(`search/suggestions?q=${query}`),
  getSavedSearches: () => axiosInstance.get('search/saved'),
  saveSearch: (searchCriteria) => axiosInstance.post('search/save', searchCriteria)
};

// Favourite APIs
export const favouriteService = {
  addFavourite: (data) => axiosInstance.post('members/favourites', data),
  removeFavourite: (memberId) => axiosInstance.delete(`members/favourites/${memberId}`),
  getFavouriteStatus: (memberId) => axiosInstance.get(`members/favourites/status/${memberId}`),
  addCommunityFavourite: (data) => axiosInstance.post('members/favourites', {
    ...data,
    membertypeid: 4 // Community member type
  }),
  getFavouriteMembers: (memberId) => axiosInstance.get(`members/favourites/${memberId}`),
  addFavouriteMember: (data) => axiosInstance.post('members/favourites', data),
  removeFavourite: (data) => axiosInstance.post('members/favourites/remove', data),
  getFavouritesList: (pageNo, pageSize) => axiosInstance.post('members/favourites/list', {
    pageNo,
    pageSize
  })
};

// Event APIs
export const eventService = {
  fetchEventCategories: () => axiosInstance.get('eb/getebcategories'),
  fetchEvents: (params) => axiosInstance.post('eb/fetchevents', params),
  getEventDetails: (eventuuid) => axiosInstance.get(`eb/fetchEbPost/${eventuuid}`),
  getEventBySlug: (slug) => axiosInstance.get(`eb/share-event/${slug}`),
  getUrlPreview: (url) => axiosInstance.get(`eb/org-url-preview?url=${encodeURIComponent(url)}`),
  fetchParticipants: (eventuuid, pageIndex, pageSize) => 
    axiosInstance.post('eb/fetchParticipants', {
      eventuuid,
      pageIndex,
      pageSize
    }),
  createEvent: (formData) => axiosInstance.post('eb/addebpost', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }),
  updateEvent: (eventuuid, formData) => axiosInstance.put(`eb/updateebpost/${eventuuid}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }),
  getEventDetails: (eventuuid) => axiosInstance.get(`eb/fetchEbPost/${eventuuid}`),
  registerEvent: (data) => axiosInstance.post('eb/register', data),
  deleteEvent: (eventuuid) => axiosInstance.delete(`eb/deleteebpost/${eventuuid}`),
};

// Job APIs
export const jobService = {
  fetchJobCategories: () => axiosInstance.get('jobmgmt/fetchCategories'),
  fetchJobPostings: (params) => axiosInstance.post('jobmgmt/fetchJobPostings', params),
  fetchJobDetails: (jobuuid) => axiosInstance.get(`jobmgmt/fetchJobPosting/${jobuuid}`),
  fetchJobDetailsByShareUrl: (job_share_url) => axiosInstance.get(`jobmgmt/fetchJobPostingBySharedUrl/${job_share_url}`),
  addJobPosting: (jobData) => axiosInstance.post('jobmgmt/addJobPosting', jobData),
  editJobPosting: (jobData) => axiosInstance.post('jobmgmt/updateJobPosting', jobData),
};

// Mentor APIs
export const mentorService = {
  fetchMentors: (param) => axiosInstance.post('mentorship/listMentors', param),
  fetchCategoriesAndSubcategories: () => axiosInstance.get('mentorship/categories-with-subcategories'),
  fetchCategories: () => axiosInstance.get('mentorship/categories'),
  fetchSubCategories: (categoryIds) => axiosInstance.get('mentorship/subcategories', { params: { categories: categoryIds.join(',') } }),
  fetchMentorsByCategory: (categoryId, pageIndex) => axiosInstance.get('mentorship/fetch-mentors-categories', { params: { categoryid: categoryId, pageindex: pageIndex } }),
  fetchMenteesByCategory: (categoryId, pageIndex) => axiosInstance.get('mentee/fetch-mentees-categories', { params: { categoryid: categoryId, pageindex: pageIndex } }),
  fetchCategoryStats: () => axiosInstance.get('mentorship/category-stats'),
  fetchMenteeCategoryStats: () => axiosInstance.get('mentee/mentee-category-stats'),
  updateMentorStatus: (data) => axiosInstance.post('mentorship/update-mentor-status', data),
  deleteMentor: (mentorUuid) => axiosInstance.delete(`mentorship/delete-mentor/${mentorUuid}`),
  fetchMentorStatus: () => axiosInstance.get(`mentorship/mentor-status`),
  fetchMentorDetails: () => axiosInstance.get(`mentorship/fetch-mentor-details`),
  becomeMentor: (mentorData) => axiosInstance.post('mentorship/become-mentor', mentorData),
};

// Mentee APIs
export const menteeService = {
  getMenteeStatus: () => axiosInstance.get('mentee/mentee-status'),
  fetchMenteeDetails: () => axiosInstance.get('mentee/fetch-mentee-details'),
  becomeMentee: (data) => axiosInstance.post('mentee/become-mentee', data),
  updateMenteeStatus: (data) => axiosInstance.post(`mentee/update-mentee-status`, data),
  deleteMentee: (menteeUuid) => axiosInstance.delete(`mentee/delete-mentee/${menteeUuid}`),
  listMentees: (params) => axiosInstance.post('mentee/listMentees', params),
  fetchMenteesByCategory: (categoryId, pageIndex) => 
    axiosInstance.get('mentee/fetch-mentees-categories', {
      params: { categoryid: categoryId, pageindex: pageIndex }
    }),
  fetchMenteeCategoryStats: () => axiosInstance.get('mentee/mentee-category-stats'),
};

// Profile APIs
export const profileService = {
  getShortProfile: (memberId) => axiosInstance.get(`members/short/${memberId}`),
  getFullDetails: (memberId) => axiosInstance.get(`members/fulldetails/${memberId}`),
  uploadImage: (formData) => axiosInstance.post('members/uploadImage', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  deleteImage: (data) => axiosInstance.post('members/deleteImage', data),
  getPhotoUrl: (fileName) => axiosInstance.get(`members/filepath/${fileName}`),
  getBasicProfile: (memberId) => axiosInstance.get(`members/basic/${memberId}`),
  getPersonalProfile: (memberId) => axiosInstance.get(`members/personal/${memberId}`),
  updatePersonalProfile: (data) => axiosInstance.post('members/personal/', data)
};

export const codesService = {
  getComplexion: () => axiosInstance.get('codes/complexion'),
  getMaritalStatus: () => axiosInstance.get('codes/maritalstatus'),
  getColors: () => axiosInstance.get('codes/color'),
  getGenders: () => axiosInstance.get('codes/gender'),
  getBloodGroups: () => axiosInstance.get('codes/bloodgroup'),
  getDegreeLevel: () => axiosInstance.get('codes/degreelevel'),
  getDegreeNames: () => axiosInstance.get('codes/degreenames'),
  getCompanyTypes: () => axiosInstance.get('codes/companytype'),
  getCompanyStatuses: () => axiosInstance.get('codes/companystatus'),
  getSalaryCurrencies: () => axiosInstance.get('codes/salarycurrency'),
  getOccupationalCategories: () => axiosInstance.get('codes/occupationalcategory'),
  getSkillsets: () => axiosInstance.get('codes/skillset'),
  getDietPreferences: () => axiosInstance.get('codes/dietpref'),
  getSmokingHabits: () => axiosInstance.get('codes/smokinghabit'),
  getDrinkingHabits: () => axiosInstance.get('codes/drinkinghabit'),
  getGames: () => axiosInstance.get('codes/games'),
  getExercise: () => axiosInstance.get('codes/exercise'),
  getPartOfNGO: () => axiosInstance.get('codes/partofNGO'),
  getPetPreferences: () => axiosInstance.get('codes/petpref'),
  getLanguages: () => axiosInstance.get('codes/language'),
  getReligions: () => axiosInstance.get('codes/religion'),
  getCastes: () => axiosInstance.get('codes/caste')
};

export const educationService = {
  getEducation: (memberId) => axiosInstance.get(`members/educational/${memberId}`),
  updateEducation: (data) => axiosInstance.post('members/educational/', data)
};

export const careerService = {
  getCareerDetails: (memberId) => axiosInstance.get(`members/occupation/${memberId}`),
  updateCareerDetails: (data) => axiosInstance.post('members/occupation/', data)
};

export const familyService = {
  getFamilyDetails: (memberId) => axiosInstance.get(`members/family/${memberId}`),
  updateFamilyDetails: (data) => axiosInstance.post('members/family/', data)
};

export const lifestyleService = {
  getLifestyleDetails: (memberId) => axiosInstance.get(`members/lifestyle/${memberId}`),
  updateLifestyleDetails: (data) => axiosInstance.post('members/lifestyle/', data)
};

export const regionalService = {
  getRegionalDetails: (memberId) => axiosInstance.get(`members/regional/${memberId}`),
  updateRegionalDetails: (data) => axiosInstance.post('members/regional/', data)
};

export const settingsService = {
  getRecommenderDetails: (uuid) => axiosInstance.get(`members/short/${uuid}`),
  changeRecommender: (data) => axiosInstance.post('members/changerecommender', data),
  changePassword: (data) => axiosInstance.post('members/changepassword/', data),
  changeActivationStatus: (data) => axiosInstance.post('members/changeactivationstatus', data),
  updateRole: (data) => axiosInstance.post('members/updaterole', data),
  deleteMember: (data) => axiosInstance.post('members/deletemember', data)
};

export const socialService = {
  getSocialDetails: (memberId) => axiosInstance.get(`members/social/${memberId}`),
  updateSocialDetails: (data) => axiosInstance.post('members/social/', data)
};

export const spiritualService = {
  getSpiritualDetails: (memberId) => axiosInstance.get(`members/spiritual/${memberId}`),
  updateSpiritualDetails: (data) => axiosInstance.post('members/spiritual/', data)
};

export const preferenceService = {
  // Career preferences
  getCareerPreference: (memberId) => axiosInstance.get(`preference/occupation/${memberId}`),
  updateCareerPreference: (data) => axiosInstance.post('preference/occupation', data),

  // Education preferences  
  getEducationPreference: (memberId) => axiosInstance.get(`preference/educational/${memberId}`),
  updateEducationPreference: (data) => axiosInstance.post('preference/educational', data),

  // Family preferences
  getFamilyPreference: (memberId) => axiosInstance.get(`preference/familybg/${memberId}`),
  updateFamilyPreference: (data) => axiosInstance.post('preference/familybg', data),

  // Lifestyle preferences
  getLifestylePreference: (memberId) => axiosInstance.get(`preference/lifestyle/${memberId}`),
  updateLifestylePreference: (data) => axiosInstance.post('preference/lifestyle', data),

  // Personal preferences
  getPersonalPreference: (memberId) => axiosInstance.get(`preference/personal/${memberId}`),
  updatePersonalPreference: (data) => axiosInstance.post('preference/personal', data),

  // Regional preferences
  getRegionalPreference: (memberId) => axiosInstance.get(`preference/regional/${memberId}`),
  updateRegionalPreference: (data) => axiosInstance.post('preference/regional', data),

  // Spiritual preferences
  getSpiritualPreference: (memberId) => axiosInstance.get(`preference/spiritual/${memberId}`),
  updateSpiritualPreference: (data) => axiosInstance.post('preference/spiritual', data)
};

// Referral APIs
export const referralService = {
  getPendingReferrals: (memberId) => axiosInstance.get(`members/pendingrecommendation/${memberId}`),
  updateReferral: (data) => axiosInstance.post('members/updaterecommendation', data),
  getRecommendedMembers: (pageNo, pageSize) => axiosInstance.post(
    'membersmgmt/getRecommendedMembers',
    { pageNo, pageSize }
  )
};

// Registration APIs
export const registrationService = {
  validateOtp: (data) => axiosInstance.post('registration/validate', data),
  resendOtp: (data) => axiosInstance.post('registration/resendOTPForVerification', data),
};

// Payment APIs
export const paymentService = {
  createPaymentOrder: (data) => axiosInstance.post('subscription/create-payment-order', data),
  verifyPayment: (data) => axiosInstance.post('subscription/verify-payment', data)
};

export const subscriptionService = {
  paymentSuccess: (paymentPayload) => axiosInstance.post('subscription/paymentsuccess', paymentPayload),
  createOrder : (createOrderData) => axiosInstance.post('subscription/createorder', createOrderData),  
};

// Admin APIs
export const adminService = {
  getAllMembers: (data) => axiosInstance.post('members/allmemberbasicdetails', data),
  changeActivationStatus: (data) => axiosInstance.post('members/changeactivationstatus', data),
  getFullMemberDetails: (memberUuid) => axiosInstance.get(`members/fulldetails/${memberUuid}`)
};
