import React, { useState, useEffect } from 'react';
import {
  Col,
  Card,
  CardBody,
  Badge,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Alert,
} from 'reactstrap';
import classnames from 'classnames';
import { FaGlobe, FaUser, FaBookmark } from 'react-icons/fa';
import eventImage from '../../../assets/images/MettaMateImages/eventSamples/defaultEventImage.png';
import EventCard from './EventCard';
import SearchEvent from './SearchEvent';
import { useNavigate, Link } from 'react-router-dom';
import { Plus } from 'lucide-react';
import eventtitleicon from '../../../assets/images/event/eventtitleicon.png';
import { fetchEvents } from './eventServices';
import { Loader } from 'lucide-react';
import { message } from 'antd';
import { Calendar as CalendarIcon, PlusCircle } from 'lucide-react';
import { useApi } from '../../../hooks/useApi';

// EventListingPage component
const EventListingPage = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastFilters, setLastFilters] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 12,
    totalCount: 0,
    pageCount: 0,
  });
  const { callApi } = useApi();

  const getCurrentDateMinusOne = () => {
    const today = new Date();
    today.setDate(today.getDate() - 15);
    return today.toISOString().split('T')[0];
  };

  const fetchEventData = async (page = 1, filters = {}) => {
    setLoading(true);
    try {
      const eventType =
        activeTab === '1' ? 'global' : activeTab === '3' ? 'bookmarked' : 'my';

      const response = await fetchEvents(callApi, {
        pageIndex: page,
        pageSize: pagination.pageSize,
        ...filters,
        isBookmarked: eventType === 'bookmarked',
        isMyEvents: eventType === 'my',
      });

      if (response && response.events) {
        setEvents(response.events);
        // Update pagination with the response data
        if (response.pagination) {
          setPagination(prev => ({
            ...prev,
            currentPage: parseInt(response.pagination.pageIndex),
            totalCount: parseInt(response.pagination.totalCount),
            pageCount: parseInt(response.pagination.totalPages),
          }));
        }
      }
    } catch (error) {
      message.error('Failed to fetch events');
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle tab changes
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      // If we have last used filters, apply them to the new tab
      if (lastFilters) {
        const eventType =
          tab === '1' ? 'global' : tab === '3' ? 'bookmarked' : 'my';
        fetchEvents(lastFilters, eventType);
      }
    }
  };

  // Handle search from SearchEvent component
  const handleSearch = (filters) => {
    setLastFilters(filters);
    setPagination(prev => ({
      ...prev,
      currentPage: 1 // Reset to first page on new search
    }));
    fetchEventData(1, filters);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage !== pagination.currentPage) {
      setPagination(prev => ({
        ...prev,
        currentPage: newPage
      }));
      fetchEventData(newPage, lastFilters || {});
    }
  };

  // Initial fetch on component mount
  useEffect(() => {
    const initialFilters = {
      searchText: '',
      categories: [],
      timeFilter: 'all',
      startDate: getCurrentDateMinusOne(),
      // ...other default filters
    };
    fetchEventData(1, initialFilters);

    // Check if user is authenticated (adjust this according to your auth method)
    const userToken = localStorage.getItem('token');
    setIsAuthenticated(!!userToken);
  }, [activeTab]); // Refetch when tab changes

  const tabStyle = {
    transition: 'all 0.3s ease',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    minWidth: '140px',
    border: '1px solid #dee2e6',
    marginTop: '2px', // Add margin to compensate for the upward movement
    WebkitOverflowScrolling: 'touch', // Correct camelCase format
  };

  const activeTabStyle = {
    transform: 'translateY(-2px)',
    boxShadow: '0 2px 4px rgba(0,123,255,0.2)',
    border: '1px solid #007bff',
    position: 'relative',
    zIndex: 1,
    marginTop: '2px', // Reset margin when active
  };

  const getDateRangeForTimeFilter = (timeFilter) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    switch (timeFilter) {
      case 'today':
        return {
          start: today,
          end: today,
        };
      case 'tomorrow':
        return {
          start: tomorrow,
          end: tomorrow,
        };
      case 'thisWeek': {
        const endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
        return {
          start: today,
          end: endOfWeek,
        };
      }
      case 'weekend': {
        const saturday = new Date(today);
        saturday.setDate(today.getDate() + (6 - today.getDay()));
        const sunday = new Date(saturday);
        sunday.setDate(saturday.getDate() + 1);
        return {
          start: saturday,
          end: sunday,
        };
      }
      case 'nextWeek': {
        const nextWeekStart = new Date(today);
        nextWeekStart.setDate(today.getDate() + (7 - today.getDay()) + 1);
        const nextWeekEnd = new Date(nextWeekStart);
        nextWeekEnd.setDate(nextWeekStart.getDate() + 6);
        return {
          start: nextWeekStart,
          end: nextWeekEnd,
        };
      }
      default:
        return null;
    }
  };

  const handleEditEvent = (eventId) => {
    // alert("Am I coming here with the right eventId?"+eventId);
    navigate(`/eventsedit/${eventId}`); // Updated to point to new form
  };

  const handleCreateEvent = () => {
    navigate('/events/create'); // Updated to point to new form
  };

  const renderEventsList = () => (
    <div>
      <Row className="g-4">
        {loading ? (
          <Col xs={12} className="text-center py-5">
            <Loader className="animate-spin" size={40} />
            <p className="text-muted mt-2">Loading events...</p>
          </Col>
        ) : events.length > 0 ? (
          <>
            {events.map((event) => (
              <Col key={event.eventuuid} lg={4} md={6}>
                <EventCard
                  event={event}
                  isBookmarked={activeTab === '3'}
                  canEdit={activeTab === '2'}
                  onEdit={
                    activeTab === '2'
                      ? () => handleEditEvent(event.eventuuid)
                      : undefined
                  }
                />
              </Col>
            ))}

            {/* Pagination */}
            {pagination.pageCount > 1 && (
              <Col xs={12} className="mt-4">
                <nav className="d-flex justify-content-center">
                  <ul className="pagination">
                    <li
                      className={`page-item ${pagination.currentPage === 1 ? 'disabled' : ''}`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageChange(pagination.currentPage - 1)
                        }
                        disabled={pagination.currentPage === 1}
                      >
                        Previous
                      </button>
                    </li>

                    {/* Add page numbers with ellipsis for large page counts */}
                    {[...Array(pagination.pageCount)].map((_, index) => {
                      const pageNumber = index + 1;
                      const isCurrentPage = pageNumber === pagination.currentPage;
                      const isWithinRange = 
                        pageNumber <= 2 || 
                        pageNumber >= pagination.pageCount - 1 || 
                        Math.abs(pageNumber - pagination.currentPage) <= 1;

                      if (!isWithinRange) {
                        if (pageNumber === 3 || pageNumber === pagination.pageCount - 2) {
                          return <li key={pageNumber} className="page-item disabled"><span className="page-link">...</span></li>;
                        }
                        return null;
                      }

                      return (
                        <li
                          key={pageNumber}
                          className={`page-item ${isCurrentPage ? 'active' : ''}`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      );
                    })}

                    <li
                      className={`page-item ${pagination.currentPage === pagination.pageCount ? 'disabled' : ''}`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageChange(pagination.currentPage + 1)
                        }
                        disabled={
                          pagination.currentPage === pagination.pageCount
                        }
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </Col>
            )}
          </>
        ) : (
          <Col xs={12} className="text-center py-5">
            <p className="text-muted">No events found</p>
          </Col>
        )}
      </Row>
    </div>
  );

  return (
    <React.Fragment>
      <section className="section">
        {' '}
        {/* Added mt-5 class */}
        <Container>
          {/* Header Section */}
          <Row className="">
            <Col className="text-center mb-2">
              <p className="text-center fs-5 text-dark">
                Discover and thrive in your community events!
              </p>
              <Link 
                to="/subscriptionplan" 
                className="text-decoration-none subscription-link"
                style={{
                  background: 'linear-gradient(45deg, #2d5fb4, #0d6efd)',
                  fontWeight: '800',
                  fontSize: '1.1rem',
                  color: 'white',
                }}
              >
                ✨ Subscribe and Support MettaMate ✨
              </Link>
            </Col>
          </Row>

          {isAuthenticated ? (
            <>
              {/* Tabs Section - Only shown when authenticated */}
              <Row className="mb-4">
                <Col xs={12}>
                  <div
                    className="d-flex overflow-auto pb-2"
                    style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
                  >
                    <div className="d-flex gap-3">
                      {[
                        { id: '1', icon: FaGlobe, title: 'Global Events' },
                        { id: '2', icon: FaUser, title: 'My Events' },
                        { id: '3', icon: FaBookmark, title: 'Bookmarked' },
                      ].map((tab) => (
                        <Card
                          key={tab.id}
                          className={classnames({
                            'bg-light': activeTab === tab.id,
                          })}
                          onClick={() => toggle(tab.id)}
                          style={{
                            ...tabStyle,
                            ...(activeTab === tab.id ? activeTabStyle : {}),
                            cursor: 'pointer',
                          }}
                        >
                          <CardBody className="d-flex align-items-center gap-2 py-2 px-3">
                            <tab.icon
                              size={20}
                              className={
                                activeTab === tab.id
                                  ? 'text-primary'
                                  : 'text-dark'
                              }
                            />
                            <span
                              className={`${activeTab === tab.id ? 'text-primary' : 'text-dark'}`}
                            >
                              {tab.title}
                            </span>
                          </CardBody>
                        </Card>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>

              {/* TabContent - Only shown when authenticated */}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                <div className="d-flex justify-content-between align-items-center mb-3">
                      <Button
                        color="primary"
                        className="d-flex align-items-center gap-2 px-3 py-2 w-100 w-sm-auto"
                        onClick={handleCreateEvent}
                        style={{
                          background: 'linear-gradient(135deg, #2563EB, #1E40AF)',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: '0 2px 4px rgba(37, 99, 235, 0.2)',
                          transition: 'all 0.3s ease',
                          maxWidth: '100%',
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'translateY(-1px)';
                          e.currentTarget.style.boxShadow = '0 4px 12px rgba(37, 99, 235, 0.3)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = '0 2px 4px rgba(37, 99, 235, 0.2)';
                        }}
                      >
                        <PlusCircle className="flex-shrink-0" size={20} />
                        <span className="fw-semibold">Post Your Event</span>
                      </Button>
                    </div>
                  <SearchEvent onSearch={handleSearch} />
                  {renderEventsList()}
                </TabPane>

                <TabPane tabId="2">
                  <div className="mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Button
                        color="primary"
                        className="d-flex align-items-center gap-2 px-3 py-2 w-100 w-sm-auto"
                        onClick={handleCreateEvent}
                        style={{
                          background: 'linear-gradient(135deg, #2563EB, #1E40AF)',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: '0 2px 4px rgba(37, 99, 235, 0.2)',
                          transition: 'all 0.3s ease',
                          maxWidth: '100%',
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'translateY(-1px)';
                          e.currentTarget.style.boxShadow = '0 4px 12px rgba(37, 99, 235, 0.3)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = '0 2px 4px rgba(37, 99, 235, 0.2)';
                        }}
                      >
                        <PlusCircle className="flex-shrink-0" size={20} />
                        <span className="fw-semibold">Post Your Event</span>
                      </Button>
                    </div>
                    <SearchEvent onSearch={handleSearch} />
                  </div>
                  {renderEventsList()}
                </TabPane>

                <TabPane tabId="3">
                  <SearchEvent onSearch={handleSearch} />
                  {renderEventsList()}
                </TabPane>
              </TabContent>
            </>
          ) : (
            // Non-authenticated view - only shows global events
            <>
              <SearchEvent onSearch={handleSearch} />
              {renderEventsList()}
            </>
          )}
        </Container>
      </section>
      <style>
        {`
          @media (max-width: 576px) {
            .w-sm-auto {
              width: 100% !important;
            }
            
            .d-sm-none {
              margin-bottom: 1rem;
            }
          }

          @media (min-width: 577px) {
            .d-sm-none {
              display: none !important;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default EventListingPage;
