import React, { useEffect, useMemo, useState } from 'react';
import '../../Home/css/userHomePage.css';
import CommonModal from '../modal.js';
import apiUrl from '../../server.js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';
import { Row, Col, PaginationLink, PaginationItem } from 'reactstrap';

import ContentLoader, { List } from 'react-content-loader';
import { useApi } from '../../../hooks/useApi';
import { referralService } from '../../../services/api';

const PendingReferralFunction = () => {
  const { callApi } = useApi();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [recommendationsList, setrecommendationsList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalCount, setTotalCount] = useState();
  const itemsPerPage = 5;

  useEffect(() => {
    fetchPendingReferralDetails();
  }, []);

  const fetchPendingReferralDetails = async () => {
    setLoading(true);
    try {
      const response = await callApi(() =>
        referralService.getPendingReferrals(localStorage.getItem('memberID'))
      );

      if (response?.details?.length > 0) {
        setrecommendationsList(response.details);
        setTotalCount(response.details.length);
        setTotalPages(Math.ceil(response.details.length / itemsPerPage));
      } else {
        setrecommendationsList([]);
      }
    } catch (error) {
      console.error('Error fetching pending referrals:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateReferral = async (referralMemberuuid, status) => {
    try {
      const request = {
        referralmemberuuid: referralMemberuuid,
        memberuuid: localStorage.getItem('memberID'),
        status: status,
      };

      const response = await callApi(() =>
        referralService.updateReferral(request)
      );

      if (response) {
        await fetchPendingReferralDetails();
        setSuccess({
          title: 'SUCCESS!',
          message: response.message,
        });
      }
    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.data?.message || 'Failed to update referral status'
      });
    }
  };

  const errorHandler = () => {
    setError(null);
  };

  const successHandler = () => {
    setSuccess(null);
  };

  //client side pagination
  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  let currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return recommendationsList.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, itemsPerPage, recommendationsList]);

  let pageNumbers = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }, [totalPages]);

  pageNumbers = useMemo(() => {
    const pages = [1]; // Start with the first page

    // Calculate dynamic start and end indices
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(currentPage + 1, totalPages - 1);

    // Add ellipsis if there's a gap after the first page
    if (startPage > 2) pages.push('...');

    // Add page numbers in the current range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add ellipsis before the last page if there's a gap
    if (endPage < totalPages - 1) pages.push('...');

    // Include the last page if more than one page exists
    if (totalPages > 1) pages.push(totalPages);
    return pages;
  }, [currentPage, totalPages]);

  return (
    <>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}
      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <Col lg="12" className="Col_12">
        <Row>
          <Col md={12} xs={12} className="inner_row pb-4">
            {isLoading ? (
              <Row className="mt-3">
                <Col md={12}>
                  <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">
                    <Row className="w-100">
                      <Col md={4}>
                        <List />
                      </Col>
                      <Col md={4}></Col>
                      <Col md={4}>
                        <List />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                {currentItems.length == 0 ? (
                  <div className="text-center mt-3 label_text">
                    No Pending Referral ! Time to Refer Our Community Members !
                  </div>
                ) : (
                  <Row>
                    {(currentItems || []).map((item, key) => (
                      <Col xs={12} className="mt-4 pt-2" key={key}>
                        <div className="job-box bg-white d-md-flex align-items-center shadow rounded p-4 position-relative ">
                          {item.gender == 'Male' && (
                            <img
                              className="avatar avatar-md-sm favourite_avatar_img border border-1 border-primary"
                              src={manavatar}
                            />
                          )}
                          {item.gender == 'Female' && (
                            <img
                              className="avatar avatar-md-sm favourite_avatar_img border border-1 border-primary"
                              src={femaleavatar}
                            />
                          )}
                          {item.gender == 'Other' && (
                            <img
                              className="avatar avatar-md-sm favourite_avatar_img  border border-1 border-primary"
                              src={transgenderavatar}
                            />
                          )}
                          <div className="ms-md-4 mt-4 mt-sm-0">
                            <a className="text-dark h5">
                              {item.firstname} {item.lastname}{' '}
                            </a>
                            <ul className="list-unstyled mb-0 mt-2">
                              <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                                <i className="uil uil-user-plus text-primary"></i>{' '}
                                <Link to="#" className="text-dark">
                                  {item.membertype}
                                </Link>
                              </li>{' '}
                              <li className="d-lg-inline text-muted h6 mb-0">
                                <i className="uil uil-bill text-primary"></i>{' '}
                                {item.gender}
                              </li>{' '}
                            </ul>
                          </div>

                          <div className="position-absolute top-0 end-0 mt-3 me-3">
                            <button
                              title="Accept"
                              className="btn text-white btn-success"
                              onClick={() => updateReferral(item.memberuuid, 1)}
                            >
                              <i className="uil uil-check align-middle "></i>{' '}
                              Accept Referral
                            </button>{' '}
                            <button
                              title="Ignore"
                              className="btn  btn-danger text-white "
                              onClick={() => updateReferral(item.memberuuid, 4)}
                            >
                              <i className="uil uil-x align-middle "></i> Ignore
                              Referral
                            </button>
                          </div>
                        </div>
                      </Col>
                    ))}
                    <Col xs={12} className="mt-4 pt-2">
                      {totalPages > 1 && (
                        <>
                          {currentItems.length > 0 && (
                            <ul className="pagination justify-content-center mb-0">
                              <PaginationItem disabled={currentPage === 1}>
                                <PaginationLink
                                  to="#"
                                  aria-label="Previous"
                                  onClick={() =>
                                    handlePageClick(currentPage - 1)
                                  }
                                >
                                  {'<<'}
                                </PaginationLink>
                              </PaginationItem>
                              {pageNumbers.map((number) => (
                                <PaginationItem
                                  active={number === currentPage}
                                  key={number}
                                  disabled={number === '...'}
                                >
                                  <PaginationLink
                                    onClick={() => handlePageClick(number)}
                                  >
                                    {number}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                              <PaginationItem
                                disabled={currentPage === totalPages}
                              >
                                <PaginationLink
                                  to="#"
                                  aria-label="Next"
                                  onClick={() =>
                                    handlePageClick(currentPage + 1)
                                  }
                                >
                                  {'>>'}
                                </PaginationLink>
                              </PaginationItem>
                            </ul>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default PendingReferralFunction;
