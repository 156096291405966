import axios from 'axios';
import apiUrl from '../apppages/server';

const handleAuthError = (error) => {
  // Clear all auth tokens
  localStorage.removeItem('token');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('memberID');
  localStorage.removeItem('configurationData');
  
  // Store return URL
  localStorage.setItem('returnUrl', window.location.pathname);
  
  let message = 'Your session has expired. Please login again.';
  
  // Handle specific error cases
  if (error.response?.data?.code === 'REFRESH_TOKEN_MISSING') {
    message = 'Please login to continue';
  } else if (error.response?.data?.message) {
    message = error.response.data.message;
  }
  
  // Don't redirect if we're already on the login page
  if (!window.location.pathname.includes('user-login-page')) {
    window.location.href = `/user-login-page?expired=true&message=${encodeURIComponent(message)}`;
  }
  return Promise.reject(error);
};

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    
    // Debug logging
    console.log('Request Headers:', {
      Authorization: accessToken ? `Bearer ${accessToken}` : 'None',
      'x-refresh-token': refreshToken || 'None'
    });

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    
    if (refreshToken) {
      config.headers['x-refresh-token'] = refreshToken;  // Match backend's expected header
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle token updates from response headers
    const newAccessToken = response.headers['access-token'];
    const newRefreshToken = response.headers['refresh-token'];
    const tokenUpgradeRequired = response.headers['token-upgrade-required'];

    // Debug logging
    console.log('Response Headers:', {
      'access-token': newAccessToken || 'None',
      'refresh-token': newRefreshToken || 'None',
      'token-upgrade-required': tokenUpgradeRequired || 'None'
    });

    if (newAccessToken) {
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('token', newAccessToken); // For backward compatibility
    }
    
    if (newRefreshToken) {
      localStorage.setItem('refreshToken', newRefreshToken);
    }

    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Check for specific error codes from backend
      if (error.response?.data?.code === 'RE_LOGIN_REQUIRED') {
        return handleAuthError(error);
      }

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw { response: { data: { code: 'REFRESH_TOKEN_MISSING' } } };
        }

        // Call refresh endpoint
        const response = await axios.post(`${apiUrl}auth/refresh`, {}, {
          headers: {
            'x-refresh-token': refreshToken  // Match backend's expected header
          }
        });

        if (response.headers['access-token']) {
          const newAccessToken = response.headers['access-token'];
          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('token', newAccessToken);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        return handleAuthError(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Add debug interceptor
axiosInstance.interceptors.request.use(request => {
  console.log('Starting Request:', {
    url: request.url,
    headers: request.headers
  });
  return request;
});

axiosInstance.interceptors.response.use(response => {
  console.log('Response:', {
    status: response.status,
    headers: response.headers,
    data: response.data
  });
  return response;
});

export default axiosInstance;
