import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Row, Col, Label, Input, Button, Form } from 'reactstrap';
import CommonModal from '../../Home/modal.js';
import '../../Home/css/userHomePage.css';
import { useFormik } from 'formik';
import apiUrl from '../../server';
import FeatherIcon from 'feather-icons-react';
import DegreeCapIcon from '../../../assets/images/MettaMateImages/education.png'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useApi } from '../../../hooks/useApi';
import { educationService, codesService } from '../../../services/api';

const EducationFunction = () => {
  const { callApi } = useApi();
  let memberID = localStorage.getItem('memberID');
  const [educationData, setEducationData] = useState({});
  const [degreeLevelList, setDegreeLevelList] = useState([]);
  const [degreeNamesList, setdegreeNamesList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [mapvalue, setmapValue] = useState({});
  const [address, setAddress] = useState('');
  const [degreelevel, setdegreelevel] = useState({});
  const [graduationyear, setGraduationYear] = useState({});
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [degreelevelselectedOption, setdegreelevelselectedOption] = useState('');
  const [degreenamesselectedOption, setdegreeNamesselectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [degreelocationcity, setDegreeLocationCity] = useState('');
  const [degreelocationstate, setDegreeLocationState] = useState('');
  const [degreelocationcountry, setDegreeLocationCountry] = useState('');
  const [degreelocationname, setDegreeLocationDetails] = useState('');
  useEffect(() => {
    fetcheducationDetails();
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index,
    );
    setYearList(years);
    const fetchData = async () => {
      try {
        const [degreelevelRes, degreenamesRes] = await Promise.all([
          callApi(() => codesService.getDegreeLevel()),
          callApi(() => codesService.getDegreeNames())
        ]);

        setDegreeLevelList(degreelevelRes.list);
        setdegreeNamesList(degreenamesRes.list);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchData();
  }, []);

  const fetcheducationDetails = async () => {
    try {
      const response = await callApi(() => 
        educationService.getEducation(memberID)
      );

      if (response.length > 0) {
        setEducationData(response[0]);
        setdegreelevelselectedOption(response[0].degreelevel);
        setGraduationYear(response[0].yearofgraduation);
        setdegreeNamesselectedOption(response[0].degreename);
        if (response[0].degreeunilocationid) {
          setmapValue(response[0].degreeunilocationid);
          setAddress(response[0].degreeunilocationname);
        }
      } else {
        setEducationData({});
        setdegreelevelselectedOption({});
        setGraduationYear({});
      }
    } catch (error) {
      console.error('Error fetching education details:', error);
    }
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleAddressSelect = async (address, placeid) => {
    setmapValue(placeid);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      console.log(address)
      setAddress(address);
      const addressComponents = results[0].address_components;
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.long_name || '';

      // Store the city, state, and country in your state or do something else with this information
      setDegreeLocationCity(city);
      setDegreeLocationState(state);
      setDegreeLocationCountry(country);
      const locationDetails = [city, state, country].filter(Boolean).join(', '); 
      setDegreeLocationDetails(locationDetails);
      // Use latLng if needed
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handledegreeChange = (e) => {
    setEducationData({ ...educationData, [e.target.name]: e.target.value });
    validation.setFieldValue('degreename', e.target.value);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      memberuuid: memberID,
      degreeunilocationid: '',
      degreelevel: '',
      degreename: '',
      degreecollege: '',
      yearofgraduation: '',
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      values.degreelevel = parseInt(degreelevelselectedOption);
      values.degreename = parseInt(degreenamesselectedOption);
      var degreecollege = document.getElementById('degreecollege');
      values.degreecollege = degreecollege.value;
      values.degreeunilocationid = mapvalue ? mapvalue : '';
      var degreeyear = document.getElementById('degreeyear');
      values.yearofgraduation = degreeyear.value;
      values.degreeunilocationcity = degreelocationcity;
      values.degreeunilocationstate = degreelocationstate;
      values.degreeunilocationcountry = degreelocationcountry;
      values.degreeunilocationname = degreelocationname;

      try {
        const response = await callApi(() => 
          educationService.updateEducation(values)
        );

        setSuccess({
          title: 'SUCCESS!',
          message: response.message,
        });

        await fetcheducationDetails();

      } catch (error) {
        setError({
          title: 'An Error Occurred!',
          message: error.response?.data?.message || 'Failed to update education details'
        });
      } finally {
        setIsLoading(false);
      }
    },
  });
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setEducationData({});
    setdegreelevel({});
    setGraduationYear({});
  };
  const handledegreelevelOptionChange = (event) => {
    setdegreelevelselectedOption(event.target.value);
  }
  const handledegreeNamesOptionChange = (event) => {
    setdegreeNamesselectedOption(event.target.value);
  }
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {
        isLoading ? <LoaderComponent /> :
          (

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Row>
                <Col md={12} className='mt-3'>
                  <img src={DegreeCapIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Degree Description</span>
                  <Row className="mt-3">

                    <Col md={12} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Degree Level

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(degreeLevelList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="levelradio" type="radio" value={type.value}
                                    checked={degreelevelselectedOption == type.value} id={`radiolevel${index}`}
                                    onChange={handledegreelevelOptionChange} />
                                  <label htmlFor={`radiolevel${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>

                    </Col>

                    <Col md={12} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Degree Name

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(degreeNamesList || []).map((type, index) => (
                                <div className={`radio-item ${type.level == degreelevelselectedOption ? '' : 'd-none'}`} key={index} >
                                  <Input name="degreenamesradio" type="radio" value={type.value}
                                    checked={degreenamesselectedOption == type.value} id={`radiodegreeNames${index}`}
                                    onChange={handledegreeNamesOptionChange} />
                                  <label htmlFor={`radiodegreeNames${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Degree College

                          </Label>
                          <div className="form-icon position-relative">
                            <i className="fa fa-building" aria-hidden="true"></i>
                          </div>
                          <Input
                            type="text"
                            id="degreecollege"

                            className="form-control form_Input ps-5"
                            placeholder="Degree College "
                            defaultValue={educationData.degreecollege || ''}
                            name="degreeCollege"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Degree University Location

                          </Label>

                          <PlacesAutocomplete
                            value={address}
                            onChange={handleAddressChange}
                            onSelect={handleAddressSelect}
                            className="form-control form_input"
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => {
                              const uniqueSuggestions = new Set();

                              return (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';

                                      const words = suggestion.description.split(',');
                                      const trimmedArray = words.map((str) => str.trim());
                                      const lastThreeWords = trimmedArray
                                        .slice(-3)
                                        .join(',');
                                      // Check if the suggestion is unique based on place_id
                                      if (!uniqueSuggestions.has(lastThreeWords)) {
                                        uniqueSuggestions.add(lastThreeWords);

                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                          : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };

                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{lastThreeWords}</span>
                                          </div>
                                        );
                                      } else {
                                        return null; // Skip rendering duplicate suggestions
                                      }
                                    })}
                                  </div>
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Year Of Graduation

                          </Label>

                          <select
                            className="form-select form-control form_Input"
                            id="degreeyear"
                            value={graduationyear}
                            onChange={(e) => setGraduationYear(e.target.value)}
                          >
                            {(yearList || []).map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>


                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "

                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary  mt-2 ms-2"

                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment >
  );
};
export default EducationFunction;
