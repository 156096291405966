import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Label,
  Button,
  Input,
  FormFeedback,
  CardTitle,
} from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import '../../css/userHomePage.css';
import CommonModal from '../../../Home/modal';
import { Link } from 'react-router-dom';
import { useApi } from '../../../../hooks/useApi';
import { menteeService, mentorService } from '../../../../services/api';
import {
  FaUserEdit,
  FaUserTie,
  FaUserCheck,
  FaBriefcase,
  FaListAlt,
  FaInfoCircle,
  FaDollarSign,
  FaEye,
} from 'react-icons/fa';
import { MdPerson } from 'react-icons/md'; // Import mentee icon
const iconStyle = { color: '#0D6EFD' };

const BecomeMentee = () => {
  const navigate = useNavigate();
  const { callApi } = useApi();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [menteeStatus, setMenteeStatus] = useState(null); // 'pending', 'approved', 'rejected', or null
  const [isEditing, setIsEditing] = useState(false);
  const [existingMenteeData, setExistingMenteeData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Inside your form component
  const [editorContent, setEditorContent] = useState('');

  // Quill editor configurations
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'link',
  ];

  const handleCancelEdit = () => {
    navigate('/self-mentee-page');
  };

  useEffect(() => {
    fetchCategories();
    checkMenteeStatus();
  }, []);

  const checkMenteeStatus = async () => {
    try {
      setIsLoading(true);
      const response = await callApi(() =>
        menteeService.getMenteeStatus()
      );
      if (response && response.isMentee) {
        setMenteeStatus(response.status);
        setExistingMenteeData(response.menteeDetails); // Fixed: removed .data
        setSelectedCategories(
          response.menteeDetails.categories.map((cat) => ({
            value: cat.categoryid,
            label: cat.categoryname,
          }))
        );
        await fetchSubCategories(
          response.menteeDetails.categories.map((cat) => cat.categoryid)
        );
        setSelectedSubCategories(
          response.menteeDetails.subcategories.map((subCat) => ({
            value: subCat.subcategoryid,
            label: subCat.subcategoryname,
          }))
        );
      }
    } catch (error) {
      console.error('Error checking mentee status:', error);
      setError({
        title: 'Error',
        message: 'Failed to fetch mentee status',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await callApi(() =>
        mentorService.fetchCategories()
      );
      setCategories(response || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSubCategories = async (categoryIds) => {
    try {
      const response = await callApi(() =>
        mentorService.fetchSubCategories(categoryIds)
      );
      setSubCategories(response.subCategories || []);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleCategoryChange = (selected) => {
    setSelectedCategories(selected);
    if (selected.length > 0) {
      fetchSubCategories(selected.map((cat) => cat.value));
    } else {
      setSubCategories([]);
      setSelectedSubCategories([]);
    }
  };

  const handleMentorshipChange = (type) => {
    if (type === 'both') {
      const isBothSelected =
        validation.values.isPaidMentorship &&
        validation.values.isFreeMentorship;
      validation.setFieldValue('isPaidMentorship', !isBothSelected);
      validation.setFieldValue('isFreeMentorship', !isBothSelected);
    } else {
      validation.setFieldValue('isPaidMentorship', type === 'paid');
      validation.setFieldValue('isFreeMentorship', type === 'free');
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: existingMenteeData
      ? {
          menteeTitle: existingMenteeData.mentee_title,
          menteeBio: existingMenteeData.mentee_bio,
          mentorshipPreference: existingMenteeData.mentorship_preference, // Updated field
        }
      : {
          menteeTitle: '',
          menteeBio: '',
          mentorshipPreference: 'FREE', // Default value
        },
    validate: (values) => {
      const errors = {};

      if (!values.menteeTitle) {
        errors.menteeTitle = 'Title is required';
      }

      if (!values.menteeBio) {
        errors.menteeBio = 'Bio is required';
      } else if (values.menteeBio.length > 512) {
        errors.menteeBio = 'Bio must be less than 512 characters';
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const menteeData = {
          ...values,
          categories: selectedCategories.map((cat) => cat.value),
          subCategories: selectedSubCategories.map((subCat) => subCat.value),
        };

        const response = await callApi(() => 
          isEditing 
            ? menteeService.updateMentee(existingMenteeData.mentee_uuid, menteeData)
            : menteeService.becomeMentee(menteeData)
        );

        if (response) { // Fixed: removed .status === 200 check since response is already the data
          setSuccess({
            title: 'Success!',
            message: isEditing
              ? 'Your mentee profile has been updated successfully'
              : 'Your mentee profile has been created successfully',
          });
          if (isEditing) {
            setIsEditing(false);
            checkMenteeStatus(); // Refresh the data
          }
        }
      } catch (error) {
        setError({
          title: 'Error!',
          message: error.response?.data?.message || 'Something went wrong',
        });
      }
    },
  });

  if (isLoading) {
    return <div className="text-center p-5">Loading...</div>;
  }

  const renderStatusBadge = () => {
    const statusColors = {
      pending: 'warning',
      approved: 'success',
      rejected: 'danger',
    };

    return (
      menteeStatus && (
        <div className={`badge bg-${statusColors[menteeStatus]} mb-3`}>
          Status: {menteeStatus.charAt(0).toUpperCase() + menteeStatus.slice(1)}
        </div>
      )
    );
  };

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={() => setError(null)}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={() => {
            setSuccess(null);
            navigate('/self-mentee-page');
          }}
          status="success"
        />
      )}

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} xs={12} md={12}>
              <Card className="shadow">
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <CardTitle className="fs-4">
                      {isEditing ? (
                        <>
                          <FaUserEdit className="me-2" style={iconStyle} />
                          Edit Mentee Profile
                        </>
                      ) : existingMenteeData ? (
                        <>
                          <MdPerson className="me-2" style={iconStyle} />
                          Mentee Profile
                        </>
                      ) : (
                        <>
                          <MdPerson className="me-2" style={iconStyle} />
                          Become a Mentee
                        </>
                      )}
                    </CardTitle>
                    {renderStatusBadge()}
                    {existingMenteeData && !isEditing && (
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit Profile
                      </Button>
                    )}
                  </div>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label fs-6 ">
                            <FaBriefcase className="me-2" style={iconStyle} />
                            Seeking Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="menteeTitle"
                            placeholder="e.g., Looking for abroad admission or Help with university shortlisting"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.menteeTitle}
                            invalid={
                              validation.touched.menteeTitle &&
                              validation.errors.menteeTitle
                            }
                          />
                          {validation.touched.menteeTitle &&
                            validation.errors.menteeTitle && (
                              <FormFeedback type="invalid">
                                {validation.errors.menteeTitle}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label fs-6 ">
                            <FaListAlt className="me-2" style={iconStyle} />
                            Looking for which Categories ? <span className="text-danger">*</span>
                          </Label>
                          <Select
                            isMulti
                            options={categories.map((cat) => ({
                              value: cat.categoryid,
                              label: cat.categoryname,
                            }))}
                            value={selectedCategories}
                            onChange={handleCategoryChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                minHeight: '60px',
                                maxHeight:
                                  state.selectProps.value?.length > 3
                                    ? '150px'
                                    : 'fit-content',
                                overflow: 'auto',
                              }),
                              valueContainer: (base) => ({
                                ...base,
                                maxHeight: '150px',
                                overflow: 'auto',
                                padding: '2px 8px',
                              }),
                              multiValue: (base) => ({
                                ...base,
                                margin: '4px',
                              }),
                            }}
                          />
                          {/* After the Categories Select */}
                          {validation.touched.categories &&
                            selectedCategories.length === 0 && (
                              <FormFeedback className="d-block">
                                At least one category is required
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      {subCategories.length > 0 && (
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label fs-6 ">
                              <FaListAlt className="me-2" style={iconStyle} />
                              Specific Specializations ?{' '}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              isMulti
                              options={subCategories.map((subCat) => ({
                                value: subCat.subcategoryid,
                                label: subCat.subcategoryname,
                              }))}
                              value={selectedSubCategories}
                              onChange={setSelectedSubCategories}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  minHeight: '60px',
                                  maxHeight:
                                    state.selectProps.value?.length > 3
                                      ? '150px'
                                      : 'fit-content',
                                  overflow: 'auto',
                                }),
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: '150px',
                                  overflow: 'auto',
                                  padding: '2px 8px',
                                }),
                                multiValue: (base) => ({
                                  ...base,
                                  margin: '4px',
                                }),
                              }}
                            />
                            {/* After the Subcategories Select */}
                            {validation.touched.subCategories &&
                              selectedCategories.length > 0 &&
                              selectedSubCategories.length === 0 && (
                                <FormFeedback className="d-block">
                                  At least one specialization is required
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      )}

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label fs-5">
                            <FaInfoCircle className="me-2" style={iconStyle} />
                            Your Mentorship Requirement Details{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <ReactQuill
                            theme="snow"
                            value={validation.values.menteeBio}
                            onChange={(content) => {
                              validation.setFieldValue('menteeBio', content);
                            }}
                            modules={modules}
                            formats={formats}
                            style={{ height: '200px', marginBottom: '50px' }}
                          />
                          <small className="text-muted">
                            {/* Note: You'll need to handle character count differently for HTML content */}
                            Use formatting tools to make your bio more engaging
                          </small>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label fs-6 ">
                            <FaInfoCircle className="me-2" style={iconStyle} />
                            What type of Mentorship you are looking :
                          </Label>
                          <div className="form-check d-flex flex-row ">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              name="mentorshipPreference"
                              onChange={() => validation.setFieldValue('mentorshipPreference', 'PAID')}
                              checked={validation.values.mentorshipPreference === 'PAID'}
                            />
                            <Label className="form-check-label">
                              Paid Mentorship
                            </Label>
                          </div>
                          <div className="form-check d-flex flex-row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              name="mentorshipPreference"
                              onChange={() => validation.setFieldValue('mentorshipPreference', 'FREE')}
                              checked={validation.values.mentorshipPreference === 'FREE'}
                            />
                            <Label className="form-check-label">
                              Free Mentorship
                            </Label>
                          </div>
                          <div className="form-check d-flex flex-row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              name="mentorshipPreference"
                              onChange={() => validation.setFieldValue('mentorshipPreference', 'BOTH')}
                              checked={validation.values.mentorshipPreference === 'BOTH'}
                            />
                            <Label className="form-check-label">Both</Label>
                          </div>
                        </div>
                      </Col>

                      <Col md={12} className="text-center mt-4">
                        {(!existingMenteeData || isEditing) && (
                          <Button
                            color="primary"
                            type="submit"
                            disabled={
                              !validation.isValid ||
                              selectedCategories.length === 0 ||
                              (selectedCategories.length > 0 &&
                                selectedSubCategories.length === 0)
                            }
                          >
                            {isEditing ? 'Save Changes' : 'Submit Application'}
                          </Button>
                        )}
                        {isEditing && (
                          <Button
                            color="secondary"
                            className="ms-2"
                            onClick={() => handleCancelEdit()}
                          >
                            Cancel
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BecomeMentee;
