import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import AsMembersSearch from './AsMembersSearch';
import AsMemberCard from './AsMemberCard';
import { useApi } from '../../../hooks/useApi';
import './AsMembersHomePage.css';
import axiosInstance from '../../../utils/axiosConfig';

// Add this service to /services/api.js if not already present
const memberService = {
  // ...existing services...
  getAllianceSeekers: (pageNo, pageSize, searchCriteria) => 
    axiosInstance.post('membersmgmt/getAllianceSeekers', {
      pageNo,
      pageSize,
      searchCriteria
    })
};

const AsMembersHomePage = () => {
  const location = useLocation();
  const { state } = location || {};
  const { returnPage } = state || {};
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(returnPage || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [configurationData, setConfigurationData] = useState(null);
  const pageSize = 20;
  const maxPageLinks = 5;
  const { callApi } = useApi();

  const fetchMembers = async (criteria) => {
    const configData = localStorage.getItem('configurationData');
    if (!configData) {
      window.location.href = '/index';
      return;
    }

    const parsedConfigData = JSON.parse(configData);
    setConfigurationData(parsedConfigData);

    // Set default search criteria based on the opposite gender if not already set
    if (!criteria.gendercode) {
      const defaultGender = parsedConfigData.gendercode === 1 ? 2 : 1;
      criteria.gendercode = defaultGender;
    }

    setLoading(true);
    try {
      const response = await callApi(() => 
        memberService.getAllianceSeekers(currentPage, pageSize, criteria)
      );
      
      setMembers(response.members);
      setTotalPages(response.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching members:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers(searchCriteria);
  }, [currentPage, searchCriteria]);

  const handleSearch = (criteria) => {
    setSearchCriteria(criteria);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageLinks / 2));
    const endPage = Math.min(totalPages, startPage + maxPageLinks - 1);

    if (startPage > 1) {
      items.push(
        <PaginationItem key="start-ellipsis" disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem active={i === currentPage} key={i}>
          <PaginationLink onClick={() => handlePageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <PaginationItem key="end-ellipsis" disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* Hero Section */}
          <Row className="justify-content-center mb-1">
            <Col lg={8} className="text-center">
              <p className="display-4 fw-bold mb-1 fs-4" 
                  style={{
                    background: 'linear-gradient(45deg, #2d5fb4, #0d6efd)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.1)'
                  }}>
                Find Your Perfect Match
              </p>
              <Link 
                to="/subscriptionplan" 
                className="text-decoration-none subscription-link"
                style={{
                  background: 'linear-gradient(45deg, #2d5fb4, #0d6efd)',
                  fontWeight: '800',
                  fontSize: '1.1rem',
                  color: 'white',
                }}
              >
                ✨ Subscribe and Support MettaMate ✨
              </Link>

              {/* Add this CSS */}
              <style>
                {`
                  .subscription-link {
                    animation: glow 2s ease-in-out infinite;
                    transition: all 0.3s ease;
                  }
                  
                  .subscription-link:hover {
                    transform: scale(1.05);
                    text-decoration: underline !important;
                  }

                  @keyframes glow {
                    0% { opacity: 1; }
                    50% { opacity: 0.7; }
                    100% { opacity: 1; }
                  }
                `}
              </style>
            </Col>
          </Row>

          {/* Search Panel */}
          <Row>
            <Col xs="12" className="mb-2">
              <div className="search-panel-wrapper">
                <AsMembersSearch onSearch={handleSearch} />
              </div>
            </Col>
          </Row>

          {/* Members Grid */}
          <Row>
            {loading ? (
              <Col xs="12" className="text-center py-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </Col>
            ) : (
              members.map((member, index) => (
                <Col lg="3" md="6" sm="12" key={index} className="mb-4">
                  <AsMemberCard 
                    member={member}
                    currentPage={currentPage}
                    searchCriteria={searchCriteria}
                    configurationData={configurationData}
                  />
                </Col>
              ))
            )}
          </Row>

          {/* Pagination */}
          {totalPages > 1 && (
            <Row>
              <Col xs="12" className="d-flex justify-content-center">
                <Pagination>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink
                      first
                      onClick={() => handlePageChange(1)}
                    />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageChange(currentPage - 1)}
                    />
                  </PaginationItem>
                  {renderPaginationItems()}
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink
                      next
                      onClick={() => handlePageChange(currentPage + 1)}
                    />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink
                      last
                      onClick={() => handlePageChange(totalPages)}
                    />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AsMembersHomePage;