import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const callApi = async (apiFunction, successCallback, errorCallback) => {
    setLoading(true);
    try {
      const response = await apiFunction();
      if (successCallback) {
        successCallback(response.data);
      }
      return response.data;
    } catch (err) {
      if (err.response?.data?.code === 'TOKEN_UPGRADE_NEEDED' ||
          err.response?.data?.code === 'RE_LOGIN_REQUIRED') {
        navigate('/user-login-page');
      }
      setError(err.response?.data?.message || 'An error occurred');
      if (errorCallback) {
        errorCallback(err);
      }
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, callApi };
};
