import React, { useState, useEffect, useRef } from 'react';

import '../Home/css/userHomePage.css';

import { useApi } from '../../hooks/useApi';
import { subscriptionService } from '../../services/api';
import { Link, useNavigate } from 'react-router-dom';
import CommonModal from '../../apppages/Home/modal';
import {
  Container,
  Card,
  CardBody,
  Row,
  Table,
  Col,
  UncontrolledCarousel,
  UncontrolledCollapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import wrongIcon from '../../assets/images/MettaMateImages/minus.png';
import onemonthImg from '../../assets/images/MettaMateImages/1month.png';
import threemonthImg from '../../assets/images/MettaMateImages/3month.png';
import sixmonthImg from '../../assets/images/MettaMateImages/6month.png';
import twelvemonthImg from '../../assets/images/MettaMateImages/12month.png';
import celebrationGif from '../../assets/images/MettaMateImages/celebration.gif';

import SectionTitle from '../../components/Shared/SectionTitle';
// Import images
import Banner from '../../assets/images/mainBG-image.jpg';

import Pricing from '../../components/Shared/PricingBox';


const SVGIcon = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 2.06299C6.57191 2.06299 2.5625 6.0724 2.5625 11.0005C2.5625 15.9286 6.57191 19.938 11.5 19.938C16.4281 19.938 20.4375 15.9286 20.4375 11.0005C20.4375 6.0724 16.4281 2.06299 11.5 2.06299ZM16.1514 8.00514L10.3764 14.8801C10.313 14.9556 10.2342 15.0165 10.1452 15.0589C10.0563 15.1012 9.95925 15.1239 9.86074 15.1255H9.84914C9.75278 15.1255 9.6575 15.1052 9.56948 15.0659C9.48147 15.0267 9.40269 14.9694 9.33824 14.8978L6.86324 12.1478C6.80039 12.0811 6.75149 12.0025 6.71943 11.9167C6.68737 11.8309 6.67279 11.7395 6.67655 11.6479C6.6803 11.5564 6.70232 11.4665 6.74131 11.3836C6.7803 11.3007 6.83547 11.2264 6.90358 11.1651C6.97169 11.1038 7.05136 11.0567 7.13791 11.0267C7.22447 10.9966 7.31616 10.9842 7.4076 10.99C7.49904 10.9959 7.58838 11.02 7.67037 11.0609C7.75236 11.1018 7.82534 11.1587 7.88504 11.2282L9.83109 13.3904L15.0986 7.12084C15.2168 6.98423 15.384 6.89961 15.564 6.88526C15.7441 6.87092 15.9225 6.928 16.0608 7.04417C16.1991 7.16034 16.2861 7.32627 16.3031 7.50609C16.32 7.68591 16.2655 7.86517 16.1514 8.00514V8.00514Z"
      fill="#1E60E1"
    ></path>
  </svg>
);

// const stripePromise = loadStripe(
//   'pk_test_51P66vFSIT4uxePkhZkmTmpF97RprMa1WntPqq2vNGLJSU9noTbuv3oOiHdcBP66dzeRtYt7pJYFaytLp4vyt29lU00JIG13HO6',
// );

const pricings = [
  {
    id: 2,
    title: 'Six Months ',
    price: 1000,
    duration: 6,
    subscriptionplanid: '57a5d659-0de5-4478-94bd-b45bf81a2992',

    buttonText: 'Get Started',
    btnLink: '',
    isActive: true,
    discount: 'Basic',
    features: [
      { title: 'All Free Users features' },
      { title: 'View Alliance Seekers' },
      { title: 'Connect to Alliance Seekers' },
      { title: 'Message to Alliance Seekers' },
      { title: 'Personal Consultation' },
      { title: 'NO_ELEMENT' },
    ],
  },
  {
    id: 3,
    title: 'One Year ',
    price: 1600,
    duration: 12,
    subscriptionplanid: 'dd96edc8-0ce4-4e41-bf2d-306fd4d85166',
    buttonText: 'Trust Us',
    btnLink: '',
    isActive: true,
    discount: '20%',
    features: [
      { title: 'All Free Users features' },
      { title: 'View Alliance Seekers' },
      { title: 'Connect to Alliance Seekers' },
      { title: 'Message to Alliance Seekers' },
      { title: 'Personal Consultation' },
      { title: 'Professional Mentorship' },
    ],
  },
  {
    id: 4,
    title: 'Lifetime ',
    price: 2500,
    duration: 10000,
    subscriptionplanid: '253620de-90bd-456e-b8fa-92faf87d3415',
    buttonText: 'Become Partner',
    btnLink: '',
    isActive: true,
    discount: 'Opportunity',
    features: [
      { title: 'All Free Users features' },
      { title: 'View Alliance Seekers' },
      { title: 'Connect to Alliance Seekers' },
      { title: 'Message to Alliance Seekers' },
      { title: 'Personal Consultation' },
      { title: 'Professional Mentorship' },
      { title: 'Proud Partnership' },
    ],
  },
];

const SubscriptionFunction = () => {
  const { callApi } = useApi();
  // Function to dynamically load the Razorpay script
  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => reject(false);
      document.body.appendChild(script);
    });
  };
  const navigate = useNavigate();
  let configurationData = JSON.parse(localStorage.getItem('configurationData'));
  const [goldplantable, setgoldplantable] = useState(false);
  //const [filteredData, setfilteredData] = useState([]);
  const [showtable, setshowTable] = useState(true);
  const [showsummary, setShowsummary] = useState(false);
  const [planImage, setplanImage] = useState('');
  const [plandetails, setplanDetails] = useState({
    amount: '',
    discount: '',
    finalamount: '',
    discountpercent: '',
  });
  const [tokenname, setpromocode] = useState({});
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [showCelebration, setshowCelebration] = useState(false);

  useEffect(() => {
    // Load the Razorpay script when the component is mounted
    loadRazorpayScript().then((success) => {
      if (success) {
        console.log('Razorpay script loaded successfully.');
        // You can initialize Razorpay here if needed
      } else {
        console.error('Failed to load Razorpay script.');
      }
    });
    configurationData = JSON.parse(localStorage.getItem('configurationData'));
  }, []);

  const handleOrderResponse = async (
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature,
    priceAmount,
    subscriptionplanid,
    duration,
  ) => {
    // alert("PaymentID: " + razorpay_payment_id);
    // alert("OrderId:" + razorpay_order_id);
    // alert("Signature:" + razorpay_signature);
    const response = await callApi(() =>
      subscriptionService.paymentSuccess({
        razorpay_payment_id: razorpay_payment_id,
        razorpay_order_id: razorpay_order_id,
        razorpay_signature: razorpay_signature,
        memberuuid: localStorage.getItem('memberID'),
        amount: priceAmount,
        planid: subscriptionplanid,
        duration: duration,
        redirectURL: 'https://mettamate.ai/settings', // `${appUrl}/settings`
      })
    );
    if (response) {
      setSuccess({
        title: 'Success!',
        message: 'Congratulations ! You are a valid premium member now.',
      });
    }
  };

  const handlePayment = async (
    priceCategory,
    priceAmount,
    subscriptionplanid,
    duration,
  ) => {
    const response = await callApi(() =>
      subscriptionService.createOrder({
        memberuuid: localStorage.getItem('memberID'),
        amount: priceAmount,
        currency: 'INR',
        planid: subscriptionplanid,
        duration: duration,
      })
    );

    console.log('Going to Print something here on the console');

    if (response.status == 'OK') {
      // TODO: Only change the Main MettaMate's URL
      // let companyLogoURL = `${appUrl}/final_logo_icon_only.png`;
      let companyLogoURL = 'https://mettamate.ai/final_logo_icon_only.png';
      var options = {
        // Enter the Key ID generated from the Dashboard
        key: 'rzp_live_582ZmIPuMhKJsM', //razorKeyID,

        // Amount is in currency subunits (paise). Default currency is INR.
        // Hence, 50000 refers to 50000 paise
        amount: priceAmount * 100,

        currency: 'INR',
        name: 'MettaMate Platform', //your business name
        description:
          'Subscribing to planId:' +
          subscriptionplanid +
          ', Duration:' +
          duration,
        image: companyLogoURL,
        // Pass the `id` obtained in the response of Step 1
        order_id: response.orderid,
        handler: function (response) {
          handleOrderResponse(
            response.razorpay_payment_id,
            response.razorpay_order_id,
            response.razorpay_signature,
            priceAmount,
            subscriptionplanid,
            duration,
          );
        },
        // "callback_url": callBackURL,

        prefill: {
          // We recommend using the prefill parameter to auto-fill customer's contact
          // information especially their phone number
          name: configurationData.firstname, //your customer's name
          email: configurationData.emailid,
          // "contact": "9000090000" //Provide the customer's phone number for better conversion rates
        },
        notes: {
          address: 'MettaMate Pvt. Ltd., Mysoor, KA',
        },
        theme: {
          color: '#d6855c',
        },
      };

      var rzp1 = new Razorpay(options);
      rzp1.open();
    }
  };

  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <section
        className="d-table w-100 pt-5 mt-5"
        style={{
          background: `url(${Banner}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
        id="home"
      >
        <Container className="section-three">
          <Row className="position-relative align-items-center pt-1">
            <Col lg={6} className="offset-lg-6">
              <Card className="title-heading studio-home rounded shadow mb-4">
                <h1 className="heading mb-3">
                  Become a Premium Member of{' '}
                  <span className="text-primary">MettaMate</span>
                </h1>
                <p className="para-desc ">
                  Empower the Community to Come together !
                </p>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Pricing Menu */}
      <Container className="mt-50 mt-60 mb-5">
        <Row id="pricing">
          {pricings.map((pricing, index) => (
            <Col
              name="pricing"
              lg={4}
              md={6}
              className="col-12 mt-4 pt-2"
              key={index}
            >
              <Card
                name="pricingbox"
                className={
                  pricing.isActive
                    ? 'card pricing pricing-primary business-rate shadow border-0 rounded'
                    : 'card pricing pricing-primary business-rate shadow bg-light border-0 rounded'
                }
              >
                {pricing.isActive && (
                  <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                    <span className="text-center d-block shadow small h6">
                      {pricing.discount}
                    </span>
                  </div>
                )}
                <CardBody>
                  <h6
                    className={
                      pricing.isActive
                        ? 'title fw-bold text-uppercase text-primary mb-4'
                        : 'title name fw-bold text-uppercase mb-4'
                    }
                  >
                    {pricing.title}{' '}
                    <span className="text-secondary">Membership !</span>
                  </h6>
                  <div className="d-flex mb-4" name="pricingsection">
                    <span className="h4 mb-0 mt-2">₹</span>
                    <span className="price h1 mb-0">{pricing.price}/-</span>
                  </div>

                  <ul className="list-unstyled mb-0 ps-0">
                    {pricing.features.map((feature, key) => {
                      if (feature.title === 'NO_ELEMENT') {
                        return <li key={key}></li>;
                      } else {
                        return (
                          <li key={key} className="h6 text-muted mb-0">
                            <span className="icon h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            {feature.title}
                          </li>
                        );
                      }
                    })}
                  </ul>
                  <hr />
                  <Button
                    className="btn btn-primary mt-4"
                    onClick={() =>
                      handlePayment(
                        pricing.title,
                        pricing.price,
                        pricing.subscriptionplanid,
                        pricing.duration,
                      )
                    }
                  >
                    {pricing.buttonText}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container className="mt-100 mt-60 mb-5">
        <Row className="text-center">
          <h1>For Any Questions and Feedback</h1>
          <h2>Contact admin@mettamate.ai </h2>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default SubscriptionFunction;
