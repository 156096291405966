import { mentorService } from '../../../services/api';


export const fetchMentors = async (callApi, params) => {
  const queryParams = {
    page: params.page || 1,
    pageSize: params.pageSize || 20,
    categories: params.categories?.length ? params.categories.join(',') : undefined,
  };

  const response = await callApi(() => mentorService.fetchMentors(queryParams));
  return {
    data: response.results,
    total: response.totalCount,
    currentPage: response.currentPage,
    totalPages: response.totalPages,
  };
};

export const fetchCategoriesAndSubcategories = async (callApi) => {
  const response = await callApi(() => mentorService.fetchCategoriesAndSubcategories());
  return response;
};

export const fetchCategories = async (callApi) => {
  const response = await callApi(() => mentorService.fetchCategories());
  return response;
};

export const fetchSubCategories = async (callApi, categoryIds) => {
  const response = await callApi(() => mentorService.fetchSubCategories(categoryIds));
  return response;
};

export const fetchMentorsByCategory = async (callApi, categoryId, pageIndex) => {
  const response = await callApi(() => mentorService.fetchMentorsByCategory(categoryId, pageIndex));
  return response;
};

export const fetchMenteesByCategory = async (callApi, categoryId, pageIndex) => {
  const response = await callApi(() => mentorService.fetchMenteesByCategory(categoryId, pageIndex));
  return response;
};

export const fetchCategoryStats = async (callApi) => {
  const response = await callApi(() => mentorService.fetchCategoryStats());
  const statsObject = response.reduce((acc, stat) => {
    acc[stat.categoryid] = stat.mentor_count;
    return acc;
  }, {});
  return statsObject;
};

export const fetchMenteeCategoryStats = async (callApi) => {
  const response = await callApi(() => mentorService.fetchMenteeCategoryStats());
  const statsObject = response.reduce((acc, stat) => {
    acc[stat.categoryid] = stat.mentee_count;
    return acc;
  }, {});
  return statsObject;
};

