import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  Form,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';

import '../../Home/css/userHomePage.css';
import CommonModal from '../../Home/modal';
import resetpasswordIcon from '../../../assets/images/MettaMateImages/reset-password.png';
import oldpasswordIcon from '../../../assets/images/MettaMateImages/oldpassword.png';
import newpasswordIcon from '../../../assets/images/MettaMateImages/newpassword.png';
import confirmpasswordIcon from '../../../assets/images/MettaMateImages/confirmpassword.png';
import referralIcon from '../../../assets/images/MettaMateImages/alternate.png';
import accountManagementIcon from '../../../assets/images/MettaMateImages/account-settings.png';
import subscriptionIcon from '../../../assets/images/MettaMateImages/membership.png';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'; // Import the material theme (optional)
import Profilecard from '../Profile_card';
import { useApi } from '../../../hooks/useApi';
import { settingsService, authService } from '../../../services/api';

const SettingFunction = () => {
  const { callApi } = useApi();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [confirmmodalOpen, setconfirmModalOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [currentPlan, setCurrentPlan] = useState('');
  const [validSubscriber, setValidSubscriber] = useState(0);
  const [countryCode1, setCountryCode1] = useState('');
  const [phoneNumber1, setPhoneNumber1] = useState();
  const [recommenderName, setRecommenderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState({
    memberuuid: localStorage.getItem('memberID'),
    membertype: '',
  });
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  let configurationData = JSON.parse(localStorage.getItem('configurationData'));

  useEffect(() => {
    configurationData = JSON.parse(localStorage.getItem('configurationData'));

    let currentPlan = JSON.parse(
      localStorage.getItem('configurationData'),
    ).subscription;
    setCurrentPlan(currentPlan);

    let validSubscriber = JSON.parse(
      localStorage.getItem('configurationData'),
    ).validsubscriber;
    setValidSubscriber(validSubscriber);
    getRecommendersShortdetails();
  }, []);
  const handlerecommenderCountrycodechange = (value, country) => {
    setCountryCode1(country.dialCode);
    setPhoneNumber1(value.replace(countryCode1, ''));
  };
  const changeHandler = (e) => {
    if (e.target.value === '1') {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const getRecommendersShortdetails = async () => {
    try {
      const response = await callApi(() => 
        settingsService.getRecommenderDetails(configurationData.recommendersuuid)
      );
      
      let fullName = response.firstname + ' ' + response.lastname;
      setRecommenderName(fullName);
    } catch (error) {
      console.error('Error fetching recommender details:', error);
    }
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const openConfirmModal = (status) => {
    setStatus(status);
    setconfirmModalOpen(true);
  };
  const closeModal = () => {
    setconfirmModalOpen(false);
  };
  const changeActivationStatus = async (activationstatus) => {
    try {
      const response = await callApi(() =>
        settingsService.changeActivationStatus({
          memberuuid: localStorage.getItem('memberID'),
          activationstatus
        })
      );

      setconfirmModalOpen(false);
      setSuccess({
        title: 'SUCCESS!',
        message: activationstatus === 1 ? 
          'Your Account has been Activated successfully' : 
          'Your Account has been Deactivated successfully'
      });

      setTimeout(async () => {
        if (activationstatus === 2) {
          await logout();
        } else {
          await fetchconfigureDetails();
        }
      }, 3000);

    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.message
      });
    }
  };
  const fetchconfigureDetails = async () => {
    try {
      const response = await callApi(() =>
        settingsService.getMemberShortDetails(localStorage.getItem('memberID'))
      );
      // alert("Came here for response + " + JSON.stringify(response));
      localStorage.setItem('configurationData', JSON.stringify(response));
      let fileurl = response.photopath1
        ? await getPhotoUrlDetails(response.photopath1)
        : undefined;
      localStorage.setItem('profilePicture', fileurl);
      let configurationData = JSON.parse(
        localStorage.getItem('configurationData'),
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getPhotoUrlDetails = async (fileName) => {
    try {
      const response = await callApi(() =>
        settingsService.getFilePath(fileName)
      );
      return response.filepath;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const deleteMember = async () => {
    setconfirmModalOpen(false);
    try {
      const response = await callApi(() =>
        settingsService.deleteMember({
          memberuuid: localStorage.getItem('memberID')
        })
      );

      setSuccess({
        title: 'SUCCESS!',
        message: response.message,
      });
      logout();
    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.message
      });
    }
  };
  const logout = async () => {
    try {
      // alert("Came here for logout ?");
      await callApi(() =>
        authService.logout()
      );
      localStorage.clear();
      navigate('/user-login-page');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      memberUUID: localStorage.getItem('memberID'),
      currentPassword: '',
      newPassword: '',
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        values.currentPassword = document.getElementById('oldpassword').value;
        values.newPassword = document.getElementById('newpassword').value;

        const response = await callApi(() =>
          settingsService.changePassword(values)
        );

        setSuccess({
          title: 'SUCCESS!',
          message: response.message,
        });
        // alert("Came here for logout ?");
        handleReset();
        logout();
      } catch (error) {
        setError({
          title: 'An Error Occurred!',
          message: error.response?.message
        });
      } finally {
        setIsLoading(false);
      }
    },
  });
  const matchPassword = (e) => {
    var newpassword = document.getElementById('newpassword').value;
    if (newpassword == e.target.value) {
      setIsButtonDisabled(false);
    } else {
      setErrorMessage('New Password and Confirm Password is not matched');
      setIsButtonDisabled(true);

      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
    logout(); // <-- call logout after clearing success
  };

  const handleReset = () => {
    var oldpassword = document.getElementById('oldpassword');
    oldpassword.value = '';
    var newpassword = document.getElementById('newpassword');
    newpassword.value = '';
    let confirmpassword = document.getElementById('confirmpassword');
    confirmpassword.value = '';
    setIsButtonDisabled(true);
  };
  const changeReferral = async () => {
    try {
      const email = document.getElementById('recommendermail').value;
      const response = await callApi(() => 
        settingsService.changeRecommender({ emailid: email })
      );

      setSuccess({
        title: 'SUCCESS!',
        message: response.message,
      });
      handleReset();
    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.message
      });
    }
  };
  const changeRole = async () => {
    try {
      const response = await callApi(() =>
        settingsService.updateRole(role)
      );

      setSuccess({
        title: 'SUCCESS!',
        message: response.message,
      });
      logout();
    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.message
      });
    }
  };
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      <Profilecard />
      <Container >
        <section className="section_user_home_page">
          <Container>
            <Row>
              <Col lg="12" className="Col_12 p-0">
                <Row className="inner_row m-0  mt-1">
                  <h6 className="title   title_bar ">Settings</h6>
                  {configurationData.memberstatuscode === 3 && (
                    <Col md={12} xs={12}>
                      <img
                        src={referralIcon}
                        className="legendIcon"
                        style={{ width: `40px` }}
                      />
                      &nbsp;
                      <span className="m-2 legendName">Change Referral</span>
                      <Row>
                        {recommenderName ? (
                          <Label className="form-label label_text mt-2 mx-2">
                            <b className="text-primary ">{recommenderName} </b>{' '}
                            is your Referral.
                          </Label>
                        ) : (
                          ''
                        )}

                        <Col md={3} xs={12}>
                          <div className=" features feature-primary py-0">
                            <div className="mb-3 flex-container">
                              &nbsp;
                              <select
                                onChange={changeHandler}
                                className="form-select form-control form_Input width100"
                                required
                                placeholder="Select"
                                id="recommendersSelect"
                              >
                                <option value="0">Email</option>
                                <option value="1">Phone Number</option>
                              </select>
                            </div>
                          </div>
                        </Col>
                        {isVisible ? (
                          <Col md={5} xs={12}>
                            <div className=" features feature-primary py-0">
                              <div className="mb-3">
                                <PhoneInput
                                  className=" form-control form_Input mt-20"
                                  international
                                  // countryCallingCodeEditable={false}
                                  country={'in'}
                                  // value={value1}
                                  minLength={10}
                                  maxLength={13}
                                  required
                                  onChange={handlerecommenderCountrycodechange}
                                />
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col md={4} xs={12}>
                            <div className="features feature-primary py-0">
                              <div className="mb-3">
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="mail"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  type="email"
                                  id="recommendermail"
                                  className="form-control form_Input ps-5  mt-20"
                                  placeholder="Email"
                                  name="recommendermail"
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col md={2}>
                          <button
                            type="button"
                            className="btn btn-outline-primary mt-20"
                            onClick={() => changeReferral()}
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                      <hr style={{ boxShadow: `0px 1px 1px` }} />
                    </Col>
                  )}


                      <Col md={12} className="mt-3">
                    <img
                      src={subscriptionIcon}
                      className="legendIcon"
                      style={{ width: `40px` }}
                    />
                    &nbsp;{' '}
                    <span className="m-2 legendName">
                      My Subscription Plans
                    </span>
                    <Row className="m-2">
                      <Col md={12} sm={12}>
                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            <Row>
                              <Col md={12} sm={12}>
                                <Label className="form-label label_text mt-2">
                                  Active Plan : <b>{currentPlan}</b>
                                </Label>
                                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                  <Link
                                    to="/subscriptionPlan"
                                    className="btn btn-outline-primary mt-2 rounded"
                                  >
                                    {' '}
                                    Upgrade Plan{' '}
                                  </Link>{' '}
                                  <Link className="btn btn-outline-danger mt-2 rounded">
                                    {' '}
                                    Cancel Subscription{' '}
                                  </Link>{' '}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                  </Col>

                  {isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <Col md={12} className="mt-3">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                        }}
                      >
                        <img
                          src={resetpasswordIcon}
                          className="legendIcon"
                          style={{ width: `40px` }}
                        />
                        &nbsp;{' '}
                        <span className="m-2 legendName">Reset Password</span>
                        <Row className="mt-3">
                          <Col md={12} sm={12}>
                            <div className="features feature-primary p-1">
                              <div className="mb-3">
                                <Row>
                                  <Col md={3} sm={12}>
                                    <Label className="form-label label_text mt-2">
                                      Current Password:{' '}
                                    </Label>
                                  </Col>
                                  <Col md={4} sm={12}>
                                    <div className="form-icon position-relative">
                                      <img
                                        src={oldpasswordIcon}
                                        style={{
                                          position: `absolute`,
                                          width: `45px`,
                                        }}
                                      />
                                    </div>
                                    <Input
                                      type={
                                        showCurrentPassword
                                          ? 'text'
                                          : 'password'
                                      }
                                      className="form-control form_Input ps-5"
                                      placeholder="Current Password"
                                      name="currentpassword"
                                      required
                                      id="oldpassword"
                                      defaultValue={
                                        validation.values.currentPassword
                                      }
                                    />
                                    <span
                                      style={{
                                        position: 'absolute',
                                        right: '26px',
                                        top: '59%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                      }}
                                      onClick={toggleCurrentPasswordVisibility}
                                    >
                                      {showCurrentPassword ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1.4em"
                                          height="1.4em"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1.4em"
                                          height="1.4em"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                          />
                                        </svg>
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>

                          <Col md={12} sm={12}>
                            <div className="features feature-primary p-1">
                              <div className="mb-3">
                                <Row>
                                  <Col md={3} sm={12}>
                                    <Label className="form-label label_text mt-2">
                                      New Password:{' '}
                                    </Label>
                                  </Col>
                                  <Col md={4} sm={12}>
                                    <div className="form-icon position-relative">
                                      <img
                                        src={newpasswordIcon}
                                        style={{
                                          position: `absolute`,
                                          width: `45px`,
                                        }}
                                      />
                                    </div>
                                    <Input
                                      type={
                                        showNewPassword ? 'text' : 'password'
                                      }
                                      className="form-control form_Input ps-5"
                                      placeholder="New Password"
                                      name="newpassword"
                                      required
                                      id="newpassword"
                                      defaultValue={
                                        validation.values.newPassword
                                      }
                                    />

                                    <span
                                      style={{
                                        position: 'absolute',
                                        right: '26px',
                                        top: '59%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                      }}
                                      onClick={toggleNewPasswordVisibility}
                                    >
                                      {showNewPassword ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1.4em"
                                          height="1.4em"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1.4em"
                                          height="1.4em"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                          />
                                        </svg>
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>

                          <Col md={12} sm={12}>
                            <div className="features feature-primary p-1">
                              <div className="mb-3">
                                <Row>
                                  <Col md={3} sm={12}>
                                    <Label className="form-label label_text mt-2">
                                      Confirm Password:{' '}
                                    </Label>
                                  </Col>
                                  <Col md={4} sm={12}>
                                    <div className="form-icon position-relative">
                                      <img
                                        src={confirmpasswordIcon}
                                        style={{
                                          position: `absolute`,
                                          width: `38px`,
                                        }}
                                      />
                                    </div>
                                    <Input
                                      type={
                                        showConfirmPassword
                                          ? 'text'
                                          : 'password'
                                      }
                                      className="form-control form_Input ps-5"
                                      placeholder="Confirm Password"
                                      name="confirmpassword"
                                      required
                                      id="confirmpassword"
                                      onBlur={matchPassword}
                                      defaultValue={
                                        validation.values.confirmpassword
                                      }
                                    />
                                    <span
                                      style={{
                                        position: 'absolute',
                                        right: '26px',
                                        top: '59%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                      }}
                                      onClick={toggleConfirmPasswordVisibility}
                                    >
                                      {showConfirmPassword ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1.4em"
                                          height="1.4em"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1.4em"
                                          height="1.4em"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                          />
                                        </svg>
                                      )}
                                    </span>
                                    <small
                                      className="text-danger"
                                      style={{ position: `absolute` }}
                                    >
                                      {errorMessage}
                                    </small>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                          <button
                            type="submit"
                            disabled={isButtonDisabled}
                            className="btn btn-outline-primary mt-2 "
                          >
                            {' '}
                            Save Changes{' '}
                          </button>{' '}
                          <button
                            onClick={handleReset}
                            type="reset"
                            className="btn btn-outline-secondary  mt-2 ms-2"
                          >
                            Reset
                          </button>
                        </div>
                        <hr style={{ boxShadow: `0px 1px 1px` }} />
                      </Form>
                    </Col>
                  )}

              
                  <Col md={12} xs={12}>
                    <img
                      src={referralIcon}
                      className="legendIcon"
                      style={{ width: `40px` }}
                    />
                    &nbsp;
                    <span className="m-2 legendName">Change Role</span>
                    <Row>
                      <Col md={3} xs={12}>
                        <div className=" features feature-primary py-0">
                          <div className="mb-3 flex-container">
                            &nbsp;
                            <select
                              value={role.membertype} // Add this
                              onChange={(e) =>
                                setRole({ ...role, membertype: e.target.value })
                              }
                              className="form-select form-control form_Input width100"
                              required
                              placeholder="Select"
                              id="roleid"
                            >
                              <option value="">Select Role</option>
                              <option
                                value="1"
                                disabled={configurationData.membertypeid === 1}
                              >
                                Allaince Seeker
                              </option>
                              <option
                                value="4"
                                disabled={configurationData.membertypeid === 4}
                              >
                                Community Member
                              </option>
                            </select>
                          </div>
                        </div>
                      </Col>

                      <Col md={2}>
                        <button
                          type="button"
                          className="btn btn-outline-primary mt-20"
                          onClick={() => changeRole()}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                  </Col>

                  <Col md={12} className="mt-3">
                    <img
                      src={accountManagementIcon}
                      className="legendIcon"
                      style={{ width: `40px` }}
                    />
                    &nbsp;{' '}
                    <span className="m-2 legendName">Account Management</span>
                    <Row className="m-2">
                      <Col md={12} sm={12}>
                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            {configurationData.memberstatuscode === 1 ||
                            configurationData.memberstatuscode === 3 ? (
                              <Row>
                                <Col md={12} sm={12}>
                                  <Label className="form-label label_text mt-2">
                                    Deactivate Account
                                  </Label>
                                  {/* <p className='text-dark p-0 m-0'>{configurationData.firstname}, we’re sorry to see you go</p>
 <p className='text-muted p-0 m-0'>Just a quick reminder, If you won't login upto 6 month then your account, you may deleted from MettaMate.</p> */}
                                  <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                    <button
                                      onClick={() =>
                                        openConfirmModal('Deactivate')
                                      }
                                      className="btn btn-outline-primary mt-2 rounded"
                                    >
                                      {' '}
                                      Continue{' '}
                                    </button>{' '}
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col md={12} sm={12}>
                                  <Label className="form-label label_text mt-2">
                                    Activate Account
                                  </Label>
                                  {/* <p className='text-dark p-0 m-0'>{configurationData.firstname}, we’re sorry to see you go</p>
                              <p className='text-muted p-0 m-0'>Just a quick reminder, If you won't login upto 6 month then your account, you may deleted from MettaMate.</p> */}
                                  <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                    <button
                                      onClick={() =>
                                        openConfirmModal('Activate')
                                      }
                                      className="btn btn-outline-primary mt-2 rounded"
                                    >
                                      {' '}
                                      Continue{' '}
                                    </button>{' '}
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                    <Row className="m-2">
                      <Col md={12} sm={12}>
                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            <Row>
                              <Col md={12} sm={12}>
                                <Label className="form-label label_text mt-2">
                                  Delete Account
                                </Label>
                                <p className="text-dark p-0 m-0">
                                  {configurationData.firstname}, Sorry to see
                                  you to delete the account. Friendly reminder,
                                  If you delete your account , you'll lose all
                                  your connections and all your data will wipe
                                  out.{' '}
                                </p>

                                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                  <button
                                    type="submit"
                                    className="btn btn-outline-primary mt-2 rounded"
                                    onClick={() => openConfirmModal('Delete')}
                                  >
                                    {' '}
                                    Continue{' '}
                                  </button>{' '}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>

                      <hr style={{ boxShadow: `0px 1px 1px` }} />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>

      <Modal isOpen={confirmmodalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          Confirmation
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className="mb-0">Are You Sure to {status} ?</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button color="secondary" onClick={closeModal} type="button">
            Close
          </Button>
          {status == 'Deactivate' && (
            <Button
              color="danger"
              type="button"
              onClick={(e) => changeActivationStatus(2)}
            >
              Deactivate
            </Button>
          )}

          {status == 'Activate' && (
            <Button
              color="danger"
              type="button"
              onClick={(e) => changeActivationStatus(1)}
            >
              Activate
            </Button>
          )}
          {status == 'Delete' && (
            <Button color="danger" type="button" onClick={() => deleteMember()}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default SettingFunction;
