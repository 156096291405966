import { paymentService } from '../services/api';

const loadRazorpayScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => resolve(true);
    script.onerror = () => reject(false);
    document.body.appendChild(script);
  });
};

export const initiatePayment = async (paymentAmount, handlePaymentSuccess, callApi) => {
  try {
    await loadRazorpayScript();
   
    const orderResponse = await callApi(() => 
      paymentService.createPaymentOrder({
        memberuuid: localStorage.getItem('memberID'),
        amount: paymentAmount,
        currency: 'INR'
      })
    );

    let configurationData = JSON.parse(localStorage.getItem('configurationData'));
    let companyLogoURL = 'https://mettamate.ai/final_logo_icon_only.png';

    var options = {
      key: 'rzp_live_582ZmIPuMhKJsM',
      amount: paymentAmount * 100,
      currency: 'INR',
      name: 'MettaMate Platform',
      description: 'Registering fees for Mentorship',
      image: companyLogoURL,
      order_id: orderResponse.orderid,
      handler: function (paymentResponse) {
        handlePaymentSuccess(paymentResponse);
      },
      prefill: {
        name: configurationData.firstname,
        email: configurationData.emailid,
      },
      notes: {
        address: 'MettaMate Pvt. Ltd., Mysoor, KA',
      },
      theme: {
        color: '#d6855c',
      },
    };

    var rzp1 = new Razorpay(options);
    rzp1.open();

  } catch (error) {
    throw new Error('Failed to initiate payment. Please try again.');
  }
};
