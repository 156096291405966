import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  CardImg,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import educationServiceImage from '../../assets/images/mettamate/personalConsultationPurchased.jpg';
import healthServiceImage from '../../assets/images/MettaMateImages/services/health-services-main.jpeg';
import professionalDevelopment from '../../assets/images/mettamate/Mentorship.jpeg';
import mentorServiceImage from '../../assets/images/MettaMateImages/services/mentor-services-main.jpeg';
import scholarshipServiceImage from '../../assets/images/MettaMateImages/services/scholarship-services-main.jpeg';
import matrimonyServiceImage from '../../assets/images/MettaMateImages/services/matrimony-services-main.jpeg';

const ServicePage = () => {
  const ServicePageCard = [
    {
      id: 1,
      image: educationServiceImage,
      title:
        'Join Educational Transformation: Empowering Marginalized Communities',
      link: '/EducationPage',
    },
    {
      id: 2,
      image: healthServiceImage,
      title:
        'Transforming Lives: Health and Wellness for Underprivileged Communities',
      link: '/TransformLivesPage',
    },
    {
      id: 3,
      image: professionalDevelopment,
      title:
        'Join Professional community network, get referred and enhance your job skills',
      link: '/ProfessionalDevelopmentPage',
    },
    {
      id: 4,
      image: mentorServiceImage,
      title: 'Transform Your Career: Mentorship From Seasoned Professionals',
      link: '#',
    },
    {
      id: 5,
      image: scholarshipServiceImage,
      title: 'Realize Your Dreams: Unlock various funding opportunities',
      link: '/ScholarshipPage',
    },
    {
      id: 6,
      image: matrimonyServiceImage,
      title:
        'Find your Perfect partner: A Platform for Building Strong Relationships',
      link: '#',
    },
  ];

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <h2 className="text-center">
              MettaMate is a venture that solves critical social challenges
            </h2>
          </Row>

          <Row className="shadshadow-md p-3 bg-body rounded">
            {ServicePageCard.map((card, key) => (
              <Col lg="4" md="6" className="mb-4 pb-2" key={key} name="blog">
                <Card 
                  className="blog rounded border-primary shadow overflow-hidden h-100"
                  style={{
                    borderRadius: '12px',
                    border: '1px solid #e2e8f0',  // Add light border
                    transition: 'all 0.3s ease',
                  }}
                >
                  <div className="position-relative">
                    <CardImg
                      top
                      src={card.image}
                      className="rounded-top"
                      alt=""
                    />
                    <div className="overlay rounded-top bg-dark"></div>
                  </div>
                  <CardBody className="content d-flex flex-column">
                    <h5 className="card-title">
                      {card.title}
                    </h5>
                    <div className="mt-auto">
                      <Link 
                        to={card.link}
                        className="btn btn-primary w-100 mt-3 d-flex align-items-center justify-content-center gap-2"
                        style={{
                          background: 'linear-gradient(135deg, #2563EB, #1E40AF)',
                          border: 'none',
                          borderRadius: '8px',
                          padding: '0.75rem',
                          transition: 'all 0.3s ease',
                          fontWeight: '500',
                          fontSize: '0.95rem',
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'translateY(-2px)';
                          e.currentTarget.style.boxShadow = '0 4px 12px rgba(37, 99, 235, 0.3)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = 'none';
                        }}
                      >
                        <span>Explore More</span>
                        <ArrowRight size={18} />
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <h4 className="text-center">
              Sign up now and begin your journey towards meaningful connections,
              personal growth, and help to build the strong Empowering Community
              Network
            </h4>
            <Link to={'/user-registration-page'}>
              <div className="text-center pt-2">
                <Button color="primary">Join Now</Button>
              </div>
            </Link>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ServicePage;
