import React, { useState } from 'react';
import { Card, CardBody, Button, Badge } from 'reactstrap';
import {
  BookmarkPlus,
  Share2,
  Building2,
  MapPin,
  Clock,
  Copy,
  Check,
  Edit,
  Trash2,
} from 'lucide-react';
import { Modal, message, Input } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import * as Icons from 'react-icons/fa';
import ProfileModal from '../../Home/Profile_modal.jsx';
import ModalBodySkeleton from '../../Home/skeleton.jsx';
import apiUrl from '../../server.js';
import { json, useNavigate, Link } from 'react-router-dom';

const typographyStyles = {
  title: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '1.35rem',
    fontWeight: '600',
    lineHeight: '1.4',
    color: '#1A202C',
    marginBottom: '0.5rem',
  },
  company: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '1rem',
    fontWeight: '500',
    color: '#2D3748',
  },
  metadata: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.9rem',
    lineHeight: '1.5',
    color: '#4A5568',
    fontWeight: '400',
  },
  description: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.95rem',
    lineHeight: '1.6',
    color: '#2D3748',
    marginBottom: '0.75rem',
    fontWeight: '400',
    '& ul, & ol': {
      paddingLeft: '20px',
      marginBottom: '0.5rem',
    },
    '& li': {
      marginBottom: '0.25rem',
    },
  },
};

const badgeStyles = {
  base: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.9rem',
    fontWeight: '500',
    padding: '0.35rem 0.85rem',
    borderRadius: '6px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.5rem',
    transition: 'all 0.2s ease',
  },
  skill: {
    backgroundColor: '#F7FAFC',
    color: '#2D3748',
    border: '1px solid #E2E8F0',
    fontSize: '0.8rem',
    padding: '0.25rem 0.75rem',
  },
  skillsContainer: {
    maxHeight: '2.5rem', // Reduced to show only one line
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    alignItems: 'center',
  },
  skillsOverflow: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '3rem',
    background: 'linear-gradient(90deg, rgba(255,255,255,0), #ffffff 40%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '0.5rem',
    color: '#0D6EFD',
    fontSize: '0.875rem',
    fontWeight: '500',
  },
};

// Add these utility functions at the top with other functions
const extractBulletPoints = (htmlContent) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;

  // Get all list items
  const listItems = tempDiv.getElementsByTagName('li');
  let bullets = Array.from(listItems).map((li) => li.textContent.trim());

  // If no bullet points found, try to get plain text
  if (bullets.length === 0) {
    bullets = [tempDiv.textContent.trim()];
  }

  // Limit each bullet to 20 words and take max 3 bullets
  return bullets.slice(0, 3).map((bullet) => {
    const words = bullet.split(' ');
    if (words.length > 20) {
      return words.slice(0, 20).join(' ') + '...';
    }
    return bullet;
  });
};

// Add this utility function at the top with other functions
const truncateDescription = (htmlContent) => {
  // Strip HTML tags
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;
  const plainText = tempDiv.textContent || tempDiv.innerText;

  // Get first 20 words
  const words = plainText.trim().split(/\s+/);
  return words.slice(0, 20).join(' ') + (words.length > 20 ? '...' : '');
};

const JobCard = ({ job, canEdit, onEdit }) => {
  // alert("canEdit: " + canEdit);
  const navigate = useNavigate();
  // Keep existing state variables
  const [memberModal, setModal] = useState(false);
  const [memberFullData, setMemberFullData] = useState([]);
  const [isModalLoading, setModalLoading] = useState(true);
  const [dob, setDOB] = useState({});
  const [currentLocation, setCurrentLocation] = useState({});
  const [nativeLocation, setNativeLocation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});

  // Add new state for share functionality
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  // Function to format salary range with currency
  const formatSalary = (salary, currency = '₹') => {
    return salary ? `${currency}${salary}` : 'Not Disclosed';
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${apiUrl}jobmgmt//${event.eventuuid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      message.success('Event deleted successfully');
      window.location.reload();
    } catch (error) {
      console.error('Failed to delete event:', error);
      message.error('Failed to delete event');
    }
    setIsDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  // Format the date to relative time
  const getRelativeTime = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const diffTime = Math.abs(now - postedDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    return `${Math.floor(diffDays / 30)} months ago`;
  };

  // Function to strip HTML tags
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };

  const createMarkup = (content) => {
    return { __html: content };
  };

  // Add these helper functions
  const getOrdinalSuffix = (day) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const getMonthName = (month) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[month];
  };

  // Keep only this version of handleMemberClick
  const handleMemberClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModal(true);
    setModalLoading(true);

    try {
      const result = await axios.get(
        `${apiUrl}members/fulldetails/${job.referrer_uuid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      const { data } = result;
      if (data && data.length > 0) {
        const member = data[0];
        const date = new Date(member.dob);
        setDOB(
          `${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`,
        );
        setNativeLocation(member.nativelocationname || 'Location not updated');
        setCurrentLocation(
          member.currentlocationname || 'Location not updated',
        );
        setCompanyLocation(
          member.companylocationname || 'Location not updated',
        );
        setMemberFullData(member);
        setModalLoading(false);
      }
    } catch (error) {
      console.error('Error fetching member details: ', error);
      setModalLoading(false);
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  // Add share handlers
  const handleShare = () => {
    const shareUrl = `${window.location.protocol}//${window.location.host}/job/${job.share_url || job.id}`;
    setShareUrl(shareUrl);
    setIsShareModalVisible(true);
  };

  const handleCopy = () => {
    setCopied(true);
    message.success('Link copied to clipboard!');
    setTimeout(() => setCopied(false), 3000);
  };

  const handleNativeShare = async () => {
    try {
      await navigator.share({
        title: job.job_title,
        text: `Check out this job: ${job.job_title} at ${job.company_name}`,
        url: shareUrl,
      });
      message.success('Job shared successfully!');
      setIsShareModalVisible(false);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }
      message.error('Failed to share job');
      console.error('Share failed:', error);
    }
  };

  const handleCardClick = (e) => {
    // Prevent navigation if clicking on buttons or links
    if (
      e.target.tagName === 'BUTTON' ||
      e.target.tagName === 'A' ||
      e.target.closest('button') ||
      e.target.closest('a')
    ) {
      return;
    }
    // Update to use the correct job identifier from your data
    //alert(JSON.stringify(job));
    navigate(`/jobs/${job.job_uuid}`);
  };

  return (
    <>
      <Card
        className="h-100 hover-shadow transition-all border-primary border-2 position-relative"
        style={{
          borderRadius: '12px',
          border: '1px solid #E2E8F0',
          background: '#ffffff',
          cursor: 'pointer', // Add cursor pointer
        }}
        onClick={handleCardClick}
      >
        {canEdit && (
          <div className="position-absolute top-0 end-0 p-3 d-flex gap-2">
            <Button
              color="light"
              size="sm"
              className="d-flex align-items-center gap-1 px-3 py-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEdit(job);
              }}
              style={{
                borderRadius: '15px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }}
            >
              <Edit size={16} />
              <span className="fs-6">Edit</span>
            </Button>

            {/* <Button
              color="danger"
              size="sm"
              className="d-flex align-items-center justify-content-center"
              onClick={handleDelete}
              style={{
                borderRadius: '15px',
                width: '38px',
                height: '38px',
                padding: 0,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: 'rgba(255, 59, 48, 0.9)',
              }}
            >
              <Trash2 size={16} color="white" />
            </Button> */}
          </div>
        )}
        <CardBody className="p-3">
          <div className="d-flex justify-content-between align-items-start mb-3">
            <h5 style={typographyStyles.title}>
              {stripHtmlTags(job.job_title)}
            </h5>
            {/* <Button
              color="light"
              size="sm"
              className="d-flex align-items-center justify-content-center"
              style={{
                borderRadius: '15px',
                width: '38px',
                height: '38px',
                padding: 0,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }}
            >
              <BookmarkPlus size={16} />
            </Button> */}
          </div>

          <div className="d-flex flex-column gap-2 mb-3">
            <div className="d-flex align-items-center">
              <Building2 size={16} className="me-2 text-primary" />
              <span style={typographyStyles.company}>
                {stripHtmlTags(job.company_name)}
              </span>
            </div>

            <div className="d-flex align-items-center">
              <MapPin size={16} className="me-2 text-primary" />
              <span style={typographyStyles.metadata}>
                {stripHtmlTags(job.location_address)}
              </span>
            </div>

            <div className="d-flex align-items-center">
              <Clock size={16} className="me-2 text-primary" />
              <span style={typographyStyles.metadata}>
                {getRelativeTime(job.created_at)}
              </span>
            </div>
          </div>

          <div className="d-flex flex-wrap gap-2 mb-3">
            {[
              { text: job.employment_type, color: '#3182CE' },
              {
                text: `${job.experience_required} years exp`,
                color: '#38A169',
              },
              { text: formatSalary(job.salary_range), color: '#319795' },
            ].map((badge, index) => (
              <div
                key={index}
                style={{
                  ...badgeStyles.base,
                  backgroundColor: `${badge.color}15`,
                  color: badge.color,
                  border: `1px solid ${badge.color}30`,
                }}
              >
                {badge.text}
              </div>
            ))}
          </div>
          {/* <div style={typographyStyles.description} className="mb-3">
            <div className="d-flex">
              <span className="me-2" style={{ color: '#0D6EFD' }}>
                •
              </span>
              <span>{truncateDescription(job.description)}</span>
            </div>
          </div> */}

          <div className="mb-3" style={badgeStyles.skillsContainer}>
            {job.skills_required.map((skill, index) => (
              <div
                key={index}
                className="skill-tag"
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  padding: '0.5rem 1rem',
                  borderRadius: '12px',
                  backgroundColor: 'rgba(66, 153, 225, 0.08)',
                  color: '#2B6CB0',
                  border: '1px solid rgba(66, 153, 225, 0.2)',
                  fontSize: '0.875rem',
                  whiteSpace: 'nowrap',
                }}
              >
                <Icons.FaCheckCircle size={12} className="me-2" />
                {skill}
              </div>
            ))}
            {job.skills_required.length > 3 && (
              <div style={badgeStyles.skillsOverflow}>...</div>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-start mt-3 pt-3 border-top">
            {/* Left side with Posted By and Expiry Date stacked vertically */}
            <div className="d-flex flex-column gap-2">
              <div className="d-flex align-items-center gap-2">
                <span className="text-muted" style={{ fontSize: '0.875rem' }}>
                  Posted By:
                </span>
                <Link
                  to={`/member/${job.referrer_uuid}`}
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    color: '#007bff',
                    fontWeight: '500',
                    fontSize: '0.875rem',
                    textDecoration: 'none'
                  }}
                >
                  {job.firstname}
                </Link>
              </div>

              <div className="d-flex align-items-center">
                <span style={typographyStyles.metadata}>
                  Expires: {new Date(job.expires_at).toLocaleDateString()}
                </span>
              </div>
            </div>

            {/* Replace the text with a beautiful button */}
            <div className="d-flex gap-2">
              <Button
                color="primary"
                size="sm"
                className="view-details-btn d-flex align-items-center gap-2"
                onClick={() => navigate(`/jobs/${job.job_uuid}`)}
                style={{
                  background: 'linear-gradient(45deg, hsl(237, 88%, 49%), rgb(229, 7, 163))',
                  border: 'none',
                  borderRadius: '20px',
                  padding: '0.5rem 1.25rem',
                  fontSize: '0.875rem',
                  fontWeight: '500',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  transition: 'all 0.3s ease'
                }}
              >
                <span>View Details</span>
                <i className="fas fa-arrow-right"></i>
              </Button>
            </div>
          </div>
        </CardBody>

        {/* Add Member Modal */}
        <Modal
          title="Profile Details"
          open={memberModal}
          onCancel={closeModal}
          width="80%"
          footer={null}
        >
          {isModalLoading ? (
            <ModalBodySkeleton />
          ) : (
            <>
              {memberFullData && Object.keys(memberFullData).length > 0 && (
                <ProfileModal
                  memberData={memberFullData}
                  location={[nativeLocation, currentLocation, companyLocation]}
                  dob={dob}
                />
              )}
            </>
          )}
        </Modal>

        {/* Add Share Modal */}
        <Modal
          title="Share Job"
          open={isShareModalVisible}
          onCancel={() => setIsShareModalVisible(false)}
          footer={null}
        >
          <div className="p-4">
            <h5 className="mb-3">Share "{job.job_title}"</h5>

            <div className="d-flex gap-2 align-items-center mb-4">
              <Input
                value={shareUrl}
                readOnly
                className="flex-grow-1"
                style={{
                  backgroundColor: '#f8f9fa',
                  padding: '0.75rem',
                  borderRadius: '8px',
                }}
              />
              <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
                <Button
                  color={copied ? 'success' : 'primary'}
                  className="d-flex align-items-center gap-2"
                >
                  {copied ? (
                    <>
                      <Check size={16} />
                      Copied!
                    </>
                  ) : (
                    <>
                      <Copy size={16} />
                      Copy
                    </>
                  )}
                </Button>
              </CopyToClipboard>
            </div>

            {navigator.share && (
              <div className="mt-4 border-top pt-4">
                <h6 className="mb-3">Or share via</h6>
                <Button
                  color="primary"
                  outline
                  className="w-100"
                  onClick={handleNativeShare}
                >
                  <Share2 size={16} className="me-2" />
                  Share on your device
                </Button>
              </div>
            )}
          </div>
        </Modal>

        {/* Delete Confirmation Modal */}
        {/* <Modal
          title={
            <div className="text-danger">
              <Trash2 className="me-2" size={20} />
              Delete Job Posting
            </div>
          }
          open={isDeleteModalVisible}
          onCancel={handleDeleteCancel}
          footer={[
            <Button key="ok" color="primary" onClick={handleDeleteCancel}>
              OK
            </Button>,
          ]}
        >
          <div className="py-4">
            <h5>This job posting "{job.job_title}" has been deleted.</h5>
            <div className="alert alert-info mt-3">
              <p className="mb-0">
                <strong>Note:</strong> If you need to restore this job posting,
                please contact MettaMate's admin support.
              </p>
            </div>
          </div>
        </Modal> */}

        <style>
          {`
            .hover-shadow {
              transition: all 0.3s ease;
              box-shadow: 0 2px 4px rgba(0,0,0,0.05);
            }
            .hover-shadow:hover {
              transform: translateY(-4px);
              box-shadow: 0 12px 24px rgba(0,0,0,0.1) !important;
            }
            .transition-all {
              transition: all 0.3s ease;
            }
            .job-description ul, .job-description ol {
              padding-left: 20px;
              margin-bottom: 0.5rem;
            }
            .job-description li {
              margin-bottom: 0.25rem;
            }
            .job-description p {
              margin-bottom: 0.75rem;
            }
            .skill-tag {
              transition: transform 0.2s ease;
            }
            .skill-tag:hover {
              transform: translateY(-1px);
            }
            .view-details-btn:hover {
              transform: translateY(-2px);
              box-shadow: 0 4px 8px rgba(0,0,0,0.2);
              background: linear-gradient(45deg, rgb(229, 7, 163), hsl(237, 88%, 49%)) !important;
            }
          `}
        </style>
      </Card>
    </>
  );
};

export default JobCard;
