import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardImg,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Slider from 'react-slick';
import { useApi } from '../../../hooks/useApi';
import { favouriteService } from '../../../services/api';
import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AsMemberCard = ({
  member,
  currentPage,
  searchCriteria,
  configurationData,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFavorite, setIsFavorite] = useState(member.likedstatus);
  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false);

  const { callApi } = useApi();

  const memberTypeEnums = {
    ALLIANCE_SEEKER: 1,
    COMMUNITY_MEMBER: 4,
  };

  const handleCardClick = () => {
    navigate(`/member/${member.memberuuid}`, {
      state: {
        locationUrl: '/asMembers',
        returnPage: currentPage,
        searchCriteria: searchCriteria,
        memberConnectionStatus: member.connectionstatus,
      },
    });
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleLikeModal = () => setIsLikeModalOpen(!isLikeModalOpen);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    toggleModal();
  };

  const handleFavoriteClick = async (memberuuid, likedstatus) => {
    if (!configurationData || Object.keys(configurationData).length === 0) {
      navigate('/index');
      return;
    }
    
    // Check subscription status
    if (configurationData.subscription !== 'Basic') {
      toggleLikeModal();
    } else {
      navigate('/subscriptionplan');
    }
  };

  const handleFavoriteConfirmation = async () => {
    try {
      await callApi(() => 
        favouriteService.addFavourite({
          likedmemberuuid: member.memberuuid,
          memberuuid: localStorage.getItem('memberID'),
          membertypeid: memberTypeEnums.ALLIANCE_SEEKER,
        })
      );
      
      setIsFavorite(!isFavorite);
      toggleLikeModal();
    } catch (error) {
      console.error('Error updating favorite status:', error);
      // Handle specific error cases if needed
    }
  };

  const defaultImage = member.gender === 'Male' ? manavatar : femaleavatar;
  const photos = [
    member.photothumb1 || defaultImage,
    member.photothumb2 || defaultImage,
    member.photothumb3 || defaultImage,
  ];

  const formattedHeight =
    typeof member.height === 'number' && member.height > 0
      ? Number(member.height).toFixed(1)
      : Number(member.height).toFixed(1);

  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .trim();
  };

  const formattedFirstName = capitalizeFirstLetter(member.firstname);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ul style={{ margin: 0, padding: 0 }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: '#fff',
          opacity: 0.75,
        }}
      />
    ),
  };

  return (
    <Card className="member-card border-0 shadow-md h-100">
      <div className="position-relative">
        <Slider {...settings}>
          {photos.map((photo, index) => (
            <div key={index}>
              <CardImg
                top
                src={photo}
                alt={formattedFirstName}
                className="member-photo"
                style={{
                  objectFit: 'cover',
                  cursor: 'pointer',
                  width: '100%',
                  height: 'auto', // Adjust the height to auto
                }}
                onClick={() => handleImageClick(photo)}
              />
            </div>
          ))}
        </Slider>
        <div className="member-badges position-absolute top-0 end-0 p-2 d-flex gap-2">
          {member.isPremium && (
            <span
              className="badge rounded-pill"
              style={{
                background: 'linear-gradient(45deg, #FFD700, #FFA500)',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
            >
              <FeatherIcon icon="award" size={12} fill="#FFF" />
              <span className="ms-1">Premium</span>
            </span>
          )}
          {member.isVerified && (
            <span
              className="badge rounded-pill"
              style={{
                background: 'linear-gradient(45deg, #00C853, #69F0AE)',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
            >
              <FeatherIcon icon="check-circle" size={12} fill="#FFF" />
              <span className="ms-1">Verified</span>
            </span>
          )}
        </div>
        {member.maritalstatus && (
          <div className="position-absolute top-0 start-0 p-2">
            <span
              className="badge fs-7"
              style={{
                background: 'linear-gradient(45deg,hsl(237, 88.00%, 49.00%),rgb(229, 7, 163))',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
            >
              {member.maritalstatus}
            </span>
          </div>
        )}
      </div>

      <CardBody className="p-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5 className="mb-0">
            {formattedFirstName}, {member.currentage}
          </h5>
          <span className="d-flex align-items-center">
            <FeatherIcon
              icon="arrow-up"
              size={14}
              style={{ color: '#6610f2' }}
            />
            <span className="ms-1 fs-6">{formattedHeight} ft</span>
          </span>
          <Button
            color="link"
            className="p-0 favorite-btn"
            onClick={() =>
              handleFavoriteClick(member.memberuuid, member.likedstatus)
            }
          >
            <FeatherIcon
              icon="heart"
              fill={isFavorite ? '#dc3545' : 'none'}
              stroke={isFavorite ? '#dc3545' : 'currentColor'}
            />
          </Button>
        </div>

        <div className="member-info mb-3">
          <div className="d-flex align-items-center text-dark mb-2">
            <FeatherIcon
              icon="briefcase"
              size={14}
              style={{ color: '#0d6efd' }}
            />
            <span className="ms-2 fs-6 text-dark">
              Profession: {member.jobprofession || 'Not Entered'}
            </span>
          </div>

          <div className="d-flex align-items-center text-dark mb-2">
            <FeatherIcon
              icon="map-pin"
              size={14}
              style={{ color: '#dc3545' }}
            />
            <span className="ms-2 fs-6 text-dark">{member.currentlocationname}</span>
          </div>

          {/* <div className="d-flex align-items-center text-dark mb-2">
            <FeatherIcon icon="book" size={14} style={{ color: '#198754' }} />
            <span className="ms-2">
              {(member.degreename || member.degreelevels || 'Degree Name') +
                ', ' +
                (member.degreecollege || 'College Name')}
            </span>
          </div> */}
        </div>

        <div className="member-details d-flex flex-wrap gap-3 mb-1 border-top pt-1">
          {/* Empty Space You can add things here */}
        </div>

        <Button
          color="primary"
          block
          className="mt-1 as-view-profile-btn"
          onClick={handleCardClick}
          style={{
            background: 'linear-gradient(45deg, hsl(237, 88%, 49%), rgb(229, 7, 163))',
            border: 'none',
            color: 'white',
            fontWeight: '600',
            textTransform: 'uppercase',
            letterSpacing: '0.5px',
            transition: 'all 0.3s ease',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '0.45rem'
          }}
        >
          View Profile
        </Button>
      </CardBody>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <button
          type="button"
          aria-label="Close"
          className="close-button"
          onClick={toggleModal}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
            zIndex: '1000',
          }}
        >
          &times;
        </button>
        <ModalBody>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Modal"
              className="img-fluid"
              style={{ width: '100%' }}
            />
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={isLikeModalOpen} toggle={toggleLikeModal}>
        <ModalHeader toggle={toggleLikeModal}>
          Confirmation
          <button
            type="button"
            className="close-button"
            aria-label="Close"
            onClick={toggleLikeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <p className="mb-0">
            Are you sure you want to {isFavorite ? 'unlike' : 'like'} this
            member?
          </p>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button color="secondary" onClick={toggleLikeModal} type="button">
            Close
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={handleFavoriteConfirmation}
          >
            {isFavorite ? 'Unlike' : 'Like'}
          </Button>
        </ModalFooter>
      </Modal>

      <style>
        {`
          .member-card {
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            border-radius: 12px;
            overflow: hidden;
            border: 1px solid #e0e0e0; /* Light border */
            background-color: #f9f9f9; /* Light background */
          }

          .member-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
          }

          .member-photo {
            width: 100%;
            height: auto; /* Adjust the height to auto */
            object-fit: cover;
          }

          .favorite-btn:hover {
            transform: scale(1.2);
            transition: transform 0.2s ease;
          }

          .badge {
            font-weight: 500;
            padding: 0.5em 0.8em;
            font-size: 0.75em;
            text-transform: uppercase;
            letter-spacing: 0.5px;
          }

          .marital-status-badge {
            text-transform: none;
          }

          .member-info span,
          .member-details span {
            font-size: 0.90rem;
            font-weight: 500;
            letter-spacing: 0.2px;
            color: #495057;
          }

          .slick-dots {
            bottom: 10px !important; /* Ensure dots are on the image */
          }

          .slick-dots li button:before {
            font-size: 10px;
            color: white;
            opacity: 0.75;
          }

          .slick-dots li.slick-active button:before {
            color: white;
            opacity: 1;
          }

          .as-view-profile-btn {
            background: linear-gradient(45deg, hsl(237, 88%, 49%), rgb(229, 7, 163)) !important;
            border: none !important;
            color: white !important;
            font-weight: 600 !important;
            text-transform: uppercase !important;
            letter-spacing: 0.5px !important;
            transition: all 0.3s ease !important;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
          }

          .as-view-profile-btn:hover {
            background: linear-gradient(45deg, rgb(229, 7, 163), hsl(237, 88%, 49%)) !important;
            transform: translateY(-2px) !important;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2) !important;
          }
        `}
      </style>
    </Card>
  );
};

export default AsMemberCard;
