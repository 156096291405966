import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalFooter,
  ModalHeader,
  Container,
  Table,
  PaginationItem,
  PaginationLink,
  Button,
  ModalBody,
} from 'reactstrap';
import ContentLoader from 'react-content-loader';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import ModalBodySkeleton from './skeleton';
import ProfileModal from './Profile_modal';
import CommonModal from '../Home/modal';
import Profilecard from '../Home/Profile_card';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import apiUrl from '../server';
import axios from 'axios';
import asIcon from '../../assets/images/MettaMateImages/allainceseeker.png';
import cmIcon from '../../assets/images/MettaMateImages/communitymember.png';
import usermanagement from '../../assets/images/MettaMateImages/usermanagement.png';
import eventsmanagement from '../../assets/images/MettaMateImages/eventsmanagement.png';
import '../Home/css/userHomePage.css';
import Subscription from './SubscriptionManagement';
import MentorsManagement from './mentorship/MentorsManagement'
import { useApi } from '../../hooks/useApi';
import { adminService } from '../../services/api';

const AdminPortalFunction = () => {
  const { callApi } = useApi();
  const [memberType, setMemberType] = useState(1);
  const [status, setStatus] = useState('Block');
  const [confirmmodalOpen, setconfirmModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [memberModal, setModal] = useState(false);
  const [memberFullData, setMemberFullData] = useState([]);
  const [dob, setDOB] = useState({});
  const [currentLocation, setCurrentLocation] = useState({});
  const [nativeLocation, setNativeLocation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [isModalLoading, setModalLoading] = useState(true);
  const [copyModalOpen, setcopyModalOpen] = useState(false);
  const [sharedMemberUuid, setsharedMemberUuid] = useState({});
  const [copyButton, setCopyButton] = useState('Copy Url');
  const [pageUrl, setpageUrl] = useState({});
  const [searching, setsearching] = useState('');

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [activeLink, setActiveLink] = useState(1);
  const [activeSlideLink, setActiveSlideLink] = useState(1);
  const [memberData, setmemberData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchItem, setSearchItem] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sliderLink, setSliderLink] = useState(1);

  const [memberRequestbody, setmemberRequestbody] = useState({
    pageNo: currentPage,
    pageSize: pageSize,
    memberuuid: localStorage.getItem('memberID'),
    membertype: '',
    search: '',
  });

  useEffect(() => {
    getAllMemberDetails(currentPage, pageSize, activeLink, '');
  }, []);

  useEffect(() => {
    renderPaginationItems();
  }, [totalCount]);

  //server side pagination
  const renderPaginationItems = () => {
    const items = [];

    // Always include the first page
    items.push(
      <PaginationItem key={1} active={currentPage === 1}>
        <PaginationLink onClick={() => handlePageChange(1)}>1</PaginationLink>
      </PaginationItem>,
    );

    // Conditionally add ellipses
    if (currentPage > 3) {
      items.push(
        <PaginationItem key="ellipsis1" disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    }

    // Determine the range of pages to display
    let start = Math.max(2, currentPage - 1);
    let end = Math.min(currentPage + 1, totalPages - 1);

    for (let i = start; i <= end; i++) {
      items.push(
        <PaginationItem key={i} active={currentPage === i}>
          <PaginationLink onClick={() => handlePageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }

    // Optionally add ellipses before the last page
    if (currentPage < totalPages - 2) {
      items.push(
        <PaginationItem key="ellipsis2" disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    }

    // Always include the last page if it's not the first page
    if (totalPages > 1) {
      items.push(
        <PaginationItem key={totalPages} active={currentPage === totalPages}>
          <PaginationLink onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>,
      );
    }
    return items;
  };
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber); // Increment currentPage using functional update
    if (activeLink == 1) {
      if (searchItem.length > 0) {
        await getAllMemberDetails(pageNumber, pageSize, activeLink, searchItem);
      } else {
        await getAllMemberDetails(pageNumber, pageSize);
      }
    } else if (activeLink == 4) {
      if (searchItem.length > 0) {
        await getAllMemberDetails(pageNumber, pageSize, activeLink, searchItem);
      } else {
        await getAllMemberDetails(pageNumber, pageSize, activeLink, '');
      }
    }
  };

  const menudata = [
    { icon: asIcon, menuName: 'AS Members', value: 1 },
    { icon: cmIcon, menuName: 'CM Members', value: 4 },
  ];
  const slidermenudata = [
    { icon: usermanagement, menuName: 'User Management', value: 1 },
    { icon: eventsmanagement, menuName: 'Mentors Management', value: 2 },
    //{ icon: eventsmanagement, menuName: 'Subscription Management', value: 3 },
  ];
  const errorHandler = () => {
    setError(null);
  };

  const successHandler = () => {
    setSuccess(null);
  };
  const menuslider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // Show 5 items at a time
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleLinkClick = (link) => {
    setActiveLink(link);
    setCurrentPage(1);
    setSearchItem('');
    getAllMemberDetails(1, pageSize, link, '');
  };
  const handleSlideClick = (link) => {
    setActiveSlideLink(link);
    setCurrentPage(1);
    if (link == 1) setSliderLink(1);
    else if (link == 2) setSliderLink(2);
  };

  const getAllMemberDetails = async (
    currentPage,
    pageSize,
    membertype,
    search,
  ) => {
    const requestBody = {
      currentPage,
      pageSize,
      membertype,
      search,
      memberuuid: localStorage.getItem('memberID')
    };

    setLoading(true);
    try {
      const response = await callApi(() => 
        adminService.getAllMembers(requestBody)
      );

      setTotalPages(Math.ceil(parseInt(response.totalCount) / pageSize));
      setTotalCount(response.totalCount);
      setmemberData(response.details);
    } catch (error) {
      console.error('Error fetching members:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setmemberRequestbody({ ...memberRequestbody, search: e.target.value });
      getAllMemberDetails(1, pageSize, activeLink, e.target.value);
      setSearchItem(e.target.value);
      setCurrentPage(1);
    }
  };

  const openConfirmModal = (item) => {
    setconfirmModalOpen(true);
    setData(item);
    if (item.membershipstatus == 'Active') {
      setStatus('Block');
    } else {
      setStatus('Unblock');
    }
  };
  const closeModal = () => {
    setModal(false);
    setStatus('');
  };
  const changeActivationStatus = async () => {
    const requestBody = {
      memberuuid: data.memberuuid,
      activationstatus: data.membershipstatus === 'Active' ? 2 : 1
    };

    try {
      const response = await callApi(() =>
        adminService.changeActivationStatus(requestBody)
      );

      setconfirmModalOpen(false);
      setSuccess({
        title: 'SUCCESS!',
        message: response.message
      });
      
      await getAllMemberDetails(currentPage, pageSize, activeLink, '');
    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.data?.message || 'Failed to update status'
      });
    }
  };

  const getOrdinalSuffix = (day) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };
  const getMonthName = (month) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[month];
  };
  const openProfileModal = async (memberuuid) => {
    setModal(!memberModal);
    try {
      const response = await callApi(() => 
        adminService.getFullMemberDetails(memberuuid)
      );

      if (response?.length > 0) {
        const member = response[0];
        const date = new Date(member.dob);

        setDOB(`${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`);
        setNativeLocation(member.nativelocationname || 'Location Not Updated');
        setCurrentLocation(member.currentlocationname || 'Location Not Updated');
        setCompanyLocation(member.companylocationname || 'Location Not Updated');
        setMemberFullData(member);
        setModalLoading(false);
      }
    } catch (error) {
      console.error('Error fetching member details:', error);
      setError({
        title: 'Error',
        message: 'Failed to fetch member details'
      });
    }
  };
  const openCopyModal = (memberdata) => {
    setcopyModalOpen(true);
    setsharedMemberUuid(memberdata.memberuuid);
  };
  const toggleCopyModal = () => {
    setcopyModalOpen(false);
  };

  const handleCopyText = () => {
    const urlToCopy = `${pageUrl}index/${sharedMemberUuid}`;
    const textArea = document.createElement('textarea');
    textArea.value = urlToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopyButton('Copied!');
    setTimeout(() => {
      setCopyButton('Copy Url');
    }, 3000);
  };
  const closeProfileModal = () => {
    setModal(false);
  };
  const handleRefresh = () => {
    setCurrentPage(1);
    setSearchItem('');
    if (activeLink == 1) {
      getAllMemberDetails(1, pageSize);
    } else if (activeLink == 4) {
      getAllMemberDetails(1, pageSize, activeLink, '');
    }
    // getAllMemberDetails(1, pageSize, memberType);
  };

  return (
    <>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <Profilecard />

      <Container className="mt-5 mb-2">
        <section className="section_user_home_page">
          <Container>
            <Row>
              <Col lg="12" className="Col_12">
                <Row>
                  <div className="w-5/6 m-auto inner_row">
                    <div className="mt-20 pb-3">
                      <Slider
                        {...menuslider}
                        className="overflow-hidden menu_slider "
                      >
                        {(slidermenudata || []).map((item, key) => (
                          <div
                            className="mx-2 list-unstyled profession_ul text-center"
                            key={key}
                          >
                            <div
                              style={{
                                padding: `5px 10px 5px 5px`,
                                width: '177px',
                              }}
                              className={`text-center profession_li mt-1 ${
                                activeSlideLink === item.value ? 'active' : ''
                              }`}
                              onClick={() => handleSlideClick(item.value)}
                            >
                              <img
                                src={item.icon}
                                className="center"
                                style={{ width: `80px`, height: `60px` }}
                              />
                            </div>
                            <p
                              className="filter_title fw-normal m-auto"
                              style={{ fontSize: `15px` }}
                            >
                              <span
                                className={` ${activeSlideLink === item.value ? 'active_text' : ''}`}
                              >
                                {item.menuName}
                              </span>
                            </p>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </Row>
                {/* <Row>
                                    <div className='w-5/6 m-auto inner_row'>
                                        <div className='mt-20 pb-3'>
                                            <Slider {...menuslider} className="overflow-hidden menu_slider ">
                                                {(menudata || []).map((item, key) => (
                                                    <div className=' list-unstyled profession_ul text-center' key={key}>
                                                        <div
                                                            style={{ padding: `5px 10px 5px 5px` }}
                                                            className={`text-center profession_li mt-1 ${activeLink === item.value ? 'active' : ''
                                                                }`}
                                                            onClick={() => handleLinkClick(item.value)}>
                                                            <img src={item.icon} className='center' style={{ width: `80px`, height: `60px` }} />
                                                        </div>
                                                        <p className="filter_title fw-normal m-auto" style={{ fontSize: `16px` }}><span className={` ${activeLink === item.value ? 'active_text' : ''}`} >{item.menuName}</span></p>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </Row> */}
                {sliderLink == 1 && (
                  <>
                    <div className="row mt-2 mb-1">
                      <div className="col-md-6 px-0">
                        <div className="tabs">
                          {(menudata || []).map((item, key) => (
                            <>
                              <input
                                type="radio"
                                id={`radio-${key + 1}`}
                                name="tabs"
                                onClick={() => handleLinkClick(item.value)}
                              />
                              <label
                                className="tab"
                                htmlFor={`radio-${key + 1}`}
                              >
                                {item.menuName}
                              </label>
                            </>
                          ))}
                          <span className="glider"></span>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-end px-0">
                        <div className="col-md-6 d-flex my-2">
                          <i
                            className="fa fa-refresh m-3"
                            style={{ position: `static`, cursor: 'pointer' }}
                            onClick={() => handleRefresh()}
                          ></i>
                          <input
                            className="form-control mb-2 "
                            placeholder="Enter to search by Name"
                            onKeyPress={handleKeyPress}
                            defaultValue={memberRequestbody.search || ''}
                          />
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Row>
                        <div className=""></div>
                      </Row>
                      {isLoading ? (
                        <ContentLoader
                          style={{ width: `100%` }}
                          speed={2}
                          height={200}
                          viewBox="0 0 1000 200"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="1000"
                            height="20"
                          />
                          <rect
                            x="0"
                            y="30"
                            rx="3"
                            ry="3"
                            width="1000"
                            height="20"
                          />
                          <rect
                            x="0"
                            y="60"
                            rx="3"
                            ry="3"
                            width="1000"
                            height="20"
                          />
                          <rect
                            x="0"
                            y="90"
                            rx="3"
                            ry="3"
                            width="1000"
                            height="20"
                          />
                          <rect
                            x="0"
                            y="120"
                            rx="3"
                            ry="3"
                            width="1000"
                            height="20"
                          />
                          {/* Add more rows as needed */}
                        </ContentLoader>
                      ) : (
                        <>
                          <div className="col-md-9"></div>

                          <Table bordered hover>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Member Name</th>
                                <th>Subsciption Status</th>
                                <th>Member Type</th>
                                <th>Member Status</th>
                                <th>Action</th>
                                <th>Email</th>
                                <th>Phone</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(memberData || []).map((item, key) => (
                                <tr key={key}>
                                  <th scope="row">{item.srno}</th>
                                  <td className="text-primary">
                                    <u
                                      className="cursor-pointer"
                                      onClick={() =>
                                        openProfileModal(item.memberuuid)
                                      }
                                    >
                                      {item.firstname} {item.lastname}
                                    </u>
                                  </td>
                                  <td>{item.subscriptionplanname}</td>
                                  <td>{item.membertype}</td>
                                  <td className="text-center align-middle">
                                    {' '}
                                    <span
                                      className={`${item.membershipstatus == 'Active' ? 'active-users' : item.membershipstatus == 'Pending' ? 'pending-users' : item.membershipstatus == 'Rejected' ? 'rejected-users' : item.membershipstatus == 'Deactive' ? 'deactived-users' : ''}`}
                                    >
                                      {item.membershipstatus}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-warning btn-sm fw-bold"
                                      onClick={() => openConfirmModal(item)}
                                    >
                                      {item.membershipstatus == 'Deactive' ? (
                                        <span>Activate</span>
                                      ) : (
                                        <span>Block</span>
                                      )}
                                    </button>
                                  </td>
                                  <td>{item.emailid}</td>
                                  <td>+{item.phonecountrycode}-{item.phonenumber}</td>

                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}
                      <Col md={6}>
                        <div className="">
                          Total Counts :
                          <span className="totalCount">{totalCount}</span>
                        </div>
                      </Col>

                      <Col md={6} className="text-end">
                        {totalCount > pageSize ? (
                          <>
                            {memberData.length > 0 && (
                              <ul className="pagination justify-content-end mb-0">
                                <PaginationItem disabled={currentPage === 1}>
                                  <PaginationLink
                                    to="#"
                                    aria-label="Previous"
                                    onClick={() =>
                                      handlePageChange(currentPage - 1)
                                    }
                                  >
                                    {'<<'}
                                  </PaginationLink>
                                </PaginationItem>
                                {renderPaginationItems()}
                                <PaginationItem
                                  disabled={currentPage === totalPages}
                                >
                                  <PaginationLink
                                    to="#"
                                    aria-label="Next"
                                    onClick={() =>
                                      handlePageChange(currentPage + 1)
                                    }
                                  >
                                    {'>>'}
                                  </PaginationLink>
                                </PaginationItem>
                              </ul>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>{' '}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
        {/*sliderLink == 3 && <Subscription />*/}
        {sliderLink == 2 && <MentorsManagement />}
      </Container>
      <Modal isOpen={confirmmodalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          Confirmation
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className="mb-0">Are You Sure to {status} ?</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button color="secondary" onClick={closeModal} type="button">
            Close
          </Button>
          <Button color="danger" type="button" onClick={changeActivationStatus}>
            {status}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={memberModal} size="xl">
        <ModalHeader toggle={openProfileModal}>
          Profile Details
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <button
            type="button"
            className="btn shareBtn"
            onClick={() => openCopyModal(memberFullData)}
          >
            <span aria-hidden="true" className="d-flex">
              {' '}
              <span className="d-none d-md-block">Share This Profile </span>
              <i>
                <FeatherIcon
                  icon="share-2"
                  className="fea icon-sm icons px-1"
                  style={{ width: `30px` }}
                />
              </i>
            </span>
          </button>
        </ModalHeader>
        {isModalLoading ? (
          <ModalBody>
            <ModalBodySkeleton />
          </ModalBody>
        ) : (
          <>
            {memberFullData && Object.keys(memberFullData).length > 0 && (
              <ProfileModal
                memberData={memberFullData}
                location={[nativeLocation, currentLocation, companyLocation]}
                dob={dob}
              />
            )}
          </>
        )}
      </Modal>

      <Modal isOpen={copyModalOpen} toggle={toggleCopyModal}>
        <ModalHeader toggle={toggleCopyModal}>
          Share Url
          <button
            type="button"
            className="btn shareBtn shareUrlbtn btn-dark text-white btn-outline-secondary"
            onClick={handleCopyText}
          >
            <span aria-hidden="true">
              {copyButton == 'Copy Url' ? (
                <i>
                  <FeatherIcon icon="copy" className="fea icon-sm icons" />
                </i>
              ) : (
                <i>
                  <FeatherIcon icon="check" className="fea icon-sm icons" />
                </i>
              )}{' '}
              {copyButton}
            </span>
          </button>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggleCopyModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody className="copy_Modal_body">
          <Row>
            <Col lg="12">
              <p className="mb-0">
                {pageUrl}
                {'index/'}
                {sharedMemberUuid}
              </p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};
export default AdminPortalFunction;
