import * as Common from './CommonHeader';
const React = Common.React;
const FontAwesomeIcon = Common.FontAwesomeIcon;
const {
  faSun,
  faScissors,
  faEye,
  faWeight,
  faTint,
  faGlobe,
  faMoneyBillWave,
  faBuilding,
  faMapMarkerAlt,
  faGraduationCap,
  faUniversity,
  faPrayingHands,
  faUsers,
  faPeace,
  faHome,
  faMale,
  faFemale,
  faAward,
  faDna,
  faHeart,
  faSmokingBan,
  faWineGlass,
  faDumbbell,
  faGamepad,
  faPaw,
} = Common;

const PartnerDetailsSection = ({ member, styles, iconClass }) => {
  return (
    <>
      {/* Third Row */}
      <div style={styles.headingTab}>
        <p style={styles.headingText} className="fs-5">
          Expected Partner Details
        </p>
      </div>
      <Common.Row>
        <Common.Col md="4">
          <Common.Card className="border-1 shadow-sm mb-4 card-bordered">
            <Common.CardBody className="left-align">
              <div style={styles.sectionHeading}>
                <h5>Partner's Expected Attributes</h5>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faSun}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Complexion:{' '}
                  <span style={styles.boldText}>
                    {member.complexions || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faWeight}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Max Height:{' '}
                  <span style={styles.boldText}>{member.maxheight} kg</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faWeight}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Max Weight:{' '}
                  <span style={styles.boldText}>{member.endweight} kg</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faTint}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Blood Groups:{' '}
                  <span style={styles.boldText}>{member.bloodgroups}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faScissors}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Hair Color:{' '}
                  <span style={styles.boldText}>{member.haircolors}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faEye}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Eye Color:{' '}
                  <span style={styles.boldText}>{member.eyecolors}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faGlobe}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Languages Known:{' '}
                  <span style={styles.boldText}>
                    {member.languages || 'English'}
                  </span>
                </p>
              </div>
            </Common.CardBody>
          </Common.Card>
        </Common.Col>
        <Common.Col md="4">
          <Common.Card className="border-1 shadow-sm mb-4 card-bordered">
            <Common.CardBody className="left-align">
              <div style={styles.sectionHeading}>
                <h5>Professional | Educational Details</h5>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faMoneyBillWave}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Start Salary:{' '}
                  <span style={styles.boldText}>
                    {member.startsalary || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Company Name:{' '}
                  <span style={styles.boldText}>{member.companyname}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Company Location:{' '}
                  <span style={styles.boldText}>
                    {member.companylocationname || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Degrees:{' '}
                  <span style={styles.boldText}>
                    {member.degreenames ||
                      member.degreelevels ||
                      'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faUniversity}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Degree Colleges:{' '}
                  <span style={styles.boldText}>
                    {member.degreecolleges || 'Not Specified'}
                  </span>
                </p>
              </div>
            </Common.CardBody>
          </Common.Card>
        </Common.Col>
        <Common.Col md="4">
          <Common.Card className="border-1 shadow-sm mb-4 card-bordered">
            <Common.CardBody className="left-align ">
              <div style={styles.sectionHeading}>
                <h5>Religious | Spiritual Details</h5>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faPrayingHands}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Current Religions:{' '}
                  <span style={styles.boldText}>
                    {member.currentreligions ||
                      member.previousreligions ||
                      'No Religion'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faUsers}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Castes: <span style={styles.boldText}>{member.castes}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faPeace}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Number of Vipassanas:{' '}
                  <span style={styles.boldText}>
                    {member.noofvipassana || 0}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faPeace}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Other Meditation:{' '}
                  <span style={styles.boldText}>
                    {member.othermeditation || 'Not Specified'}
                  </span>
                </p>
              </div>
            </Common.CardBody>
          </Common.Card>
        </Common.Col>
        <Common.Col md="4">
          <Common.Card className="border-1 shadow-sm mb-4 card-bordered">
            <Common.CardBody className="left-align ">
              <div style={styles.sectionHeading}>
                <h5>Family Details</h5>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faHome}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Family types:{' '}
                  <span style={styles.boldText}>
                    {member.familytypes || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faMale}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Father :<span style={styles.boldText}>{member.fathers}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faFemale}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Mother :<span style={styles.boldText}>{member.mothers}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faAward}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Number of Siblings:{' '}
                  <span style={styles.boldText}>{member.noofsibling || 0}</span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faAward}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Your position in Siblings:{' '}
                  <span style={styles.boldText}>
                    {member.yourposition || 0}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faDna}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Genetic Disease:{' '}
                  <span style={styles.boldText}>
                    {member.geneticdiseases || 'Not Specified'}
                  </span>
                </p>
              </div>
            </Common.CardBody>
          </Common.Card>
        </Common.Col>
        <Common.Col md="4">
          <Common.Card className="border-1 shadow-sm mb-4 card-bordered">
            <Common.CardBody className="left-align ">
              <div style={styles.sectionHeading}>
                <h5>LifeStyle Details</h5>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faHeart}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Diet Preferences:{' '}
                  <span style={styles.boldText}>
                    {member.diettypes || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faSmokingBan}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Smoking:{' '}
                  <span style={styles.boldText}>
                    {member.smokinghabit || 'NA'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faWineGlass}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Drinking:{' '}
                  <span style={styles.boldText}>
                    {member.drinkinghabit || 'NA'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faDumbbell}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Exercise Routines:{' '}
                  <span style={styles.boldText}>
                    {member.exerciseroutine || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faGamepad}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Games:{' '}
                  <span style={styles.boldText}>
                    {member.gameList || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faHeart}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Hobbies & Interests:{' '}
                  <span style={styles.boldText}>
                    {member.hobbies || 'Not Specified'}
                  </span>
                </p>
              </div>
              <div style={styles.iconTextContainer} className="mb-2">
                <div className="icon-box-square">
                  <FontAwesomeIcon
                    icon={faPaw}
                    size="lg"
                    className={iconClass}
                  />
                </div>
                <p className="mb-0">
                  Pet Preferences:{' '}
                  <span style={styles.boldText}>
                    {member.petpreference || 'Not Specified'}
                  </span>
                </p>
              </div>
            </Common.CardBody>
          </Common.Card>
        </Common.Col>
      </Common.Row>
    </>
  );
};

export default PartnerDetailsSection;
