import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faArrowUp,
  faBriefcase,
  faMapPin,
  faBook,
  faMailBulk,
  faPhone,
  faSun,
  faScissors,
  faEye,
  faGlobe,
  faHome,
  faHeart,
  faUsers,
  faAward,
  faRunning,
  faWeight,
  faTint,
  faMoneyBillWave,
  faBuilding,
  faMapMarkerAlt,
  faGraduationCap,
  faUniversity,
  faPrayingHands,
  faMale,
  faFemale,
  faPeace,
  faOm,
  faWineGlass,
  faSmokingBan,
  faGamepad, // For Games
  faDumbbell, // For Exercise
  faChess, // Alternative for Games
  faPalette, // Alternative for Hobbies
  faDna, // For Genetic Disease
  faPaw, // For Pet Preferences
  faCat, // Alternative for Pets
  faDog, // Alternative for Pets
  faStethoscope, // Alternative for Medical Conditions
  faShareAlt, // For Share Icon
  faArrowLeft,  // Add this import
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PersonalInformationSection from './components/PersonalInformationSection';
import PartnerDetailsSection from './components/PartnerDetailsSection';
import { AlignEndHorizontal } from 'lucide-react';
import { memberService, connectionService } from '../../../services/api';
import { useApi } from '../../../hooks/useApi';

const styles = {
  boldText: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: '100%',
    display: 'inline-block',
  },
  iconTextContainer: {
    display: 'flex',
    alignItems: 'flex-start', // Changed from 'center' to better handle wrapped text
    gap: '0.5rem',
    width: '100%', // Ensure container takes full width
  },
  sectionHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.1rem',
    borderBottom: '1px solid #E2E8F0',
    paddingBottom: '0.1rem',
    marginBottom: '0.6rem',
  },
  headingTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 0.5rem',
    marginBottom: '1rem',
    backgroundColor: 'rgb(240, 136, 31)',
    borderLeft: '4px solid #007bff',
    boxShadow: '0 2px 4px rgba(244, 232, 10, 0.05)',
    borderRadius: '0 8px 8px 0',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-2px',
      left: 0,
      right: 0,
      height: '1px',
      background:
        'linear-gradient(90deg,rgb(222, 243, 115) 0%, rgba(230, 187, 18, 0.2) 100%)',
    },
  },
  headingText: {
    margin: 0,
    letterSpacing: '0.1px',
  },
};

const AsMemberDetailsPage = ({
  member,
  locationUrl,
  returnPage,
  searchCriteria,
  memberConnectionStatus,
}) => {
  const navigate = useNavigate();
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('');
  const [confirmConnectionModalOpen, setConfirmConnectionModalOpen] =
    useState(false);
  const [modalTitle, setModalTitle] = useState('Confirmation');
  const [modalDescription, setModalDescription] = useState(
    'Are You Sure to Connect?',
  );
  const [modalConnectButton, setModalConnectButton] = useState(true);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const { callApi } = useApi();

  const partnerExpectationsDetails = async (memberUUID) => {
    try {
      const response = await callApi(() => 
        memberService.getPartnerPreferences(memberUUID)
      );
      
      if (response?.length > 0) {
        setPartnerDetails(response[0]);
      }
    } catch (error) {
      console.error('Error fetching partner preferences:', error);
    }
  };

  const openConnectionModal = async (connStatus) => {
    setConnectionStatus('Connecting !');
    setConfirmConnectionModalOpen(true);
  };

  const requestForConnection = async () => {
    try {
      const request = {
        memberuuid: localStorage.getItem('memberID'),
        connectedmemberuuid: member.memberuuid,
      };

      await callApi(() => 
        connectionService.sendConnectionRequest(request)
      );

      setSuccess('Connection request sent successfully');
      setConfirmConnectionModalOpen(false);
      setConnectionStatus('Connection Sent');
    } catch (error) {
      if (error.response?.data?.code === 'TOKEN_UPGRADE_NEEDED') {
        setError('Your session has expired. Please login again.');
      } else {
        setError('Failed to send connection request');
      }
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    partnerExpectationsDetails(member.memberuuid);
    setLoading(false);
  }, [member]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleErrorModal = () => setIsErrorModalOpen(!isErrorModalOpen);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    toggleModal();
  };

  const getImage = (imagepath, gender) => {
    if (imagepath) {
      return imagepath;
    } else {
      if (gender === 'Male') {
        return manavatar;
      } else if (gender === 'Female') {
        return femaleavatar;
      } else {
        return transgenderavatar;
      }
    }
  };

  const handleShareURL = () => {
    const urlToCopy = `${window.location.origin}/member/${member.memberuuid}`;
    const textArea = document.createElement('textarea');
    textArea.value = urlToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert('URL copied to clipboard');
  };

  const handleMessageClick = async () => {
    const configData = localStorage.getItem('configurationData');
    if (!configData) {
      navigate('/subscriptionplan');
      return;
    }

    const parsedConfigData = JSON.parse(configData);
    if (parsedConfigData.subscription === ' Basic' ||
        parsedConfigData.subscription === 'Basic') {
      navigate('/subscriptionplan');
      return;
    }

    try {
      if (memberConnectionStatus === 'Connected' && 
          member.emailid !== 'Information Restricted' &&
          member.phonenumber !== 'Information Restricted') {
        window.open(`https://wa.me/${member.phonecountrycode}${member.phonenumber}`, '_blank');
      } else {
        setModalTitle('Messaging Restricted');
        setModalDescription('Messaging is restricted as the user does not want to allow anyone to see their contact details.');
        setModalConnectButton(false);
        setConfirmConnectionModalOpen(true);
      }
    } catch (error) {
      console.error('Error handling message click:', error);
      setError('Unable to process message request');
    }
  };

  const handleErrorModalClose = () => {
    toggleErrorModal();
    navigate(locationUrl || '/');
  };

  const handleBackToSearch = () => {
    if (locationUrl) {
      navigate(locationUrl, { state: { returnPage, searchCriteria } });
    } else {
      navigate('/');
    }
  };

  const formattedHeight = (height) => {
    let calculatedHeight =
      typeof height === 'number' && height > 0
        ? Number(height).toFixed(1)
        : Number(height).toFixed(1);
    return calculatedHeight;
  };

  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .trim();
  };

  const renderConnectionButton = (status) => {
    if (status === 'Connected') {
      return (
        <Button color="primary" className="ms-2" onClick={handleMessageClick}>
          Send Message
        </Button>
      );
    } else if (status === 'Connecting') {
      return (
        <Button color="secondary" className="ms-2" disabled>
          Pending Connection
        </Button>
      );
    } else {
      return (
        <Button
          color="primary"
          className="ms-2"
          onClick={() => openConnectionModal()}
        >
          Initiate Connection
        </Button>
      );
    }
  };

  const renderCriticalData = (maindata, supplimentdata) => {
    if (maindata === 'Subscription Required') {
      return (
        <Link className="btn-link ms-3 subscribe_btn" to={'/subscriptionplan'}>
          {maindata}
        </Link>
      );
    } else if (maindata === '') {
      return <span className="">Empty Data</span>;
    } else if (maindata === 'Information Restricted') {
      return <span className="">{maindata}</span>;
    } else if (maindata === 'Connection Required') {
      return <span className="">{maindata}</span>;
    } else {
      return (
          supplimentdata ? '+('+supplimentdata +')-'+maindata : maindata
      );
    }
  };

  const rednerDoBData = (dob) => {
    if (dob && 
      dob != 'Information Restricted' && 
      dob != 'Connection Required' && 
      dob != 'Subscription Required') { 
      try {
        const dt = new Date(dob);
        const month = dt.toLocaleString('default', { month: 'long' });
        const day = dt.getDate();
        const year = dt.getFullYear();

        return day + ' ' + month + ' ' + year;
      } catch (error) {
        return 'Information Restricted';
      }
    } else {
      // alert("*maindata*"+'Secured_Info'+"*actualdata*"+dob+"*end");
      return renderCriticalData(dob);
    }
  };

  if (loading) {
    return (
      <Container className="text-center py-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </Container>
    );
  }

  if (!member) {
    return (
      <Container className="text-center py-5">
        <p>Member not found</p>
      </Container>
    );
  }

  const age = member.currentage;

  const defaultImage = member.gender === 'Male' ? manavatar : femaleavatar;
  const photos = [
    member.photothumb1 || defaultImage,
    member.photothumb2 || defaultImage,
    member.photothumb3 || defaultImage,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ul style={{ margin: 0, padding: 0 }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: '#fff',
          opacity: 0.75,
        }}
      />
    ),
  };

  const iconClass =
    member?.gender?.toLowerCase() === 'female' ? 'icon-color' : 'text-primary';
  const personalIconClass = iconClass;

  const partnerIconClass =
    member?.gender?.toLowerCase() === 'female' ? 'text-primary' : 'icon-color';

  return (
    <React.Fragment>
      <section className="section">
        <Container className="mt-15">
          <Row className="mb-3">
            <Col className="text-start">
              <Button color="primary" onClick={handleShareURL}>
                <FontAwesomeIcon icon={faShareAlt} />
              </Button>
              {renderConnectionButton(memberConnectionStatus)}
            </Col>
            <Col className="text-end">
              <Button color="secondary" onClick={handleBackToSearch} className="back-button">
                <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
                <span className="back-text">Back to Search</span>
              </Button>
            </Col>
          </Row>
          <Row>
            {/* First Row */}
            <Col md="4">
              <Card className="border-1 shadow-sm mb-4 card-bordered">
                <CardBody className="left-align">
                  <Slider {...settings}>
                    {photos.map((photo, index) => (
                      <div key={index}>
                        <CardImg
                          top
                          src={photo}
                          alt={member.firstname}
                          className="member-photo"
                          style={{
                            objectFit: 'cover',
                            cursor: 'pointer',
                            width: '100%',
                            height: 'auto', // Adjust the height to auto
                          }}
                          onClick={() => handleImageClick(photo)}
                        />
                      </div>
                    ))}
                  </Slider>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="border-1 shadow-sm mb-4 card-bordered">
                <CardBody className="left-align">
                  <h4>
                    {capitalizeFirstLetter(
                      member.firstname + ' ' + member.lastname,
                    )}
                  </h4>
                  <p className="fs-4">{member.jobtitle}</p>
                  <p className="text-dark">{age} years old</p>

                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faCalendar}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Date of Birth:{' '}
                      <span style={styles.boldText}>
                        {rednerDoBData(member.dob)}
                      </span>
                    </p>
                  </div>
                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Height:{' '}
                      <span style={styles.boldText}>
                        {formattedHeight(member.height)} ft
                      </span>
                    </p>
                  </div>
                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faBriefcase}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Job Profession:{' '}
                      <span style={styles.boldText}>
                        {member.jobprofession}
                      </span>
                    </p>
                  </div>
                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faMapPin}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Current Location:{' '}
                      <span style={styles.boldText}>
                        {member.currentlocationname ||
                          member.nativelocationname}
                      </span>
                    </p>
                  </div>
                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faHome}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Native Location:{' '}
                      <span style={styles.boldText}>
                        {member.nativelocationname ||
                          member.currentlocationname}
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="border-1 shadow-sm mb-4 card-bordered">
                <CardBody className="left-align">
                  <div style={styles.sectionHeading}>
                    <h5>Contact Information (AS)</h5>
                  </div>
                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faMailBulk}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Email:{' '}
                      <span style={styles.boldText}>
                        {renderCriticalData(member.emailid)}
                      </span>
                    </p>
                  </div>
                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faPhone}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Phone:{' '}
                      <span style={styles.boldText}>
                        {renderCriticalData(
                          member.phonenumber,
                          member.phonecountrycode,
                        )}
                      </span>
                    </p>
                  </div>
                  <div style={styles.sectionHeading} className="pt-3">
                    <h5>Social Links</h5>
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                    {member.facebook && (
                      <Button color="primary" outline className="social-btn">
                        <div className="icon-box-square">
                          <FontAwesomeIcon
                            icon={faFacebook}
                            size="lg"
                            className={iconClass}
                          />
                        </div>
                      </Button>
                    )}
                    {member.instagram && (
                      <Button color="primary" outline className="social-btn">
                        <div className="icon-box-square">
                          <FontAwesomeIcon
                            icon={faInstagram}
                            size="lg"
                            className={iconClass}
                          />
                        </div>
                      </Button>
                    )}
                    {member.twitter && (
                      <Button color="primary" outline className="social-btn">
                        <div className="icon-box-square">
                          <FontAwesomeIcon
                            icon={faTwitter}
                            size="lg"
                            className={iconClass}
                          />
                        </div>
                      </Button>
                    )}
                    {member.linkedin && (
                      <Button color="primary" outline className="social-btn">
                        <div className="icon-box-square">
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            size="lg"
                            className={iconClass}
                          />
                        </div>
                      </Button>
                    )}
                    {member.youtube && (
                      <Button color="primary" outline className="social-btn">
                        <div className="icon-box-square">
                          <FontAwesomeIcon
                            icon={faYoutube}
                            size="lg"
                            className={iconClass}
                          />
                        </div>
                      </Button>
                    )}
                  </div>
                  <div style={styles.sectionHeading} className="pt-4">
                    <h5>Social Organization</h5>
                  </div>
                  <div style={styles.iconTextContainer} className="mb-2">
                    <div className="icon-box-square">
                      <FontAwesomeIcon
                        icon={faUniversity}
                        size="lg"
                        className={iconClass}
                      />
                    </div>
                    <p className="mb-0">
                      Part of NGO:{' '}
                      <span style={styles.boldText}>
                        {member.partofngo || 'Not Associated Yet'}
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <PersonalInformationSection
            member={member}
            styles={styles}
            iconClass={personalIconClass}
          />

          {member.membertypeid === 1 && partnerDetails && (
            <PartnerDetailsSection
              member={partnerDetails}
              styles={styles}
              iconClass={partnerIconClass}
            />
          )}

          <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>
              <button
                type="button"
                aria-label="Close"
                className="close-button"
                onClick={toggleModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                  zIndex: '1000',
                }}
              >
                &times;
              </button>
            </ModalHeader>
            <ModalBody>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Modal"
                  className="img-fluid"
                  style={{ width: '100%' }}
                />
              )}
            </ModalBody>
          </Modal>

          <Modal isOpen={isErrorModalOpen} toggle={toggleErrorModal}>
            <ModalHeader toggle={toggleErrorModal}>
              User Details Not Available
            </ModalHeader>
            <ModalBody>
              <p>
                User details are not available. Please browse other user
                details. Thanks!
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleErrorModalClose}>
                OK
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={confirmConnectionModalOpen}
            toggle={() =>
              setConfirmConnectionModalOpen(!confirmConnectionModalOpen)
            }
          >
            <ModalHeader
              toggle={() =>
                setConfirmConnectionModalOpen(!confirmConnectionModalOpen)
              }
            >
              {modalTitle}
            </ModalHeader>
            <ModalBody>
              <p>{modalDescription}</p>
              {error && <p className="text-danger">{error}</p>}
              {success && <p className="text-success">{success}</p>}
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={requestForConnection}
                disabled={!modalConnectButton}
              >
                Connect
              </Button>
              <Button
                color="secondary"
                onClick={() => setConfirmConnectionModalOpen(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <style>
            {`
          .social-btn {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .member-photo {
            width: 100%;
            height: auto; /* Adjust the height to auto */
            object-fit: cover;
          }

          .card-body {
            font-size: 1.1rem; /* Increase the font size */
          }

          .card-bordered {
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            padding: '1.5rem', // Reduced padding
            marginBottom: '2rem',
            boxShadow:
            '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            border: '1px solid #E2E8F0',
          }

          .left-align {
            text-align: left !important; /* Ensure it overrides other styles */
          }

          .icon-box-square {
            width: 32px;
            height: 32px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #007bff; /* Blue border */
          }

          .icon-color {
            color:rgb(240, 47, 163);
          }

          .back-button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            font-size: 1rem;
            transition: all 0.3s ease;
          }

          .back-icon {
            font-size: 1.2rem;
          }

          @media (max-width: 576px) {
            .back-text {
              display: none;
            }
            
            .back-button {
              padding: 8px;
              aspect-ratio: 1;
              border-radius: 50%;
            }
            
            .back-icon {
              margin: 0;
            }
          }
        `}
          </style>
        </Container>
        <Container className="text-end mt-4">
          <Button color="secondary" onClick={handleBackToSearch} className="back-button">
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
            <span className="back-text">Back to Search</span>
          </Button>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AsMemberDetailsPage;
