import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import MMlogo from '../../assets/images/mettamate/logo.svg';
import mentorIcon from '../../assets/images/mentorship/mentor-icon-1.png';
import menteeIcon from '../../assets/images/mentorship/mentee-icon.png';
import serviceProviderIcon from '../../assets/images/mentorship/service-provider-icon.png';
import jobIcon from '../../assets/images/job/job-icon.png';
import maleAvatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleAvatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import networknavicon from '../../assets/images/event/network_icon.png';
import homeIcon from '../../assets/images/Newemoji/homeblue.png';
import communityIcon from '../../assets/images/Newemoji/Community.png';
import messageIcon from '../../assets/images/Newemoji/messageblue.png';
import worldIcon from '../../assets/images/Newemoji/worldblue.png';
import mentorshipIcon from '../../assets/images/Newemoji/mentorshipIcon1.png';
import serviceIcon from '../../assets/images/Newemoji/service1.png';
import blogIcon from '../../assets/images/Newemoji/Blogsblue.png';
import notificationIcon from '../../assets/images/Newemoji/notification (2).png';
import partnerIcon from '../../assets/images/Newemoji/lifepartnerblue.png';
import eventIcon from '../../assets/images/event/eventIcon.png';

const MobileHeader = ({
  getTargetURL,
  getKeyMenus,
  getMenuItems,
  logout,
  isLoggedIn,
}) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define dropdown styles
  const getDropdownStyles = () => ({
    position: 'absolute',
    top: '45px',
    left: '10px',
    transform: 'none',
    backgroundColor: '#fff',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    padding: '16px',
    borderRadius: '12px',
    zIndex: 1000,
    width: isMobile ? '94vw' : '300px',
    left: isMobile ? '1vw' : '10px',
    border: '1px solid rgba(0,0,0,0.08)',
    opacity: 1,
    transition: 'all 0.3s ease',
    animation: 'dropdownFade 0.3s ease',
    maxHeight: '85vh',
    overflowY: 'auto',
  });

  // Enhanced toggle function with button handling
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsOpen(!isOpen);
  };

  // Enhanced click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOnClick = () => {
    setIsHighlighted(!isHighlighted); // Toggle highlight state
  };

  // Add handler for menu item clicks
  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  const showLoginIcon = ['/index', '/user-registration-page'].includes(
    location.pathname,
  );

  const showRegistrationIcon = location.pathname === '/user-login-page';

  // Add this helper function
  const getProfileImage = (profilePhoto, gender) => {
    const defaultImage = gender === 'Female' ? femaleAvatar : maleAvatar;

    return profilePhoto || defaultImage;
  };

  return (
    <div>
      {/* Header Section */}
      <header
        id="topnav"
        className="defaultscroll sticky"
        style={{
          flexShrink: 0,
          position: 'relative',
          zIndex: '100',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          {isLoggedIn && (
            // Remove the Link wrapper and just keep the ul
            <ul
              className={`buy-button list-inline mb-0 ${isHighlighted ? 'highlighted' : ''}`}
              style={{
                position: 'absolute',
                right: '10px',
                bottom: '5px',
                margin: '10px',
              }}
              onClick={handleOnClick}
            >
              {getKeyMenus()}
            </ul>
          )}
          {/* Logo */}
          <div
            style={{
              margin: '0 auto',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Link className="logo" to={getTargetURL()}>
              <img
                src={MMlogo}
                height="42"
                className="logo-light-mode"
                alt="Logo"
              />
              <img
                src={MMlogo}
                height="42"
                className="logo-dark-mode"
                alt="Logo"
              />
            </Link>
          </div>

          {/* Menu List Icon (replacing Settings Icon) */}
          {isLoggedIn && (
            <div style={{ position: 'absolute', left: 5 }}>
              <div>
                <button
                  ref={buttonRef}
                  className={`menu-button ${isOpen ? 'active' : ''}`}
                  onClick={toggleDropdown}
                  style={{
                    border: 'none',
                    background: 'linear-gradient(145deg, #2062C4, #3B82F6)',
                    padding: '12px 16px', // Increased padding
                    borderRadius: '10px', // Slightly larger radius
                    boxShadow: '0 4px 15px rgba(59, 130, 246, 0.3)',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'all 0.3s ease',
                    minWidth: '48px', // Increased width
                    position: 'relative',
                    zIndex: 1001, // Ensure button stays above dropdown
                  }}
                >
                  <i
                    className="fas fa-bars" // Changed from fa-th to fa-grip-lines
                    style={{
                      fontSize: '24px', // Increased font size
                      color: '#ffffff',
                      filter: 'drop-shadow(0 2px 3px rgba(0,0,0,0.2))',
                      transition: 'transform 0.3s ease',
                      transform: isOpen ? 'rotate(90deg)' : 'none',
                    }}
                    title="Menu"
                  ></i>
                </button>
                {isOpen && (
                  <div ref={dropdownRef} style={getDropdownStyles()}>
                    <div className="role-menu">
                      <div className="role-header">
                        <span className="menu-section-title">
                          Play A Role @ MettaMate
                        </span>
                      </div>
                      <hr className="my-2" />
                      <div className="role-options">
                        <Link
                          to="/self-mentor-page"
                          className="role-option"
                          onClick={handleMenuItemClick}
                        >
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={mentorIcon}
                              alt="Mentor"
                              style={{
                                width: '28px', // Increased from 24px
                                height: '28px', // Increased from 24px
                                marginRight: '12px', // Increased margin
                              }}
                            />
                            <span>Mentor</span>
                          </div>
                        </Link>
                        <Link
                          to="/self-mentee-page"
                          className="role-option"
                          onClick={handleMenuItemClick}
                        >
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={menteeIcon}
                              alt="Mentee"
                              style={{
                                width: '28px', // Increased from 24px
                                height: '28px', // Increased from 24px
                                marginRight: '12px', // Increased margin
                              }}
                            />
                            <span>Mentee</span>
                          </div>
                        </Link>
                        <Link
                          to="#"
                          className="role-option"
                          onClick={handleMenuItemClick}
                        >
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={serviceProviderIcon}
                              alt="Service Provider"
                              style={{
                                width: '28px', // Increased from 24px
                                height: '28px', // Increased from 24px
                                marginRight: '12px', // Increased margin
                              }}
                            />
                            <span>Service Provider</span>
                          </div>
                        </Link>
                      </div>
                      <hr className="my-2" />
                    </div>

                    {/* Account Settings and Logout options */}
                    <div className="role-header py-2 px-1">
                      <span className="fw-medium text-dark">Menu</span>
                    </div>
                    <hr className="my-2" />
                    <Link
                      to="/connections"
                      className="role-option"
                      onClick={handleMenuItemClick}
                    >
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={networknavicon}
                          alt="My Network"
                          style={{
                            width: '28px', // Increased from 24px
                            height: '28px', // Increased from 24px
                            marginRight: '12px', // Increased margin
                          }}
                        />
                        <span>My Network</span>
                      </div>
                    </Link>
                    <Link
                      to="/job-listing"
                      className="role-option"
                      onClick={handleMenuItemClick}
                    >
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={jobIcon}
                          alt="Jobs"
                          style={{
                            width: '28px', // Increased from 24px
                            height: '28px', // Increased from 24px
                            marginRight: '12px', // Increased margin
                          }}
                        />
                        <span>Jobs</span>
                      </div>
                    </Link>
                    <Link
                      to="/event-listing-page"
                      className="role-option"
                      onClick={handleMenuItemClick}
                    >
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={eventIcon}
                          alt="Events"
                          style={{
                            width: '28px', // Increased from 24px
                            height: '28px', // Increased from 24px
                            marginRight: '12px', // Increased margin
                          }}
                        />
                        <span>Events</span>
                      </div>
                    </Link>
                    <Link
                      to="/blogs"
                      className="role-option"
                      onClick={handleMenuItemClick}
                    >
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={blogIcon}
                          alt="blogs"
                          style={{
                            width: '28px', // Increased from 24px
                            height: '28px', // Increased from 24px
                            marginRight: '12px', // Increased margin
                          }}
                        />
                        <span>Blogs</span>
                      </div>
                    </Link>
                    <Link
                      to="/mentorship-page"
                      className="role-option"
                      onClick={handleMenuItemClick}
                    >
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={mentorIcon}
                          alt="Explore Mentors"
                          style={{
                            width: '28px', // Increased from 24px
                            height: '28px', // Increased from 24px
                            marginRight: '12px', // Increased margin
                          }}
                        />
                        <span>Explore Mentors</span>
                      </div>
                    </Link>
                    <Link
                      to="/mentees-page"
                      className="role-option"
                      onClick={handleMenuItemClick}
                    >
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={menteeIcon}
                          alt="Explore Mentees"
                          style={{
                            width: '28px', // Increased from 24px
                            height: '28px', // Increased from 24px
                            marginRight: '12px', // Increased margin
                          }}
                        />
                        <span>Explore Mentees</span>
                      </div>
                    </Link>
                    <hr className="my-2" />
                    <div className="role-header py-2 px-1">
                      <span className="fw-medium text-dark">Setting</span>
                    </div>
                    <hr className="my-2" />
                    <Link
                      to="/settings"
                      className="settings-option"
                      onClick={handleMenuItemClick}
                    >
                      <div className="d-flex align-items-center p-2">
                        <i className="fas fa-cog me-2"></i>
                        <span>Account Settings</span>
                      </div>
                    </Link>
                    <Link
                      to="/user-login-page"
                      onClick={(e) => {
                        handleMenuItemClick();
                        logout(e);
                      }}
                      className="logout-option"
                    >
                      <div className="d-flex align-items-center p-2 text-danger">
                        <i className="fas fa-sign-out-alt me-2"></i>
                        <span>Logout</span>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Login Icon (Shown only on /index route) */}
          {showLoginIcon && (
            <div style={{ position: 'absolute', right: '10px' }}>
              <Link to="/user-login-page">
                <i
                  className="fas fa-user"
                  style={{
                    fontSize: '28px',
                    color: '#FF7F00',
                    cursor: 'pointer',
                  }}
                  title="Login"
                ></i>
              </Link>
            </div>
          )}

          {/* Login Icon (Shown only on /index route) */}
          {showRegistrationIcon && (
            <div style={{ position: 'absolute', right: '10px' }}>
              <Link to="/user-registration-page">
                <i
                  className="fas fa-pencil-alt p-1"
                  style={{
                    fontSize: '28px',
                    color: '#2062C4',
                    cursor: 'pointer',
                    border: '1px solid #2062C4',
                  }}
                  title="Login"
                ></i>
              </Link>
            </div>
          )}
        </Container>
      </header>

      {/* Fixed Navigation Menu at the Bottom */}
      <div
        id="navigation"
        style={{
          position: 'fixed',
          top: '90%',
          bottom: '0',
          left: '0',
          width: '100%',
          backgroundColor: '#fff',
          zIndex: '100',
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ul
          className="navigation-menu nav-dark"
          id="top-menu"
          style={{
            display: 'flex',
            padding: '0',
            margin: '0',
            gap: '5px',
            zIndex: '9999',
            top: '20px',
          }}
        >
          {getMenuItems()}
        </ul>
      </div>
    </div>
  );
};

// Update the styles
const styles = `
.role-option, .settings-option, .logout-option {
  text-decoration: none;
  color: #333;
  display: block;
  transition: all 0.2s ease;
  padding: 12px 16px;
  border-radius: 8px;
  margin: 4px 0;
  font-weight: 500;
}

.role-option:hover, .settings-option:hover {
  background-color: #f0f7ff;
  transform: translateX(4px);
  text-decoration: none;
}

.role-option:active, .settings-option:active {
  background-color: #e5f0ff;
  transform: translateX(2px);
}

.logout-option:hover {
  background-color: #fee2e2;
  transform: translateX(4px);
  text-decoration: none;
}

.role-header {
  padding: 8px 4px;
}

.menu-section-title {
  color: #2062C4;
  font-weight: 600;
  font-size: 1.1rem;
  display: block;
  margin-bottom: 8px;
}

hr {
  border-color: rgba(0,0,0,0.08);
  margin: 12px 0;
}

.menu-button {
  transition: all 0.3s ease !important;
}

.menu-button:hover {
  background: linear-gradient(145deg, #3B82F6, #2062C4) !important;
  transform: translateY(1px);
  box-shadow: 0 4px 15px rgba(59, 130, 246, 0.4);
}

.menu-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 8px rgba(59, 130, 246, 0.4);
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu-button.active {
  background: linear-gradient(145deg, #3B82F6, #2062C4) !important;
  box-shadow: 0 2px 8px rgba(59, 130, 246, 0.5) inset;
}

/* Custom scrollbar for the dropdown */
.role-menu::-webkit-scrollbar {
  width: 6px;
}

.role-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.role-menu::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

.role-menu::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .role-option, .settings-option, .logout-option {
    padding: 14px 16px;
  }
  
  .menu-section-title {
    font-size: 1rem;
  }
  
  hr {
    margin: 10px 0;
  }
}
`;

export default MobileHeader;
