import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import {
    Container,
    Row,
    Col,
    Progress,
    Card,
    CardBody,
    Badge,
    Label,
    Input,
    FormFeedback,
    Button,
    Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal';
import apiUrl from '../../server';
import '../../Home/css/userHomePage.css';
import vegIcon from '../../../assets/images/MettaMateImages/vegetarian.png'
import habitIcon from '../../../assets/images/MettaMateImages/bad-habits.png';
import exerciseIcon from '../../../assets/images/MettaMateImages/running.png'
import gamesIcon from '../../../assets/images/MettaMateImages/basketball.png';
import { useApi } from '../../../hooks/useApi';
import { preferenceService, codesService } from '../../../services/api';



const LifestyleFunction = () => {
    const { callApi } = useApi();
    const [dietPrefList, setDietPrefList] = useState([]);
    const [smokingHabitList, setSmokingHabitList] = useState([]);
    const [drinkingHabitList, setDrinkingHabitList] = useState([]);
    const [exerciseHabitList, setExerciseHabitList] = useState([]);
    const [gamesList, setGamesList] = useState([]);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [lifestylePreferenceFormData, setLifestylePrefenerceFormData] = useState({
        memberuuid: localStorage.getItem("memberID"),
        diettypes: [],
        smoking: 0,
        drinking: 0,
        excercise: 0,
        playgames: [],
        hobbies: []
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [dietPrefRes, smokingRes, drinkingRes, exerciseRes, gamesRes] = await Promise.all([
                    callApi(() => codesService.getDietPreferences()),
                    callApi(() => codesService.getSmokingHabits()),
                    callApi(() => codesService.getDrinkingHabits()),
                    callApi(() => codesService.getExercise()),
                    callApi(() => codesService.getGames())
                ]);

                setDietPrefList(dietPrefRes.list);

                // Add "Don't Care" option
                const dontCareData = { label: "Dont Care", value: 100 };
                smokingRes.list.push(dontCareData);
                drinkingRes.list.push(dontCareData);
                exerciseRes.list.push(dontCareData);

                setSmokingHabitList(smokingRes.list);
                setDrinkingHabitList(drinkingRes.list);
                setExerciseHabitList(exerciseRes.list);
                setGamesList(gamesRes.list);

            } catch (error) {
                console.error('Error fetching form data:', error);
            }
        };
        fetchData();
        fetchLifeStylePrefData();
    }, []);

    const fetchLifeStylePrefData = async () => {
        try {
            const response = await callApi(() => 
                preferenceService.getLifestylePreference(localStorage.getItem("memberID"))
            );

            if (response.length > 0) {
                const lifestyleData = response[0];
                setLifestylePrefenerceFormData({
                    memberuuid: localStorage.getItem('memberID'),
                    diettypes: lifestyleData.diettypes || [],
                    smoking: lifestyleData.smoking || 0,
                    drinking: lifestyleData.drinking || 0,
                    excercise: lifestyleData.excercise || 0,
                    playgames: lifestyleData.playgames || [],
                    hobbies: lifestyleData.hobbies || []
                });
            }
        } catch (error) {
            console.error('Error fetching lifestyle preferences:', error);
        }
    };

    const handleDietPref = (checkedFlag, dietObject) => {
        let selectedDiet = lifestylePreferenceFormData.diettypes;
        if (checkedFlag) {
            if (!selectedDiet.includes(dietObject.value)) {
                selectedDiet.push(dietObject.value);
            }
        }
        if (!checkedFlag) {
            if (selectedDiet.includes(dietObject.value)) {
                selectedDiet.splice(selectedDiet.findIndex(x => dietObject.value === x), 1);
            }
        }
        setLifestylePrefenerceFormData({ ...lifestylePreferenceFormData, diettypes: selectedDiet });
    };


    const handlePlayGames = (checkedFlag, gamesObject) => {
        let selectedGames = lifestylePreferenceFormData.playgames;
        if (checkedFlag) {
            if (!selectedGames.includes(gamesObject.value)) {
                selectedGames.push(gamesObject.value);
            }
        }
        if (!checkedFlag) {
            if (selectedGames.includes(gamesObject.value)) {
                selectedGames.splice(selectedGames.findIndex(x => gamesObject.value === x), 1);
            }
        }
        setLifestylePrefenerceFormData({ ...lifestylePreferenceFormData, diettypes: selectedGames });
    };

    const handleSmoking = (smokingObject) => {
        setLifestylePrefenerceFormData({ ...lifestylePreferenceFormData, smoking: smokingObject.value })
    }
    const handleDrinking = (drinkingObject) => {
        setLifestylePrefenerceFormData({ ...lifestylePreferenceFormData, drinking: drinkingObject.value })
    }
    const handleExercise = (exerciseObject) => {
        setLifestylePrefenerceFormData({ ...lifestylePreferenceFormData, excercise: exerciseObject.value })
    }


    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const response = await callApi(() =>
                preferenceService.updateLifestylePreference(lifestylePreferenceFormData)
            );

            setSuccess({
                title: 'SUCCESS!',
                message: response.message
            });

            await fetchLifeStylePrefData();
        } catch (error) {
            setError({
                title: 'An Error Occurred!',
                message: error.response?.data?.message || 'Failed to update lifestyle preferences'
            });
        } finally {
            setIsLoading(false);
        }
    };



    const errorHandler = () => {
        setError(null);
    };
    const successHandler = () => {
        setSuccess(null);
    };
    const handleReset = () => {
    };

    const LoaderComponent = () => (
        <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        </div>
    );


    return (
        <React.Fragment>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            {
                isLoading ? <LoaderComponent /> :
                    (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                // validation.handleSubmit();
                                handleSubmit()
                            }}
                        >
                            <Row className="inner_row mt-1">
                                <h6 className="title  title_bar">
                                    Lifestyle Preference
                                </h6>

                                <Col md={12} xs={12}>
                                    <img src={vegIcon} className='legendIcon' style={{ width: `40px` }} />
                                    &nbsp; <span className="m-2 legendName" >Diet</span>
                                    <div className="features feature-primary p-1">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Diet Preference</Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {dietPrefList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox"
                                                                            id={response.label + "dietpref"}
                                                                            checked={lifestylePreferenceFormData.diettypes.includes(response.value)}
                                                                            onChange={event => handleDietPref(event.target.checked, response)}
                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "dietpref"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                                </Col>

                                <Col md={12} sm={12}>
                                    <img src={habitIcon} className='legendIcon' style={{ width: `40px` }} />
                                    &nbsp; <span className="m-2 legendName" >Habits</span>
                                    <div className=" features feature-primary p-1">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Smoking Habit</Label>
                                            <section className="radio-section">
                                                <div className="radio-list">
                                                    {(smokingHabitList || []).map((smokingresponse, smokingindex) => (

                                                        <div className="form-check form-check-inline" key={smokingindex}>
                                                            <div className="radio-item ">
                                                                <Input name={"smoking"} id={"smoking" + smokingindex} type="radio"
                                                                    checked={smokingresponse.value == lifestylePreferenceFormData.smoking}
                                                                    onChange={event => handleSmoking(smokingresponse)}
                                                                />
                                                                <label htmlFor={"smoking" + smokingindex}>{smokingresponse.label}</label>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </Col>

                                <Col md={12} sm={12}>
                                    <div className=" features feature-primary p-1">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Drinking Habit</Label>
                                            <section className="radio-section">
                                                <div className="radio-list">
                                                    {(drinkingHabitList || []).map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <div className="radio-item ">
                                                                <Input name={"drinking"} id={"drinking" + index} type="radio"
                                                                    checked={response.value == lifestylePreferenceFormData.drinking}
                                                                    onChange={event => handleDrinking(response)}
                                                                />
                                                                <label htmlFor={"drinking" + index}>{response.label}</label>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <hr style={{ boxShadow: `0px 1px 1px` }} />

                                </Col>

                                <Col md={12} sm={12}>
                                    <img src={exerciseIcon} className='legendIcon' style={{ width: `40px` }} />
                                    &nbsp; <span className="m-2 legendName" >Physical Activity</span>
                                    <div className=" features feature-primary p-1">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Exercise Routine</Label>
                                            <section className="radio-section">
                                                <div className="radio-list">
                                                    {exerciseHabitList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <div className="radio-item ">
                                                                <Input name="exercise" id={"exercise" + index} type="radio"
                                                                    checked={response.value == lifestylePreferenceFormData.excercise}
                                                                    onChange={event => handleExercise(response)}
                                                                />
                                                                <label htmlFor={"exercise" + index}>{response.label}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={12} sm={12}>
                                    {/* <img src={gamesIcon} className='legendIcon' style={{ width: `40px` }} />
                        &nbsp; <span className="m-2 legendName" >Physical Activity</span> */}
                                    <div className="features feature-primary p-1">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Games</Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {gamesList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox"
                                                                            id={response.label + "games"}
                                                                            checked={lifestylePreferenceFormData.playgames.includes(response.value)}
                                                                            onChange={(event) => handlePlayGames(event.target.checked, response)}
                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "games"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                                </Col>




                                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                    <button
                                        type="submit"
                                        className="btn btn-outline-primary mt-2 "
                                    >
                                        {' '}
                                        Save Changes{' '}
                                    </button>{' '}
                                    <button
                                        onClick={handleReset}
                                        className="btn btn-outline-secondary mt-2 ms-2"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </Row>
                        </Form>
                    )
            }
        </React.Fragment>
    );
};
export default LifestyleFunction;
