import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardImg,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Slider from 'react-slick';
import { useApi } from '../../../hooks/useApi';
import { favouriteService } from '../../../services/api';
import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CmMemberCard = ({
  member,
  currentPage,
  searchCriteria,
  configurationData,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFavorite, setIsFavorite] = useState(member.likedstatus);
  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false);
  const [openSkillsModal, setopenSkillsModal] = useState(false);
  const [skillsetData, setSkillsetData] = useState([]);
  const { callApi } = useApi();

  const memberTypeEnums = {
    ALLIANCE_SEEKER: 1,
    COMMUNITY_MEMBER: 4,
  };

  const handleCardClick = () => {
    navigate(`/member/${member.memberuuid}`, {
      state: {
        locationUrl: '/cmMembers',
        returnPage: currentPage,
        searchCriteria: searchCriteria,
        memberConnectionStatus: member.connectionstatus,
      },
    });
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleLikeModal = () => setIsLikeModalOpen(!isLikeModalOpen);

  const toggleSkills = (skills) => {
    setopenSkillsModal(true);
    setSkillsetData(skills);
  };
  const closeSkillModal = () => {
    setopenSkillsModal(false);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    toggleModal();
  };

  const handleFavoriteClick = async (memberuuid, likedstatus) => {
    if (!configurationData || Object.keys(configurationData).length === 0) {
      navigate('/index');
      return;
    }
    // TODO first check the subscription status of the user
    if (configurationData.subscription != 'Basic') {
      // navigate('/subscriptionPlan');
    }
    toggleLikeModal();
  };

  const handleFavoriteConfirmation = async () => {
    try {
      await callApi(() => 
        favouriteService.addCommunityFavourite({
          likedmemberuuid: member.memberuuid,
          memberuuid: localStorage.getItem('memberID')
        })
      );
      
      setIsFavorite(!isFavorite);
      toggleLikeModal();
    } catch (error) {
      console.error('Error updating favorite status:', error);
      // Handle specific error cases if needed
    }
  };

  const defaultImage = member.gender === 'Male' ? manavatar : femaleavatar;
  const photos = [
    member.photothumb1 || defaultImage,
    member.photothumb2 || defaultImage,
    member.photothumb3 || defaultImage,
  ];

  const formattedHeight =
    typeof member.height === 'number' && member.height > 0
      ? Number(member.height).toFixed(1)
      : Number(member.height).toFixed(1);

  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .trim();
  };

  const formattedFirstName = capitalizeFirstLetter(member.firstname);

  const getJobTitle = (jobtitle, age) => {
    if (jobtitle) {
      return capitalizeFirstLetter(jobtitle);
    } else if (age < 25) {
      return 'Student';
    } else {
      return 'Unentered Job Title';
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ul style={{ margin: 0, padding: 0 }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: '#fff',
          opacity: 0.75,
        }}
      />
    ),
  };

  return (
    <Card className="member-card border-0 shadow-md h-100">
      <div className="position-relative">
        <Slider {...settings}>
          {photos.map((photo, index) => (
            <div key={index}>
              <CardImg
                top
                src={photo}
                alt={formattedFirstName}
                className="member-photo"
                style={{
                  objectFit: 'cover',
                  cursor: 'pointer',
                  width: '100%',
                  height: 'auto', // Adjust the height to auto
                }}
                onClick={() => handleImageClick(photo)}
              />
            </div>
          ))}
        </Slider>
        {
          <div className="member-badges position-absolute top-0 end-0 p-2 d-flex gap-2 fs-6">
            {member.ismentor && (
              <span
                className="badge rounded-pill"
                style={{
                  background:
                    'linear-gradient(45deg,rgb(14, 88, 3),rgb(7, 95, 4))',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                }}
              >
                <FeatherIcon icon="award" size={14} fill="#OD6EFD" />
                <span className="ms-1 text-white">Mentor</span>
              </span>
            )}
            {member.ismentee && (
              <span
                className="badge rounded-pill"
                style={{
                  background:
                    'linear-gradient(45deg,rgb(229, 101, 22),rgb(228, 97, 36))',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                }}
              >
                <FeatherIcon icon="check-circle" size={12} fill="#FFF" />
                <span className="ms-1">Mentee</span>
              </span>
            )}
          </div>
        }
        {member.companyname && (
          <div className="member-badges position-absolute bottom-0 start-0 p-3 fs-6">
            <span
              className="badge"
              style={{
                background: 'rgb(236, 240, 10)',
                color: 'white',
                padding: '0.5em 1em',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
              }}
            >
              <span className="ms-1 text-dark fs-7">
                {member.companyname.split(' ')[0]}
              </span>
            </span>
          </div>
        )}
      </div>

      <CardBody className="p-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5 className="mb-0">
            {formattedFirstName}, {member.currentage}
          </h5>
          <span className="d-flex align-items-center">
            <FeatherIcon
              icon="arrow-up"
              size={14}
              style={{ color: '#6610f2' }}
            />
            <span className="ms-1 fs-6">{formattedHeight} ft</span>
          </span>
          <Button
            color="link"
            className="p-0 favorite-btn"
            onClick={() =>
              handleFavoriteClick(member.memberuuid, member.likedstatus)
            }
          >
            <FeatherIcon
              icon="heart"
              fill={isFavorite ? '#dc3545' : 'none'}
              stroke={isFavorite ? '#dc3545' : 'currentColor'}
            />
          </Button>
        </div>

        <span className="fs-5 text-dark mb-2">
          {getJobTitle(member.jobtitle, member.currentage)}
        </span>

        <div className="member-info mb-3 pt-1">
          <div className="d-flex align-items-center text-dark mb-2">
            <FeatherIcon
              icon="briefcase"
              size={14}
              style={{ color: '#0d6efd' }}
            />
            <span className="fs-6 text-dark ms-2">
              Profession: {member.jobprofession || 'Not Entered'}
            </span>
          </div>

          <div className="d-flex align-items-center text-dark mb-2">
            <FeatherIcon
              icon="map-pin"
              size={14}
              style={{ color: '#dc3545' }}
            />
            <span className="ms-2 fs-6 text-dark">{member.currentlocationname}</span>
          </div>

          <div className="d-flex justify-content-center fs-6">
            {member.skillset.length > 0 && (
              <div>
                <Link
                  className="rounded bg-soft-primary shadow-md py-1 px-2 small"
                  title={member.skillset[0]}
                >
                  {member.skillset[0]}
                </Link>
                {member.skillset.length > 1 && (
                  <button
                    className="btn btn-link"
                    onClick={() => toggleSkills(member.skillset)}
                    style={{ fontSize: `12px` }}
                  >
                    +{member.skillset.length - 1} more
                  </button>
                )}
              </div>
            )}
            {member.skillset.length == 0 && (
              <Link className="rounded shadow-md bg-soft-primary py-1 px-2 small mt-2">
                Skills Not Mentioned
              </Link>
            )}
          </div>
        </div>

        <div className="member-details d-flex flex-wrap gap-3 mb-1 border-top pt-1">
          {/* Empty Space You can add things here */}
        </div>

        <Button
          color="primary"
          block
          className="mt-1 cm-view-profile-btn"  // Changed from view-profile-btn
          onClick={handleCardClick}
        >
          Explore Profile
        </Button>
      </CardBody>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <button
          type="button"
          aria-label="Close"
          className="close-button"
          onClick={toggleModal}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
            zIndex: '1000',
          }}
        >
          &times;
        </button>
        <ModalBody>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Modal"
              className="img-fluid"
              style={{ width: '100%' }}
            />
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={isLikeModalOpen} toggle={toggleLikeModal}>
        <ModalHeader toggle={toggleLikeModal}>
          Confirmation
          <button
            type="button"
            className="close-button"
            aria-label="Close"
            onClick={toggleLikeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <p className="mb-0">
            Are you sure you want to {isFavorite ? 'unlike' : 'like'} this
            member?
          </p>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button color="secondary" onClick={toggleLikeModal} type="button">
            Close
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={handleFavoriteConfirmation}
          >
            {isFavorite ? 'Unlike' : 'Like'}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openSkillsModal} toggle={closeSkillModal}>
        <ModalHeader toggle={closeSkillModal}>
          Skills
          <button
            type="button"
            className="close-button"
            aria-label="Close"
            onClick={closeSkillModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              {(skillsetData || []).map((item, index) => (
                <div key={index} className="d-flex">
                  <i
                    className="fa fa-angle-double-right mt-1"
                    aria-hidden="true"
                  ></i>
                  &nbsp; &nbsp;
                  <div className="cursor-pointer"> {item}</div>
                </div>
              ))}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <style>
        {`
          .member-card {
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            border-radius: 12px;
            overflow: hidden;
            border: 1px solid #e0e0e0; /* Light border */
            background-color: #f9f9f9; /* Light background */
          }

          .member-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
          }

          .member-photo {
            width: 100%;
            height: auto; /* Adjust the height to auto */
            object-fit: cover;
          }

          .favorite-btn:hover {
            transform: scale(1.2);
            transition: transform 0.2s ease;
          }

          .badge {
            font-weight: 500;
            padding: 0.5em 0.8em;
            font-size: 0.75em;
            text-transform: uppercase;
            letter-spacing: 0.5px;
          }

          .marital-status-badge {
            text-transform: none;
          }

          .member-info span,
          .member-details span {
            font-size: 0.90rem;
            font-weight: 500;
            letter-spacing: 0.2px;
            color: #495057;
          }

          .slick-dots {
            bottom: 10px !important; /* Ensure dots are on the image */
          }

          .slick-dots li button:before {
            font-size: 10px;
            color: white;
            opacity: 0.75;
          }

          .slick-dots li.slick-active button:before {
            color: white;
            opacity: 1;
          }

          .cm-view-profile-btn {  // Changed from view-profile-btn
            background: primary;
            border: none;
            color: white;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            transition: all 0.3s ease;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }

          .cm-view-profile-btn:hover {  // Changed from view-profile-btn
            background: linear-gradient(45deg, #45a049, #4CAF50);
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
          }
        `}
      </style>
    </Card>
  );
};

export default CmMemberCard;
