import * as Common from './CommonHeader';
const React = Common.React;
const FontAwesomeIcon = Common.FontAwesomeIcon;
const {
  faSun,
  faScissors,
  faEye,
  faWeight,
  faTint,
  faGlobe,
  faMoneyBillWave,
  faBuilding,
  faMapMarkerAlt,
  faGraduationCap,
  faUniversity,
  faPrayingHands,
  faUsers,
  faPeace,
  faHome,
  faMale,
  faFemale,
  faAward,
  faDna,
  faHeart,
  faSmokingBan,
  faWineGlass,
  faDumbbell,
  faGamepad,
  faPaw,
} = Common;

const PersonalInformationSection = ({ member, styles }) => {
  const iconClass = member?.membertypeid === 1 ? 'icon-color' : 'text-primary';

  const cardStyles = {
    card: {
      height: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
    },
    cardBody: {
      flex: 1,
      padding: '1.25rem',
    },
    contentWrapper: {
      overflow: 'auto',
      height: '100%',
    },
    textContainer: {
      ...styles.iconTextContainer,
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
    },
    textContent: {
      flex: 1,
      overflow: 'hidden',
    },
    text: {
      margin: 0,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
    boldText: {
      ...styles.boldText,
      wordBreak: 'break-word',
    },
  };

  const CardWrapper = ({ title, children }) => (
    <Common.Col md="4" className="mb-4">
      <Common.Card
        className="border-1 shadow-sm card-bordered"
        style={cardStyles.card}
      >
        <Common.CardBody style={cardStyles.cardBody}>
          <div style={styles.sectionHeading}>
            <h5>{title}</h5>
          </div>
          <div style={cardStyles.contentWrapper}>{children}</div>
        </Common.CardBody>
      </Common.Card>
    </Common.Col>
  );

  const InfoItem = ({ icon, label, value }) => (
    <div style={cardStyles.textContainer} className="mb-2">
      <div className="icon-box-square">
        <FontAwesomeIcon icon={icon} size="lg" className={iconClass} />
      </div>
      <div style={cardStyles.textContent}>
        <p style={cardStyles.text}>
          {label}{' '}
          <span style={cardStyles.boldText}>{value || 'Not Specified'}</span>
        </p>
      </div>
    </div>
  );

  return (
    <>
      <div style={styles.headingTab}>
        <p style={styles.headingText} className="fs-5">
          More Personal Information
        </p>
      </div>
      <Common.Row>
        {/* Educational Details */}
        <CardWrapper title="Educational Details">
          <InfoItem
            icon={faGraduationCap}
            label="Degree:"
            value={member.degreename || member.degreelevel}
          />
          <InfoItem
            icon={faUniversity}
            label="Degree College:"
            value={member.degreecollege}
          />
        </CardWrapper>

        {/* Professional Details */}
        <CardWrapper title="Professional Details">
          <InfoItem
            icon={faMoneyBillWave}
            label="Salary:"
            value={member.salctc}
          />
          <InfoItem
            icon={faBuilding}
            label="Company Name:"
            value={member.companyname}
          />
          <InfoItem
            icon={faMapMarkerAlt}
            label="Company Location:"
            value={member.companylocationname}
          />
        </CardWrapper>

        {/* Continue with other sections using the same pattern */}
        {/* Religious Details */}
        <CardWrapper title="Religious | Spiritual Details">
          <InfoItem
            icon={faPrayingHands}
            label="Current Religion:"
            value={
              member.currentreligion || member.previousreligion || 'No Religion'
            }
          />
          <InfoItem icon={faUsers} label="Caste:" value={member.caste} />
          <InfoItem
            icon={faPeace}
            label="Number of Vipassanas:"
            value={member.noofvipassana || 0}
          />
          <InfoItem
            icon={faPeace}
            label="Other Meditation:"
            value={member.othermeditation}
          />
        </CardWrapper>

        {/* Personal Details */}
        <CardWrapper title="Personal Details">
          <InfoItem
            icon={faSun}
            label="Complexion:"
            value={member.complexion}
          />
          <InfoItem
            icon={faWeight}
            label="Weight:"
            value={`${member.weight} kg`}
          />
          <InfoItem
            icon={faTint}
            label="Blood Group:"
            value={member.bloodgroup}
          />
          <InfoItem
            icon={faScissors}
            label="Hair Color:"
            value={member.haircolor}
          />
          <InfoItem icon={faEye} label="Eye Color:" value={member.eyecolor} />
          <InfoItem
            icon={faGlobe}
            label="Languages Known:"
            value={member.languages || 'English'}
          />
        </CardWrapper>

        {/* Family Details */}
        <CardWrapper title="Family Details">
          <InfoItem
            icon={faHome}
            label="Family type:"
            value={member.familytype}
          />
          <InfoItem icon={faMale} label="Father:" value={member.father} />
          <InfoItem icon={faFemale} label="Mother:" value={member.mother} />
          <InfoItem
            icon={faAward}
            label="Number of Siblings:"
            value={member.noofsibling || 0}
          />
          <InfoItem
            icon={faAward}
            label="Your position in Siblings:"
            value={member.yourposition || 0}
          />
          <InfoItem
            icon={faDna}
            label="Genetic Disease:"
            value={member.geneticdiseases}
          />
        </CardWrapper>

        {/* LifeStyle Details */}
        <CardWrapper title="LifeStyle Details">
          <InfoItem
            icon={faHeart}
            label="Diet Preferences:"
            value={member.diet}
          />
          <InfoItem
            icon={faSmokingBan}
            label="Smoking:"
            value={member.smokinghabit || 'NA'}
          />
          <InfoItem
            icon={faWineGlass}
            label="Drinking:"
            value={member.drinkinghabit || 'NA'}
          />
          <InfoItem
            icon={faDumbbell}
            label="Exercise Routines:"
            value={member.exerciseroutine}
          />
          <InfoItem icon={faGamepad} label="Games:" value={member.gameList} />
          <InfoItem
            icon={faHeart}
            label="Hobbies & Interests:"
            value={member.hobbies}
          />
          <InfoItem
            icon={faPaw}
            label="Pet Preferences:"
            value={member.petpreference}
          />
        </CardWrapper>
      </Common.Row>
    </>
  );
};

export default PersonalInformationSection;
