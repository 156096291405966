import React, { useState } from 'react'; // Add useState import
import { Card, CardBody, CardImg, Badge, Button } from 'reactstrap';
import { Calendar, Clock, MapPin, Users, Edit, Bookmark, ExternalLink, Trash2 } from 'lucide-react'; // Add Trash2 import
import defaultEventImage from '../../../assets/images/MettaMateImages/eventSamples/defaultEventImage.png';
import * as ReactIcons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { message, Modal, Table, Pagination } from 'antd'; // Add message, Modal, Table, and Pagination import
import apiUrl from 'apppages/server'; // Add apiUrl import
import { Users as UsersIcon } from 'lucide-react';
import { useApi } from '../../../hooks/useApi';
import { eventService } from '../../../services/api';

const typographyStyles = {
  title: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '1.35rem',
    fontWeight: '600',
    lineHeight: '1.4',
    color: '#1A202C',
    marginBottom: '0.5rem' // Reduced from 0.75rem
  },
  description: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.95rem', // Slightly smaller for better compactness
    lineHeight: '1.6',
    color: '#2D3748',
    marginBottom: '0.75rem', // Reduced from 1rem
    fontWeight: '400'
  },
  metadata: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.9rem', // Slightly reduced
    lineHeight: '1.5',
    color: '#2D3748',
    fontWeight: '400'
  },
  eventType: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.9rem',
    fontWeight: '500',
    padding: '0.35rem 0.85rem',
    borderRadius: '6px',
    textTransform: 'capitalize'
  },
  categoryTag: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.8rem', // Slightly smaller font
    fontWeight: '500',
    padding: '0.25rem 0.5rem', // Reduced padding
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    transition: 'all 0.3s ease',
    backgroundColor: '#F7FAFC',
    color: '#2D3748',
    border: '1px solid #E2E8F0',
    cursor: 'default',
    whiteSpace: 'nowrap'
  }
};

const truncateText = (text, wordLimit = 15) => {
  if (!text) return '';
  const words = text.split(' ');
  if (words.length <= wordLimit) return text;
  return words.slice(0, wordLimit).join(' ') + '...';
};

const badgeStyles = {
  base: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '0.9rem',
    fontWeight: '500',
    padding: '0.35rem 0.85rem',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease',
  },
  eventType: {
    virtual: {
      backgroundColor: '#EBF8FF',
      color: '#2C5282',
      border: '1px solid #BEE3F8'
    },
    hybrid: {
      backgroundColor: '#FEFCBF',
      color: '#975A16',
      border: '1px solid #FAF089'
    },
    'in-person': {
      backgroundColor: '#F0FFF4',
      color: '#276749',
      border: '1px solid #C6F6D5'
    }
  },
  visibility: {
    Public: {
      backgroundColor: '#E8F5E9',
      color: '#2E7D32',
      border: '1px solid #A5D6A7'
    },
    MettaMate: {
      backgroundColor: '#E3F2FD',
      color: '#1565C0',
      border: '1px solid #90CAF9'
    },
    Unlisted: {
      backgroundColor: '#FAFAFA',
      color: '#616161',
      border: '1px solid #E0E0E0'
    }
  }
};

const formatDate = (dateString) => {
  const options = { 
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

const formatTime = (time) => {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);

  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }).format(date);
};

const formatEventType = (type) => {
  if (!type) return '';
  // Handle hyphenated words like 'in-person'
  return type.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');
};

const EventCard = ({ event, isBookmarked, canEdit, onEdit }) => {
  const isVirtual = event.eventtype === 'virtual';
  const isHybrid = event.eventtype === 'hybrid';

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);
  const [isParticipantsModalVisible, setIsParticipantsModalVisible] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [participantsLoading, setParticipantsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  // Add new state for total count
  const [totalParticipants, setTotalParticipants] = useState(0);

  const { callApi } = useApi();

  const fetchParticipants = async (pageIndex = 1, pageSize = 10) => {
    try {
      setParticipantsLoading(true);
      const response = await callApi(() =>
        eventService.fetchParticipants(event.eventuuid, pageIndex, pageSize)
      );
      setParticipants(response.participants);
      setTotalParticipants(response.pagination.totalCount);
      setPagination({
        ...pagination,
        current: pageIndex,
        total: response.pagination.totalCount
      });
    } catch (error) {
      message.error('Failed to fetch participants');
    } finally {
      setParticipantsLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    fetchParticipants(pagination.current, pagination.pageSize);
  };

  const columns = [
    {
      title: 'Sr. No',
      key: 'index',
      render: (_, __, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
      width: 80,
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <Link
          to={`/member/${record.memberuuid}`}
          onClick={(e) => e.stopPropagation()}
          style={{
            color: '#1890ff',
            textDecoration: 'none'
          }}
        >
          {`${record.firstname} ${record.lastname}`}
        </Link>
      ),
    },
    {
      title: 'Contact Details',
      key: 'contact',
      render: (record) => (
        <div>
          <div>
            <i className="fas fa-envelope me-2 text-muted"></i>
            {record.emailid}
          </div>
          <div>
            <i className="fas fa-phone me-2 text-muted"></i>
            {`+${record.phonecountrycode} ${record.phonenumber}`}
          </div>
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 120,
      render: (record) => (
        <Link
          to={`/member/${record.memberuuid}`}
          onClick={(e) => e.stopPropagation()}
          className="btn btn-primary btn-sm"
          style={{
            borderRadius: '6px',
            fontSize: '0.85rem',
            padding: '0.25rem 0.75rem'
          }}
        >
          View Profile
        </Link>
      ),
    }
  ];

  const getIconComponent = (iconName) => {
    return iconName ? (ReactIcons[iconName] || BiIcons[iconName] || ReactIcons.FaUsers) : ReactIcons.FaUsers;
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await eventService.deleteEvent(event.eventuuid);
      message.success('Event deleted successfully');
      window.location.reload();
    } catch (error) {
      console.error('Failed to delete event:', error);
      message.error('Failed to delete event');
    }
    setIsDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleMemberClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMemberModalVisible(true);
  };

  const handleMemberModalClose = () => {
    setIsMemberModalVisible(false);
  };

  // New function to handle card click
  const handleCardClick = () => {
    window.location.href = `/events/${event.eventuuid}`;
  };

  return (
    <div className="position-relative"> {/* Wrap card in a relative div */}
      <Card 
        className="h-100 hover-shadow transition-all" 
        style={{
          textDecoration: 'none',
          borderRadius: '12px',
          border: '1px solid #E2E8F0',
          background: '#ffffff',
          cursor: 'pointer'
        }}
        onClick={handleCardClick}
      >
        <div className="position-relative">
          <CardImg 
            top 
            src={event.eventimagefilepath || defaultEventImage} 
            alt={event.eventtitle}
            style={{ 
              height: '220px', // Reduced from 200px
              objectFit: 'cover',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px'
            }}
          />
          {/* Event Type and Visibility Badges */}
          <div className="position-absolute top-0 start-0 p-3 d-flex gap-2">
            {/* Event Type Badge */}
            <div
              className="shadow-sm"
              style={{
                ...badgeStyles.base,
                ...badgeStyles.eventType[event.eventtype]
              }}
            >
              {formatEventType(event.eventtype)}
            </div>

            {/* Visibility Badge - Only show in My Events tab */}
            {canEdit && (
              <div
                className="shadow-sm"
                style={{
                  ...badgeStyles.base,
                  ...badgeStyles.visibility[event.eventvisibility || 'Public']
                }}
              >
                {event.eventvisibility || 'Public'}
              </div>
            )}
          </div>
          
          {/* Edit and Delete Buttons - Right Side */}
          {canEdit && (
            <div className="position-absolute top-0 end-0 p-3 d-flex gap-2">
              <Button
                color="light"
                size="sm"
                className="d-flex align-items-center gap-1 px-3 py-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEdit();
                }}
                style={{
                  borderRadius: '15px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)'
                }}
              >
                <Edit size={16} />
                <span className='fs-6'>Edit</span>
              </Button>

              <Button
                color="danger"
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={handleDelete}
                style={{
                  borderRadius: '15px',
                  width: '38px',
                  height: '38px',
                  padding: 0,
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  backgroundColor: 'rgba(255, 59, 48, 0.9)'
                }}
              >
                <Trash2 size={16} color="white" />
              </Button>
            </div>
          )}
        </div>

        <CardBody className="p-3"> {/* Reduced padding from p-4 */}
          <h5 style={typographyStyles.title}>
            {event.eventtitle}
          </h5>

          <p className="line-clamp-2" style={typographyStyles.description}>
            {truncateText(event.eventdetails, 20)}
          </p>

          <div className="d-flex flex-column gap-2 mb-2"> {/* Reduced gap-3 to gap-2 and mb-4 to mb-2 */}
            <div className="d-flex align-items-center">
              <Calendar size={16} className="me-2 text-primary" /> {/* Reduced icon size from 18 */}
              <span style={typographyStyles.metadata}>
                {formatDate(event.eventstartdate)}
              </span>
            </div>
            
            <div className="d-flex align-items-center">
              <Clock size={16} className="me-2 text-primary" />
              <span style={typographyStyles.metadata}>
                {formatTime(event.eventstarttime)}
                {' - '}
                {formatTime(event.eventendtime)}
                <span className="ms-1 text-primary">({event.eventtimezone})</span>
              </span>
            </div>

            <div className="d-flex align-items-start">
              <MapPin size={16} className="me-2 mt-1 text-primary flex-shrink-0" />
              <span className="line-clamp-1" style={typographyStyles.metadata}>
                {isVirtual ? 'Virtual Event' : event.eventlocation_address}
              </span>
            </div>
          </div>

          {/* Updated Categories Section */}
          <div className="mb-2"> {/* Reduced from mb-4 */}
            <div className="d-flex flex-wrap gap-1"> {/* Reduced gap from 2 to 1 */}
              {Array.isArray(event.categories) && event.categories.map((category, index) => {
                const categoryName = typeof category === 'object' ? category.categoryname : category;
                const categoryId = typeof category === 'object' ? category.categoryuuid : index;
                const categoryIcon = typeof category === 'object' ? category.categoryicon : null;
                const IconComponent = getIconComponent(categoryIcon);
                const categoryColor = typeof category === 'object' ? category.color : '#4A5568';
                
                return (
                  <div
                    key={categoryId}
                    style={{
                      ...badgeStyles.base,
                      backgroundColor: `${categoryColor}15`, // 15% opacity
                      color: categoryColor,
                      border: `1px solid ${categoryColor}30` // 30% opacity
                    }}
                    className="category-badge bg-soft-primary"
                  >
                    <IconComponent 
                      size={18} // Reduced from 16
                      style={{ color: categoryColor}}
                    />
                    <span>{categoryName}</span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between align-items-center mt-2 pt-2 border-top">
              {event.eventticketurl && (
                <Button
                  color="primary"
                  size="sm"
                  outline
                  className="d-flex align-items-center gap-1 px-2 py-1"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click
                    window.open(event.eventticketurl, '_blank');
                  }}
                  style={{
                    borderRadius: '6px',
                    fontSize: '0.85rem'
                  }}
                >
                  <ExternalLink size={14} />
                  <span>Register</span>
                </Button>
              )}

              <div className="ms-auto d-flex align-items-center gap-2">
                <span className="text-muted fs-7">
                Posted By:
                </span>
                <Link
                  to={`/member/${event.memberuuid}`}
                  className="btn btn-link p-0 fs-6"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    textDecoration: 'none',
                    color: '#007bff',
                    fontWeight: '500'
                  }}
                >
                  {event.firstname}
                </Link>
              </div>
            </div>

            {/* New row for List Participants button */}
            {canEdit && (
              <div className="d-flex justify-content-start mt-2 pt-2 border-top">
                <Button
                  color="warning"
                  size="sm"
                  className="d-flex align-items-center gap-1 px-3 py-1 position-relative"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsParticipantsModalVisible(true);
                    fetchParticipants();
                  }}
                  style={{
                    borderRadius: '6px',
                    fontSize: '0.85rem'
                  }}
                >
                  <UsersIcon size={14} />
                  <span>Registered Participants</span>
                  {totalParticipants > 0 && (
                    <span 
                      className="position-absolute translate-middle badge rounded-pill bg-danger"
                      style={{ 
                        top: '-5px', 
                        right: '-10px',
                        fontSize: '0.7rem',
                        padding: '0.35em 0.65em'
                      }}
                    >
                      {totalParticipants}
                    </span>
                  )}
                </Button>
              </div>
            )}
          </div>
        </CardBody>

        <style>
          {`
            @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

            .hover-shadow {
              transition: all 0.3s ease;
              box-shadow: 0 2px 4px rgba(0,0,0,0.05);
            }
            .hover-shadow:hover {
              transform: translateY(-4px);
              box-shadow: 0 12px 24px rgba(0,0,0,0.1) !important;
            }
            .line-clamp-2 {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .line-clamp-1 {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .transition-all {
              transition: all 0.3s ease;
            }
            .category-badge {
              transform: translateY(0);
              transition: all 0.2s ease;
            }
            
            .category-badge:hover {
              transform: translateY(-1px);
              box-shadow: 0 2px 4px rgba(0,0,0,0.05);
              background-color: #EDF2F7;
            }
          `}
        </style>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal
        title={
          <div className="text-danger">
            <Trash2 className="me-2" size={20} />
            Delete Event
          </div>
        }
        open={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okText="Yes, Delete"
        cancelText="Cancel"
        okButtonProps={{ 
          danger: true,
          style: { 
            paddingLeft: '2rem', 
            paddingRight: '2rem' 
          } 
        }}
      >
        <div className="py-4">
          <h5>Are you sure you want to delete "{event.eventtitle}"?</h5>
          <div className="alert alert-warning mt-3">
            <p className="mb-0">
              <strong>Warning:</strong> This action cannot be undone without MettaMate's admin support.
              Consider editing the event's visibility instead if you want to temporarily hide it.
            </p>
          </div>
        </div>
      </Modal>

      {/* Member Details Modal */}
      <Modal
        title="Member Details"
        open={isMemberModalVisible}
        onOk={handleMemberModalClose}
        onCancel={handleMemberModalClose}
        okText="Close"
      >
        <div className="py-4">
          <h5>{event.firstname} {event.lastname}</h5>
        </div>
      </Modal>

      {/* Participants Modal */}
      <Modal
        title={
          <div className="d-flex align-items-center gap-2">
            <UsersIcon size={20} className="text-primary" />
            <span>Registered Participants</span>
          </div>
        }
        open={isParticipantsModalVisible}
        onCancel={() => setIsParticipantsModalVisible(false)}
        width={800}
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={participants}
          pagination={pagination}
          onChange={handleTableChange}
          loading={participantsLoading}
          rowKey={(record) => record.memberuuid} // Add unique key for each row
          rowClassName={(record, index) => 
            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
          }
          style={{ 
            marginTop: '1rem',
            borderRadius: '8px',
            overflow: 'hidden',
            backgroundColor: '#FFFFD4' // Light yellow note-taking color
          }}
        />
        <style>
          {`
            .table-row-light {
              background-color: #FFFFD4; /* Light yellow */
            }
            .table-row-dark {
              background-color: #FFFFE8; /* Slightly darker yellow */
            }
            .ant-table {
              border: 1px solid #FFE4B5; /* Moccasin border */
            }
            .ant-table-thead > tr > th {
              background-color: #FFF8DC !important; /* Cornsilk */
              color: #8B4513; /* Saddle Brown */
              font-weight: 600;
              border-bottom: 2px solid #FFE4B5;
            }
            .ant-table-tbody > tr:hover > td {
              background-color: #FFF8DC !important; /* Cornsilk */
            }
            .ant-table {
              border: 1px solid #e5e7eb;
            }
            .ant-table-thead > tr > th {
              background-color: #f8fafd !important;
              color: #1a73e8;
              font-weight: 600;
              border-bottom: 2px solid #e5e7eb;
            }
            .ant-table-tbody > tr:hover > td {
              background-color: #e8f1fc !important;
            }
            .ant-table-cell {
              vertical-align: middle !important;
              padding: 16px !important;
            }
            .ant-table-pagination {
              margin: 16px 0;
            }
            .ant-table-tbody > tr > td {
              border-bottom: 1px solid #e5e7eb;
              transition: background 0.2s ease;
            }
          `}
        </style>
      </Modal>
    </div>
  );
};

export default EventCard;
