import React, { useState, useRef } from 'react';
import {
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Row,
  Col,
} from 'reactstrap';
import RangeSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Search, Sliders } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserMd,
  faLaptopCode,
  faGavel,
  faChalkboardTeacher,
  faBriefcase,
  faFlask,
  faChartLine,
  faBuilding,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';

const professionFilters = [
  {
    icon: 'stethoscope',
    filterName: 'Doctors',
    value: 1,
    color: '#1a73e8',
    bgColor: '#f4f8fe',
  },
  {
    icon: 'hard-hat',
    filterName: 'Engineers',
    value: 4,
    color: '#e37400',
    bgColor: '#fef7f0',
  },
  {
    icon: 'laptop-code',
    filterName: 'Software Engineers',
    value: 3,
    color: '#9334e6',
    bgColor: '#f8f1fe',
  },
  {
    icon: 'gavel',
    filterName: 'Lawyers',
    value: 2,
    color: '#d93025',
    bgColor: '#fef2f1',
  },
  {
    icon: 'chalkboard-teacher',
    filterName: 'Professors',
    value: 9,
    color: '#188038',
    bgColor: '#f1f8f4',
  },
  {
    icon: 'briefcase',
    filterName: 'Management',
    value: 6,
    color: '#a50e0e',
    bgColor: '#fce8e6',
  },
  {
    icon: 'microscope',
    filterName: 'Scientist',
    value: 5,
    color: '#e37400',
    bgColor: '#fef7f0',
  },
  {
    icon: 'chart-line',
    filterName: 'Financial Executives',
    value: 7,
    color: '#1a73e8',
    bgColor: '#f4f8fe',
  },
  {
    icon: 'building',
    filterName: 'Business Executives',
    value: 8,
    color: '#9334e6',
    bgColor: '#f8f1fe',
  },
];

const countryFilters = [
  {
    icon: '🇺🇸',
    filterName: 'USA',
    value: 'United States',
    color: '#D32F2F',
    bgColor: '#FFEBEE',
  },
  {
    icon: '🇬🇧',
    filterName: 'UK',
    value: 'United King',
    color: '#1976D2',
    bgColor: '#E3F2FD',
  },
  {
    icon: '🇮🇳',
    filterName: 'India',
    value: 'India',
    color: '#388E3C',
    bgColor: '#E8F5E9',
  },
  {
    icon: '🇦🇺',
    filterName: 'AUS',
    value: 'Australia',
    color: '#0288D1',
    bgColor: '#E1F5FE',
  },
  {
    icon: '🇦🇪',
    filterName: 'UAE',
    value: 'United Arab',
    color: '#0288D1',
    bgColor: '#E1F5FE',
  },
  {
    icon: '🇩🇪',
    filterName: 'Germany',
    value: 'Germany',
    color: '#0288D1',
    bgColor: '#E1F5FE',
  },
  {
    icon: '🇲🇾',
    filterName: 'Malaysia',
    value: 'Malaysia',
    color: '#0288D1',
    bgColor: '#E1F5FE',
  },
  {
    icon: '🇸🇬',
    filterName: 'Singapore',
    value: 'Singapore',
    color: '#0288D1',
    bgColor: '#E1F5FE',
  },
];

const CmMembersSearch = ({ onSearch }) => {
  const searchTextRef = useRef(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    searchText: '',
    professions: [],
    countries: [],
    ageRange: [18, 60],
    gender: '',
    roleAtMetta: '',
    companyName: '',
    degreeCollegeName: '',
  });

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchText = searchTextRef.current.value;
    onSearch({
      ...filters,
      searchText,
    });
    setIsFilterModalOpen(false);
  };

  const handleProfessionClick = (profession) => {
    setFilters((prev) => ({
      ...prev,
      professions: prev.professions.includes(profession.value)
        ? prev.professions.filter((p) => p !== profession.value)
        : [...prev.professions, profession.value],
    }));
  };

  const handleCountryClick = (country) => {
    setFilters((prev) => ({
      ...prev,
      countries: prev.countries.includes(country.value)
        ? prev.countries.filter((c) => c !== country.value)
        : [...prev.countries, country.value],
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      searchText: '',
      professions: [],
      countries: [],
      ageRange: [18, 60],
      gender: '',
      roleAtMetta: '',
      companyName: '',
      degreeCollegeName: '',
    });
  };

  return (
    <div className="search-container">
      <Card
        className="border-0 mb-0 search-panel" // changed mb-4 to mb-2
        style={{ backgroundColor: '#fff' }}
      >
        <CardBody className="p-3">
          <div className="category-section mb-1">
            {/* Profession Filter Section */}
            <div className="d-flex align-items-center mb-2">
              <div className="flex-grow-1 border-bottom"></div>
              <span className="px-3 text-dark small text-uppercase fw-bold fs-6">
                Professions
              </span>
              <div className="flex-grow-1 border-bottom"></div>
            </div>
            <div className="filters-scroll-wrapper">
              <Button
                color={filters.professions.length === 0 ? 'primary' : 'light'}
                className="filter-button all-button fs-6 text-dark"
                onClick={() => handleFilterChange('professions', [])}
              >
                All Professions
              </Button>
              {professionFilters.map((profession) => (
                <Button
                  key={profession.value}
                  color={filters.professions.includes(profession.value) ? 'primary' : 'light'}
                  className={`filter-button ${filters.professions.includes(profession.value) ? 'active' : ''}`}
                  onClick={(e) => {
                    handleProfessionClick(profession);
                    e.currentTarget.blur();
                  }}
                  style={{
                    '--button-color': filters.professions.includes(
                      profession.value,
                    )
                      ? '#0d6efd'
                      : profession.color,
                    '--button-bg-color': profession.bgColor,
                  }}
                >
                  <i
                    className={`fas fa-${profession.icon}`}
                    style={{
                      color: filters.professions.includes(profession.value)
                        ? '#fff'
                        : profession.color,
                    }}
                  />
                  <span style={{ color: filters.professions.includes(profession.value) ? '#fff' : 'black' }}>{profession.filterName}</span>
                </Button>
              ))}
            </div>
          </div>

          <div className="category-section mb-1">
            {/* Country Filter Section */}
            <div className="d-flex align-items-center mb-2">
              <div className="flex-grow-1 border-bottom"></div>
              <span className="px-3 text-dark small text-uppercase fw-bold fs-6">
                Locations
              </span>
              <div className="flex-grow-1 border-bottom"></div>
            </div>
            <div className="filters-scroll-wrapper">
              <Button
                color={filters.countries.length === 0 ? 'primary' : 'light'}
                className="filter-button all-button"
                onClick={() => handleFilterChange('countries', [])}
              >
                All Countries
              </Button>
              {countryFilters.map((country) => (
                <Button
                  key={country.value}
                  color={filters.countries.includes(country.value) ? 'primary' : 'light'}
                  className={`filter-button ${filters.countries.includes(country.value) ? 'active' : ''}`}
                  onClick={(e) => {
                    handleCountryClick(country);
                    e.currentTarget.blur();
                  }}
                  style={{
                    '--button-color': filters.countries.includes(country.value)
                      ? '#0d6efd'
                      : country.color,
                    '--button-bg-color': country.bgColor,
                  }}
                >
                  <span style={{ fontSize: '16px' }}>{country.icon}</span>
                  <span style={{ color: filters.countries.includes(country.value) ? '#fff' : 'black' }}>{country.filterName}</span>
                </Button>
              ))}
            </div>
          </div>

          <form onSubmit={handleSubmit} className="mb-2">
            {/* Search Bar Section */}
            <Row className="g-3">
              <Col xs={8} md={10}>
                <div className="input-group">
                  <span className="input-group-text bg-light border-0">
                    <Search size={18} />
                  </span>
                  <Input
                    type="text"
                    placeholder="Search by name, phone, location..."
                    className="border-start-0"
                    innerRef={searchTextRef}
                  />
                </div>
              </Col>
              <Col xs={4} md={2}>
                <div className="d-flex gap-2 h-100">
                  <Button
                    color="primary"
                    type="submit"
                    className="filter-button search-filter-button w-100" // Added 'search-filter-button'
                  >
                    <Search size={18} className="d-block d-md-none" />
                    <span className="d-none d-md-block text-white">Search</span>
                  </Button>
                  <Button
                    color="light"
                    className="filter-button search-filter-button w-100" // Added 'search-filter-button'
                    onClick={() => setIsFilterModalOpen(true)}
                  >
                    <Sliders size={18} />
                  </Button>
                </div>
              </Col>
            </Row>
          </form>

          {/* Advanced Filters Modal */}
          <Modal
            isOpen={isFilterModalOpen}
            toggle={() => setIsFilterModalOpen(!isFilterModalOpen)}
            size="md"
          >
            <ModalHeader toggle={() => setIsFilterModalOpen(false)}>
              Advanced Filters
            </ModalHeader>
            <ModalBody>
              <div className="mb-4">
                <Label className="mb-2">Age Range</Label>
                <RangeSlider
                  range
                  min={18}
                  max={60}
                  value={filters.ageRange}
                  onChange={(value) => handleFilterChange('ageRange', value)}
                  className="mb-2"
                />
                <div className="d-flex justify-content-between">
                  <span>{filters.ageRange[0]} years</span>
                  <span>{filters.ageRange[1]} years</span>
                </div>
              </div>

              <div className="mb-4">
                <Label className="mb-2">Gender</Label>
                <Input
                  type="select"
                  value={filters.gender}
                  onChange={(e) => handleFilterChange('gender', e.target.value)}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Input>
              </div>

              <div className="mb-4">
                <Label className="mb-2">Role At MettaMate</Label>
                <Input
                  type="select"
                  value={filters.roleAtMetta}
                  onChange={(e) => handleFilterChange('roleAtMetta', e.target.value)}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="mentor">Mentor</option>
                  <option value="mentee">Mentee</option>
                </Input>
              </div>

              <div className="mb-4">
                <Label className="mb-2">Company Name</Label>
                <Input
                  type="text"
                  value={filters.companyName}
                  onChange={(e) => handleFilterChange('companyName', e.target.value)}
                  className="form-control"
                  placeholder="Search by company name"
                />
              </div>

              <div className="mb-4">
                <Label className="mb-2">Degree College Name</Label>
                <Input
                  type="text"
                  value={filters.degreeCollegeName}
                  onChange={(e) => handleFilterChange('degreeCollegeName', e.target.value)}
                  className="form-control"
                  placeholder="Search by degree college name"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => setIsFilterModalOpen(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={handleSubmit}>
                Apply Filters
              </Button>
              <Button color="danger" onClick={handleResetFilters}>
                Reset
              </Button>
            </ModalFooter>
          </Modal>
        </CardBody>

        <style>
          {`
            .search-panel {
              box-shadow: none; /* removed extra shadow */
              border: 1px solid #0d6efd; /* added blue border */
              border-radius: 0.25rem; /* ensure border-radius matches Card */
              margin-bottom: 0; /* remove any extra margin */
            }

            .category-section:not(:last-child) {
              padding-bottom: 1rem;
            }

            .filters-scroll-wrapper {
              display: flex;
              flex-wrap: wrap;
              gap: 0.75rem;
              padding: 0.5rem 0;
              overflow-x: auto;
              scrollbar-width: none;
              -webkit-overflow-scrolling: touch;
            }

            .filters-scroll-wrapper::-webkit-scrollbar {
              display: none;
            }

            .filter-button {
              display: inline-flex;
              align-items: center;
              gap: 0.75rem;
              padding: 0.4rem 0.8rem;
              border-radius: 8px;
              font-size: 1rem !important; /* fs-6 equivalent */
              font-weight: 500;
              letter-spacing: 0.2px;
              border: 2px solid transparent;
              background: var(--button-bg-color, #fff);
              color: #212529 !important; /* text-dark equivalent */
              text-color: var(--button-color);
              transition: transform 0.3s, background-color 0.3s, color 0.3s;
              white-space: nowrap;
              box-shadow: 0 1px 2px rgba(0,0,0,0.05);
            }

            .filter-button i {
              font-size: 1rem;
            }

            .filter-button:hover {
              transform: translateY(-1px);
              box-shadow: 0 4px 8px rgba(0,0,0,0.1);
              border-color: var(--button-color);
            }

            .filter-button.active {
              background: #0d6efd;
              color: white;
              border-color: #0d6efd;
              font-weight: 600;
            }

            .filter-button.active i {
              color: white !important;
            }

            .all-button {
              background: #f8f9fa;
              color: #495057;
              font-weight: 500;
            }

            .all-button:hover,
            .all-button.active {
              background: #0d6efd;
              color: white;
            }

            .search-filter-button.btn-primary {
              background-color: #0d6efd;
              border-color: #0d6efd;
              color: #fff;
            }
            .search-filter-button.btn-light {
              background-color: #e2e6ea;
              border: 2px solid #ced4da;
              color: #495057;
            }

            .form-control {
              font-size: 1rem !important;
              color: #212529 !important;
            }

            .modal-title, 
            .modal-body label,
            .modal-body input,
            .modal-body select {
              font-size: 1rem !important;
              color: #212529 !important;
            }

            @media (max-width: 768px) {
              .filters-scroll-wrapper {
                flex-wrap: nowrap;
                overflow-x: auto;
                padding-bottom: 0.5rem;
                scrollbar-width: none;
                -webkit-overflow-scrolling: touch;
              }

              .filters-scroll-wrapper::-webkit-scrollbar {
                display: none;
              }

              .filter-button {
                flex: 0 0 auto;
                padding: 0.5rem 1rem;
                font-size: 0.85rem;
              }
            }
          `}
        </style>
      </Card>
    </div>
  );
};

export default CmMembersSearch;
