import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Row, Col, Label, Button, Form } from 'reactstrap';
import CommonModal from '../../Home/modal';
import { useFormik } from 'formik';
import apiUrl from '../../server';
import locationIcon from '../../../assets/images/MettaMateImages/location.png';
import languageIcon from '../../../assets/images/MettaMateImages/translate.png';
import { useApi } from '../../../hooks/useApi';
import { regionalService, codesService } from '../../../services/api';

const RegionalFunction = () => {
  const { callApi } = useApi();
  const [currentmapvalue, setcurrentmapValue] = useState(null);
  const [nativemapvalue, setnativemapValue] = useState(null);
  const [motherTongue, setMotherTongue] = useState({});
  const [otherlang1, setOtherlang1] = useState({});
  const [otherlang2, setOtherlang2] = useState({});
  const [languageList, setlanguageList] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(''); // Initialize with empty string
  const [nativeAddress, setNativeAddress] = useState(''); // Initialize with empty string
  const [nativecity, setnativeCity] = useState('');
  const [nativestate, setnativeState] = useState('');
  const [nativecountry, setnativeCountry] = useState('');
  const [currentcity, setcurrentCity] = useState('');
  const [currentstate, setcurrentState] = useState('');
  const [currentcountry, setcurrentCountry] = useState('');
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);

  let memberID = localStorage.getItem('memberID');

  const fetchRegionalData = async () => {
    try {
      const response = await callApi(() => 
        regionalService.getRegionalDetails(memberID)
      );
      
      if (response.length > 0) {
        setMotherTongue(response[0].mothertongue);

        setOtherlang1(response[0].otherlang1);
        setOtherlang2(response[0].otherlang2);
        setCurrentAddress(response[0].currentlocationname);
        setNativeAddress(response[0].nativelocationname);
        setcurrentCity(response[0].currentcity);
        setcurrentState(response[0].currentstate);
        setcurrentCountry(response[0].currentcountry);
        setnativeCity(response[0].nativecity);
        setnativeState(response[0].nativestate);
        setnativeCountry(response[0].nativecountry);
        setcurrentmapValue(response[0].currentlocationid);
        setnativemapValue(response[0].nativelocationid);

      } else {
        setMotherTongue('');
        setOtherlang1('');
        setOtherlang2('');
        setCurrentAddress('');
        setNativeAddress('');
        setcurrentCity('');
        setcurrentState('');
        setcurrentCountry('');
        setnativeCity('');
        setnativeState('');
        setnativeCountry('');
        setcurrentmapValue('');
        setnativemapValue('');
      }
    } catch (error) {
      console.error('Error fetching regional data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi(() => codesService.getLanguages());
        setlanguageList(response.list);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchData();
    fetchRegionalData();
  }, []);

  const handleCurrentAddressChange = (newAddress) => {
    setCurrentAddress(newAddress || ''); // Ensure empty string if null/undefined
  };
  const handlenativeAddressChange = (newAddress) => {
    setNativeAddress(newAddress || ''); // Ensure empty string if null/undefined
  };

  const handlecurrentAddressSelect = async (address, placeid) => {
    setcurrentmapValue(placeid || '');
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      // Ensure we're setting a string value
      const locationDetails = getLocationDetails(results[0].address_components);
      setCurrentAddress(locationDetails || '');

      const addressComponents = results[0].address_components;
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.long_name || '';

      // Store the city, state, and country in your state or do something else with this information
      setcurrentCity(city);
      setcurrentState(state);
      setcurrentCountry(country);
      alert('Location here : '+ [city, state, country].filter(Boolean).join(', '));
      let locationDetails1 = [city, state, country].filter(Boolean).join(', ');
      setCurrentAddress(locationDetails1);
      console.error('location details of current', locationDetails1);

      // Use latLng if needed
    } catch (error) {
      console.error('Error selecting address:', error);
      setCurrentAddress(''); // Reset to empty string on error
    }
  };

  const handlenativeAddressSelect = async (address, placeid) => {
    setnativemapValue(placeid || '');
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      // Ensure we're setting a string value
      const locationDetails1 = getLocationDetails(results[0].address_components);
      setNativeAddress(locationDetails1 || '');

      let initualAddress = address.split(',');
      let finalAddress = initualAddress.slice(-3);
      const addressComponents = results[0].address_components;
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.long_name || '';

      // Store the city, state, and country in your state or do something else with this information
      setnativeCity(city);
      setnativeState(state);
      setnativeCountry(country);
      let locationDetails = [city, state, country].filter(Boolean).join(', ');
      setNativeAddress(locationDetails);
      console.error('location details of native', locationDetails);

      // Use latLng if needed
    } catch (error) {
      console.error('Error selecting address:', error);
      setNativeAddress(''); // Reset to empty string on error
    }
  };

  // Helper function to get formatted location details
  const getLocationDetails = (addressComponents) => {
    const city = addressComponents.find(c => c.types.includes('locality'))?.long_name || '';
    const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '';
    const country = addressComponents.find(c => c.types.includes('country'))?.long_name || '';
    
    return [city, state, country].filter(Boolean).join(', ');
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      memberuuid: memberID,
      currentlocationid: '',
      currentlocationname:'',
      nativelocationid: '',
      nativelocationname:'',
      mothertongue: '',
      otherlang1: '',
      otherlang2: '',
      currentcity: '',
      currentstate: '',
      currentcountry: '',
      nativecity: '',
      nativestate: '',
      nativecountry: ''
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        // Prepare form data
        const formData = {
          ...values,
          mothertongue: parseInt(document.getElementById('mothertongue').value),
          otherlang1: parseInt(document.getElementById('otherlang1').value),
          otherlang2: parseInt(document.getElementById('otherlang2').value),
          currentlocationid: currentmapvalue || '',
          currentlocationname: currentAddress,
          nativelocationid: nativemapvalue || '',
          nativelocationname: nativeAddress,
          currentcity,
          currentstate, 
          currentcountry,
          nativecity,
          nativestate,
          nativecountry
        };

        const response = await callApi(() =>
          regionalService.updateRegionalDetails(formData)
        );

        setSuccess({
          title: 'SUCCESS!',
          message: response.message,
        });

        await fetchRegionalData();
      } catch (error) {
        setError({
          title: 'An Error Occurred!',
          message: error.response?.data?.message || 'Failed to update regional details'
        });
      } finally {
        setIsLoading(false);
      }
    }
  });

  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setMotherTongue({});
    setOtherlang1({});
    setOtherlang2({});
  };
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      {
        isLoading ? <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Row>
                <Col md={12} className='mt-3'>
                  <img src={locationIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Locality</span>
                  <Row className="mt-3">
                    <Col md={4} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Current Location

                          </Label>
                          <PlacesAutocomplete
                            value={currentAddress}
                            onChange={handleCurrentAddressChange}
                            onSelect={handlecurrentAddressSelect}
                            className="form-control form_input"
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => {
                              const uniqueSuggestions = new Set();

                              return (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';

                                      const words = suggestion.description.split(',');
                                      const trimmedArray = words.map((str) => str.trim());
                                      const lastThreeWords = trimmedArray
                                        .slice(-3)
                                        .join(',');
                                      // Check if the suggestion is unique based on place_id
                                      if (!uniqueSuggestions.has(lastThreeWords)) {
                                        uniqueSuggestions.add(lastThreeWords);

                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                          : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };

                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{lastThreeWords}</span>
                                          </div>
                                        );
                                      } else {
                                        return null; // Skip rendering duplicate suggestions
                                      }
                                    })}
                                  </div>
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Native Location

                          </Label>
                          <PlacesAutocomplete
                            value={nativeAddress}
                            onChange={handlenativeAddressChange}
                            onSelect={handlenativeAddressSelect}
                            className="form-control form_input"
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => {
                              const uniqueSuggestions = new Set();

                              return (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const words = suggestion.description.split(',');
                                      const trimmedArray = words.map((str) => str.trim());
                                      const lastThreeWords = trimmedArray
                                        .slice(-3)
                                        .join(',');

                                      // Check if the suggestion is unique based on place_id
                                      if (!uniqueSuggestions.has(lastThreeWords)) {
                                        uniqueSuggestions.add(lastThreeWords);
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                          : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };

                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{lastThreeWords}</span>
                                          </div>
                                        );
                                      } else {
                                        return null; // Skip rendering duplicate suggestions
                                      }
                                    })}
                                  </div>
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>
                  <img src={languageIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Language</span>
                  <Row className="mt-3">
                    <Col md={3} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Mother Tongue

                          </Label>
                          <select
                            id="mothertongue"
                            className="form-select form-control form_Input"
                            value={motherTongue}
                            onChange={(e) => setMotherTongue(e.target.value)}
                          >
                            {(languageList || []).map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>

                    <Col md={3} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Other Language 1
                          </Label>
                          <select
                            id="otherlang1"
                            className="form-select form-control form_Input"
                            value={otherlang1}
                            onChange={(e) => setOtherlang1(e.target.value)}
                          >
                            {(languageList || []).map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>

                    <Col md={3} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Other Language 2
                          </Label>
                          <select
                            id="otherlang2"
                            className="form-select form-control form_Input"
                            value={otherlang2}
                            onChange={(e) => setOtherlang2(e.target.value)}
                          >
                            {(languageList || []).map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "
                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary mt-2 ms-2"
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment>
  );
};
export default RegionalFunction;
