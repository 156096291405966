import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { memberService } from '../../services/api';
import { useApi } from '../../hooks/useApi';
import AsMemberDetailsPage from './as_members/AsMemberDetailsPage';
import CmMemberDetailsPage from './cm_members/CmMemberDetailsPage';

const GenericMemberDetails = () => {
  const { memberuuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { locationUrl, returnPage, searchCriteria, memberConnectionStatus } = state || {};
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { callApi } = useApi();

  const fetchMemberDetails = async () => {
    try {
      const response = await callApi(() => 
        memberService.getFullDetails(memberuuid)
      );
      
      if (response.length === 0) {
        setIsErrorModalOpen(true);
      } else {
        setMember(response[0]);
      }
    } catch (error) {
      console.error('Error fetching member details:', error);
      setIsErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemberDetails();
  }, [memberuuid]);

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
    navigate(location.state?.locationUrl || '/');
  };

  if (loading) {
    return (
      <div className="text-center py-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!member) {
    return (
      <div className="text-center py-5">
        <p>Member not found</p>
      </div>
    );
  }

  if (member.membertypeid === 1) {
    return (
      <AsMemberDetailsPage
        member={member}
        locationUrl={locationUrl}
        returnPage={returnPage}
        searchCriteria={searchCriteria}
        memberConnectionStatus={memberConnectionStatus}
      />
    );
  } else if (member.membertypeid === 4) {
    return (
      <CmMemberDetailsPage
        member={member}
        locationUrl={locationUrl}
        returnPage={returnPage}
        searchCriteria={searchCriteria}
        memberConnectionStatus={memberConnectionStatus}
      />
    );
  } else {
    return (
      <div className="text-center py-5">
        <p>Unknown member type</p>
      </div>
    );
  }
};

export default GenericMemberDetails;
