import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Profilecard from '../Home/Profile_card.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PendingReferralFunction from './connection/PendingReferral.jsx';
import PendingConnections from './connection/PendingConnections.jsx';
import MyReferralsFunction from './connection/myReferral.jsx';
import MyConnectionsFunction from './connection/myConnection.jsx';
// New work starts here
import NewMyConnections from './connection/NewMyConnections.js';
import { FaClock, FaFlag, FaBell, FaUsers, FaHeart } from 'react-icons/fa';

import FavouriteListFunction from './connection/FavouriteListing.jsx';

import { Container, Row, Col } from 'reactstrap';
const ConnectionsFunction = () => {
  const [isVisible, setPartnerPrefVisibility] = useState('pendingReferral');
  const [activeLink, setActiveLink] = useState('pendingReferral');

  // Updated menu data with FontAwesome icons at size=20
  const menudata = [
    {
      icon: <FaClock size={20} />,
      menuName: 'Pending Referrals',
      value: 'pendingReferral',
      badge: 0,
    },
    {
      icon: <FaFlag size={20} />,
      menuName: 'My Referral',
      value: 'myReferral',
      badge: 0,
    },
    {
      icon: <FaBell size={20} />,
      menuName: 'Pending Connections',
      value: 'pendingConnects',
      badge: 0,
    },
    {
      icon: <FaUsers size={20} />,
      menuName: 'My Connections',
      value: 'myConnections',
      badge: 0,
    },
    {
      icon: <FaHeart size={20} style={{ color: 'red' }} />,
      menuName: 'My Favorites',
      value: 'myFavourites',
      badge: 0,
    },
  ];

  useEffect(() => {}, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setPartnerPrefVisibility(link);
  };

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
  };

  const menuslider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // Show 5 items at a time
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* Header Section */}
          <Row className="">
            <Col>
              <p className="text-center fs-5 text-dark">
                Discover and Manage your own Network 
              </p>
            </Col>
          </Row>
            <Row>
              <Col md={12} xs={12} className="mb-3">
                {/* Replace the Slider with new Tab navigation */}
                
                  <div className="tabs-wrapper">
                    {menudata.map((item) => (
                      <button
                        key={item.value}
                        className={`tab-button ${activeLink === item.value ? 'active' : ''}`}
                        onClick={() => handleLinkClick(item.value)}
                      >
                        {item.icon}
                        <span className="tab-text">{item.menuName}</span>
                        {item.badge > 0 && (
                          <span className="tab-badge">{item.badge}</span>
                        )}
                      </button>
                    ))}
                  </div>
                
              </Col>

              {isVisible == 'pendingReferral' && (
                <Col
                  md={12}
                  xs={12}
                  style={{
                    transition: 'right 1s ease-in-out',
                    padding: '0',
                  }}
                >
                  {' '}
                  <PendingReferralFunction />
                </Col>
              )}
              {isVisible == 'myReferral' && (
                <Col
                  md={12}
                  xs={12}
                  style={{
                    transition: 'right 1s ease-in-out',
                    padding: '0',
                  }}
                >
                  <MyReferralsFunction />
                </Col>
              )}
              {isVisible == 'pendingConnects' && (
                <Col
                  md={12}
                  xs={12}
                  style={{
                    transition: 'right 1s ease-in-out',
                    padding: '0',
                  }}
                >
                  <PendingConnections />
                </Col>
              )}
              {isVisible == 'myConnections' && (
                <Col md={12} xs={12}>
                  <NewMyConnections />
                </Col>
              )}
              {isVisible == 'myFavourites' && (
                <Col md={12} xs={12}>
                  <FavouriteListFunction />
                </Col>
              )}
            </Row>
        </Container>
      </section>

      <style>
        {`
          .tabs-wrapper {
            display: flex;
            gap: 0.5rem;
            overflow-x: auto;
            scrollbar-width: none;
            -webkit-overflow-scrolling: touch;
            padding: 0.5rem;
            border: 2px solid #cfe2ff; /* Soft-primary border */
            border-radius: 8px;
            background: #fff;
          }

          .tabs-wrapper::-webkit-scrollbar {
            display: none;
          }

          .connection-tabs {
            background: #fff;
            border-radius: 12px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            padding: 0.5rem;
            margin-bottom: 2rem;
          }

          .tab-button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.75rem 1.25rem;
            border: none;
            background: transparent;
            border-radius: 4px;
            color: #64748b;
            font-weight: 500;
            white-space: nowrap;
            transition: all 0.3s ease;
            position: relative;
            border-right: 1px solid #e2e8f0; /* Separator */
          }

          .tab-button:last-child {
            border-right: none; /* No border on last child */
          }

          .tab-button:hover {
            background: #f1f5f9;
            color: #0f172a;
          }

          .tab-button.active {
            background: linear-gradient(45deg, hsl(237, 88%, 49%), rgb(229, 7, 163));
            color: white;
          }

          .tab-text {
            font-size: 1rem; /* Increased font size to fs-6 */
            font-weight: 600;
            letter-spacing: 0.3px;
          }

          .tab-badge {
            position: absolute;
            top: -5px;
            right: -5px;
            background: #ef4444;
            color: white;
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
            border-radius: 999px;
            min-width: 20px;
            text-align: center;
          }

          @media (max-width: 768px) {
            .tabs-wrapper {
              padding: 0.25rem;
            }

            .tab-button {
              padding: 0.5rem 0.75rem;
              flex-direction: column;
              gap: 0.5rem;
              text-align: center;
            }

            .tab-text {
              font-size: 0.75rem;
            }

            .tab-icon {
              width: 20px;
              height: 20px;
            }
          }

          @media (max-width: 480px) {
            .connection-tabs {
              margin: 0 -1rem;
              border-radius: 0;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default ConnectionsFunction;
