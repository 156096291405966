import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

import {
  Container,
  Form,
  Modal,
  ModalBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  CardImg,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';
import apiUrl from '../../apppages/server';
import axios from 'axios';
import mentorIcon from '../../assets/images/mentorship/mentor-icon-1.png';
import menteeIcon from '../../assets/images/mentorship/mentee-icon.png';
import serviceProviderIcon from '../../assets/images/mentorship/service-provider-icon.png';
//Import images
import MMlogo from '../../assets/images/mettamate/logo.svg';
import withRouter from '../../common/data/withRouter';

import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';

import accountSetting from '../../assets/images/settings (1).png';
import profile from '../../assets/images/user (1).png';
import networknavicon from '../../assets/images/event/network_icon.png';
import eventnavicon from '../../assets/images/event/event_icon.png';
import booknavicon from '../../assets/images/event/Books-icon.png';
import levelupnavicon from '../../assets/images/event/level-up.png';
import jobIcon from '../../assets/images/job/job-icon.png';
import eventIcon from '../../assets/images/event/eventIcon.png';
import blogIcon from '../../assets/images/Newemoji/Blogsblue.png';
import CommonModal from '../../apppages/Home/modal';
import logout from '../../assets/images/logout.png';
import { logoutFunction } from '../../apppages/Home/logout';
import '../../apppages/Home/css/style.css';
import {
  navLinks,
  sessionnavLinks,
  communityMsessionnavLinks,
  keyConstantMenus,
} from './menu';
import { authService } from '../../services/api';
import { useApi } from '../../hooks/useApi';

// Create HOC to provide useApi to class component
const withApi = (WrappedComponent) => {
  return function WithApiComponent(props) {
    const api = useApi();
    return <WrappedComponent {...props} api={api} />;
  };
};

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalMessage: false,
      showWorldDropdown: false,
      isMobile: window.innerWidth <= 768,
      navlink: '',
      isOpen: false,
      dropdownOpenShop: false,
      wishlistModal: false,
      dropdownIsOpen: false,
      open: false,
      position: 'end',
      dropdownOpen: false,
      landing: false,
      components: false,
      demo: false,
      doc: false,
      pages: false,
      company: false,
      account: false,
      email: false,
      blog: false,
      case: false,
      auth: false,
      login: false,
      signup: false,
      reset: false,
      utility: false,
      special: false,
      contact: false,
      multi: false,
      level2: false,
      isOffcanvasOpen: false,
      isLoggedIn: false,
      memberTypeCheck: 0,
      configurationData: {
        memberuuid: '',
        firstname: '',
        lastname: '',
        gendercode: 0,
        memberstatuscode: 0,
        memberstatusname: '',
        gendername: '',
        photopath1: '',
        photopath2: '',
        photopath3: '',
        subscription: '',
        jobprofession: '',
        linkedin: '',
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        snapchat: '',
        recommended: 0,
        membertypeid: '',
        activeMenuId: null,
      },
      profilePhoto: localStorage.getItem('profilePicture'),
      showMentorshipDropdown: false,
    };
    this.wrapperRef = createRef();
    this.myworldRef = createRef();
    this.mentorshipRef = createRef();

    this.toggleLine = this.toggleLine.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.toggleDropdown.bind(this);
    this.togglemodal.bind(this);
    this.removeActivation = this.removeActivation.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state.configurationData = JSON.stringify(
      localStorage.getItem('token'),
    );
    this.state.isLoggedIn = localStorage.getItem('token') ? true : false;
  }

  /**
   * Right sidebar drawer
   **/

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  closeOffcanvas = () => {
    this.setState({ isOffcanvasOpen: false });
  };

  toggleRightDrawer = () => {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  };
  onDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  // Topbar Page Activation

  activateParentDropdown() {
    var menuItems = document.getElementsByClassName('sub-menu-item');

    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].pathname === window.location.pathname) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');

        const immediateParent = matchingMenuItem.closest('li');
        if (immediateParent) {
          immediateParent.classList.add('active');
        }
        var parent = matchingMenuItem.closest('.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          var parentMenuitem = parent.querySelector('a');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }
          var parentOfParent = parent.closest('.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          parentOfParent = matchingMenuItem.closest('.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
      return false;
    }
    return false;
  }

  // UNSAFE_componentWillMount() {
  //   this.setState({
  //     profilePhoto: localStorage.getItem('profilePicture'),
  //   });
  //   document.removeEventListener('mousedown', this.handleClickOutside, true);
  // }

  componentDidUpdate(prevProps, prevState) {
    // window.scrollTo(0, 0);
    if (prevState.isMobile !== this.state.isMobile) {
      this.initializeNavigation();
    }
    /*
    const loggedInUserData = localStorage.getItem('configurationData');
    if (!loggedInUserData) {
      localStorage.clear();
      // window.location.href = '/';// Redirect to the main index page
    }
    */

    if (
      prevState.isLoggedIn !== (localStorage.getItem('token') ? true : false)
    ) {
      this.setState({
        isLoggedIn: localStorage.getItem('token') ? true : false,
        configurationData: JSON.parse(
          localStorage.getItem('configurationData'),
        ),
      });
    }

    if (this.state.profilePhoto != localStorage.getItem('profilePicture')) {
      this.setState({
        profilePhoto: localStorage.getItem('profilePicture'),
      });
    }
    //set underline to home page
    if (this.state.isLoggedIn) {
      if (this.state.navlink == '' || prevState.navlink == '') {
        this.updateNavlink(this.props.router.location.pathname);
      }
    }
    // this.authenticateToken()
  }

  // initializeNavigation = () => {
  //   const pathName = window.location.pathname;
  //   var matchingMenuItem = null;
  //   var ul = document.getElementById('navigation');
  //   var items = ul.getElementsByTagName('a');
  //   this.removeActivation(items);
  //   for (var i = 0; i < items.length; ++i) {
  //     if (pathName === items[i].pathname) {
  //       matchingMenuItem = items[i];
  //       break;
  //     }
  //   }
  //   if (matchingMenuItem) {
  //     this.activateParentDropdown(matchingMenuItem);
  //   }
  // };

  initializeNavigation = () => {
    const navigation = document.getElementById('navigation');
    if (navigation) {
      const navItems = navigation.getElementsByTagName('li');
      Array.from(navItems).forEach((item) => {
        if (item.querySelector('ul')) {
          item.classList.add('has-submenu');
          item.onclick = (event) => {
            event.stopPropagation();
            if (item.classList.contains('open')) {
              item.classList.remove('open');
            } else {
              document.querySelectorAll('.open').forEach((openItem) => {
                openItem.classList.remove('open');
              });
              item.classList.add('open');
            }
          };
        }
      });
    }
  };

  removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        if (parent.classList.contains('active')) {
          parent.classList.remove('active');
        }
      }
    }
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token && localStorage.getItem('configurationData')) {
      // first check token validity(expired or not)
      this.setState({ isLoggedIn: true });
      const configurationData = JSON.parse(
        localStorage.getItem('configurationData'),
      );
      if (
        configurationData &&
        configurationData.memberuuid !== this.state.configurationData.memberuuid
      ) {
        this.setState({ configurationData: configurationData });
      }
    } else {
      localStorage.clear();
    }
    this.initializeNavigation();
    const storedActiveMenuId = localStorage.getItem('activeMenuId');
    if (this.state.isLoggedIn && storedActiveMenuId) {
      this.setState({ activeMenuId: parseInt(storedActiveMenuId, 10) });
    } else {
      this.setState({ activeMenuId: null });
    }

    window.addEventListener('resize', this.handleResize);
    document.addEventListener('click', this.handleClickOutside, true);
  }

  handleMenuClick = (itemId) => {
    this.setState({ activeMenuId: itemId }, () => {
      localStorage.setItem('activeMenuId', itemId);
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  toggleLine = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleClickOutside = (event) => {
    // Only handle outside clicks if the menu is open
    if (
      this.state.showProfileDropdown &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) &&
      !event.target.closest('button')
    ) {
      // Ignore clicks on the button
      this.setState({ showProfileDropdown: false });
    }
    if (
      this.myworldRef.current &&
      !this.myworldRef.current.contains(event.target)
    ) {
      this.setState({ showWorldDropdown: false });
    }
    if (
      this.mentorshipRef.current &&
      !this.mentorshipRef.current.contains(event.target)
    ) {
      this.setState({ showMentorshipDropdown: false });
    }
  };

  logout = async () => {
    try {
      const { api } = this.props;
      await api.callApi(() =>
        authService.signout(localStorage.getItem('memberID')),
      );
      localStorage.clear();
      this.setState({ isLoggedIn: false, sessionnavLinks: [], navlink: '' });
      window.location.href = '/user-login-page';
    } catch (error) {
      console.error('Logout failed:', error);
      // Still clear local storage and redirect on error
      localStorage.clear();
      window.location.href = '/user-login-page';
    }
  };

  authenticateToken = async () => {
    try {
      const { api } = this.props;
      await api.callApi(() => authService.authenticateToken());
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.clear();
        this.setState({ isLoggedIn: false, sessionnavLinks: [], navlink: '' });
        logoutFunction();
      }
    }
  };

  displayImage = () => {
    if (this.state.profilePhoto != 'undefined') {
      if (this.state.configurationData.gendername == 'Male') {
        return manavatar;
      } else if (this.state.configurationData.gendername == 'Female') {
        return femaleavatar;
      } else if (this.state.configurationData.gendername == 'Other') {
        return transgenderavatar;
      }
    } else {
      return this.state.profilePhoto;
    }
  };

  login = async () => {
    this.setState({ isLoggedIn: true, sessionnavLinks: sessionnavLinks });
  };
  updateNavlink = (data) => {
    this.setState({ navlink: data });
  };

  redirectHome = () => {
    if (this.state.isLoggedIn && this.state.configurationData) {
      const link =
        this.state.configurationData.membertypeid == 4
          ? '/cmmembers'
          : '/asmembers';
      this.updateNavlink(link);
    } else {
      this.updateNavlink('/');
    }
  };

  getTargetURL = () => {
    if (this.state.isLoggedIn && this.state.configurationData) {
      if (this.state.configurationData.membertypeid == 4) {
        return '/cmmembers';
      }
      return '/asmembers';
    }
    return '/';
  };

  renderChilds = (childElements) => {
    return childElements.map((item, index) => <li key={index}>{item}</li>);
  };

  // Add this new method before getMenuItems
  renderMyWorldMenu = (item) => {
    if (this.state.isMobile) {
      return (
        <>
          <Link
            to={item.link}
            className={item.linkClassName}
            onClick={this.toggleWorldDropdown}
          >
            {item.imgSrc && (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={item.imgSrc}
                  alt={item.title}
                  className={item.imgSrcClassName}
                  style={{ height: '27px', marginBottom: '3px' }}
                />
              </div>
            )}
            <span
              className={`${
                this.state.activeMenuId === item.id ? 'active-menu' : ''
              }`}
              style={{ fontSize: '12px', color: '#0d6efd' }}
            >
              {item.title}
            </span>
            <i>
              <FeatherIcon icon="chevron-down" className="fea icon-sm uil" />
            </i>
          </Link>
          {this.state.showWorldDropdown && this.renderWorldDropdown(item)}
        </>
      );
    }

    return (
      <>
        <Link
          to={item.link}
          className={item.linkClassName}
          style={{ minHeight: '18px' }}
          onClick={() => {
            this.toggleWorldDropdown();
            this.setState({ activeMenuId: item.id });
          }}
        >
          {item.imgSrc && (
            <div style={{ textAlign: 'center' }}>
              <img
                src={item.imgSrc}
                alt={item.title}
                className="desktop-icon-size"
              />
            </div>
          )}
          <span
            className={`${
              this.state.activeMenuId === item.id ? 'active-menu' : ''
            }`}
          >
            {item.title}
            <i>
              <FeatherIcon icon="chevron-down" className="fea icon-sm uil" />
            </i>
          </span>
        </Link>
        {this.state.showWorldDropdown && this.renderWorldDropdown(item)}
      </>
    );
  };

  renderMentorshipMenu = (item) => {
    if (this.state.isMobile) {
      return (
        <>
          <Link
            to={item.link}
            className={item.linkClassName}
            onClick={this.toggleMentorshipDropdown}
          >
            {item.imgSrc && (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={item.imgSrc}
                  alt={item.title}
                  className={item.imgSrcClassName}
                  style={{ height: '27px', marginBottom: '3px' }}
                />
              </div>
            )}
            <span
              className={`${
                this.state.activeMenuId === item.id ? 'active-menu' : ''
              }`}
              style={{ fontSize: '12px', color: '#0d6efd' }}
            >
              {item.title}
            </span>
            <i>
              <FeatherIcon icon="chevron-down" className="fea icon-sm uil" />
            </i>
          </Link>
          {this.state.showMentorshipDropdown && this.renderMentorshipDropdown()}
        </>
      );
    }

    return (
      <>
        <Link
          to={item.link}
          className={item.linkClassName}
          style={{ minHeight: '18px' }}
          onClick={() => {
            this.toggleMentorshipDropdown();
            this.setState({ activeMenuId: item.id });
          }}
        >
          {item.imgSrc && (
            <div style={{ textAlign: 'center' }}>
              <img
                src={item.imgSrc}
                alt={item.title}
                className="desktop-icon-size"
              />
            </div>
          )}
          <span
            className={`${
              this.state.activeMenuId === item.id ? 'active-menu' : ''
            }`}
          >
            {item.title}
            <i>
              <FeatherIcon icon="chevron-down" className="fea icon-sm uil" />
            </i>
          </span>
        </Link>
        {this.state.showMentorshipDropdown && this.renderMentorshipDropdown()}
      </>
    );
  };

  renderMentorshipDropdown = () => {
    const dropdownStyle = this.state.isMobile
      ? {
          position: 'fixed',
          top: 'calc(95% - 200px)',
          left: '80px',
          maxHeight: '200px',
          overflowY: 'auto',
          backgroundColor: 'white',
          zIndex: 2000,
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        }
      : {
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'white',
          zIndex: 2000,
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          marginTop: '10px',
          borderRadius: '8px',
          minWidth: '200px',
        };

    const menuItemStyle = {
      display: 'block',
      padding: '10px',
      fontSize: '14px',
      color: '#0d6efd',
      textTransform: 'uppercase',
      fontWeight: '500',
      letterSpacing: '0.5px',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    };

    return (
      <div
        className="section-dropdown"
        style={dropdownStyle}
        ref={this.mentorshipRef}
        onClick={() => this.setState({ showMentorshipDropdown: false })}
      >
        <Link
          to="/mentorship-page"
          className="profile_dropdown_menu_li1"
          style={menuItemStyle}
        >
          <img
            src={mentorIcon}
            height={20}
            style={{ verticalAlign: 'middle' }}
          />{' '}
          &nbsp;&nbsp;Explore Mentors
        </Link>
        <Link
          to="/mentees-page"
          className="profile_dropdown_menu_li1"
          style={menuItemStyle}
        >
          <img
            src={menteeIcon}
            height={20}
            style={{ verticalAlign: 'middle' }}
          />{' '}
          &nbsp;&nbsp;Explore Mentees
        </Link>
      </div>
    );
  };

  getMenuItems = () => {
    // let collectedMenus = navLinks;
    // if (this.state.isLoggedIn) {
    //   if (this.state.configurationData.membertypeid == 4) {
    //     collectedMenus = communityMsessionnavLinks;
    //   } else {
    //     collectedMenus = sessionnavLinks;
    //   }
    // }

    let collectedMenus = navLinks; // Default to navLinks

    // Check if the current page is UserLoginPage or /index
    const isSpecialPage = ['/user-login-page', '/index'].includes(
      window.location.pathname,
    );

    if (!isSpecialPage) {
      if (this.state.isLoggedIn) {
        if (this.state.configurationData.membertypeid == 4) {
          collectedMenus = communityMsessionnavLinks;
        } else {
          collectedMenus = sessionnavLinks;
        }
      }
    }

    return collectedMenus.map((item, key) => {
      const hasChildren = item.child && item.child.length;

      return (
        <li
          key={key}
          className={`list-inline-item me-2 pt-3 mb-2  ${
            this.state.showWorldDropdown || this.state.showMentorshipDropdown
              ? 'sec-center'
              : ''
          }`}
          title={item.title}
          style={{ listStyle: 'none' }} // Ensure no bullet point
        >
          {item.id === 5 ? (
            this.renderMyWorldMenu(item)
          ) : item.isMentorship ? (
            this.renderMentorshipMenu(item)
          ) : (
            <Link
              to={item.link}
              className={item.linkClassName}
              style={{ minHeight: '18px' }}
              onClick={() => this.handleMenuClick(item.id)}
            >
              {item.imgSrc && (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={item.imgSrc}
                    alt={item.title}
                    className="desktop-icon-size"
                  />
                </div>
              )}
              <span
                className={`desktop-icon ${
                  this.state.activeMenuId === item.id ? 'active-menu' : ''
                }`}
              >
                {item.title}
              </span>{' '}
              {/* Smaller font size */}
            </Link>
          )}
        </li>
      );
    });
  };

  reDirectToLogin = () => {
    return (
      <div>
        <Navigate to="/subscriptionPlan" />
      </div>
    );
  };

  getProfileImage = (imageSourcePath) => {
    const defaultImage =
      this.state.configurationData.gendername === 'Female'
        ? femaleavatar
        : manavatar;

    return (
      <CardImg
        className="rounded-lg mt-2"
        src={imageSourcePath || defaultImage}
        style={{
          objectFit: 'cover', // Changed from contain to cover
          width: '52px', // Increased from 45px
          height: '52px', // Increased from 45px
          borderRadius: '50%',
          border: '2px solid #3B82F6', // Added border
          boxShadow: '0 2px 8px rgba(59, 130, 246, 0.25)', // Added shadow
        }}
        onError={(e) => {
          e.target.src = defaultImage;
          e.target.style.width = '52px'; // Keep consistent size on error
          e.target.style.height = '52px';
        }}
        alt="ProfilePic"
      />
    );
  };

  toggleProfileDropdown = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState((prevState) => ({
      showProfileDropdown: !prevState.showProfileDropdown,
    }));
  };
  toggleWorldDropdown = () => {
    console.log(this.showWorldDropdown);

    this.setState((prevState) => ({
      showWorldDropdown: !prevState.showWorldDropdown,
    }));
  };

  toggleMentorshipDropdown = () => {
    this.setState((prevState) => ({
      showMentorshipDropdown: !prevState.showMentorshipDropdown,
    }));
  };

  renderWorldDropdown = () => {
    const dropdownStyle = this.state.isMobile
      ? {
          position: 'fixed',
          top: 'calc(95% - 200px)',
          left: '80px',
          maxHeight: '200px',
          overflowY: 'auto',
          backgroundColor: 'white',
          zIndex: 2000,
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        }
      : {
          position: 'absolute',
          top: '100%', // Changed from 80px to 100%
          left: '50%', // Center align with parent
          transform: 'translateX(-50%)', // Center align with parent
          backgroundColor: 'white',
          zIndex: 2000,
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          marginTop: '10px', // Add small gap from parent
          borderRadius: '8px', // Optional: adds rounded corners
          minWidth: '200px', // Ensure minimum width
        };

    const menuItemStyle = {
      display: 'block',
      padding: '10px',
      fontSize: '14px',
      color: '#0d6efd', // Match the blue color
      textTransform: 'uppercase', // Make text uppercase
      fontWeight: '500', // Add medium font weight
      letterSpacing: '0.5px', // Add letter spacing
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    };

    return (
      <div
        className="section-dropdown"
        style={dropdownStyle}
        ref={this.myworldRef}
        onClick={() => this.setState({ showWorldDropdown: false })}
      >
        <Link
          to="/connections"
          className="profile_dropdown_menu_li1"
          style={menuItemStyle}
        >
          <img
            src={networknavicon}
            height={20}
            style={{ verticalAlign: 'middle' }}
          />{' '}
          &nbsp;&nbsp;My Network
        </Link>
        <Link
          to="/event-listing-page"
          className="profile_dropdown_menu_li1"
          style={menuItemStyle}
        >
          <img
            src={eventnavicon}
            height={20}
            style={{ verticalAlign: 'middle' }}
          />{' '}
          &nbsp;&nbsp;Events
        </Link>
        <Link
          onClick={() =>
            this.setState({
              modalMessage: {
                title: 'Stay Tune!',
                message:
                  'Upcoming Feature to help you grow in personal and professional Life!',
              },
            })
          }
          className="profile_dropdown_menu_li1"
          style={menuItemStyle}
        >
          <img
            src={levelupnavicon}
            height={20}
            style={{ verticalAlign: 'middle' }}
          />{' '}
          &nbsp;&nbsp;Self Help
        </Link>
        <Link
          onClick={() =>
            this.setState({
              modalMessage: {
                title: 'Stay Tune!',
                message:
                  'Upcoming Feature to help you grow in personal and professional Life!',
              },
            })
          }
          className="profile_dropdown_menu_li1"
          style={menuItemStyle}
        >
          <img
            src={booknavicon}
            height={20}
            style={{ verticalAlign: 'middle' }}
          />{' '}
          &nbsp;&nbsp;Books
        </Link>
      </div>
    );
  };

  getAfterLoginKeyMenus = () => {
    return [
      <li
        className="list-inline-item"
        key="2"
        ref={(node) => {
          this.dropdownMenuRef = node;
        }}
      >
        <div>
          <div className="nav-link p-0 d-flex" style={{ cursor: 'pointer' }}>
            <Link
              to="/my-profile-edit-page"
              className="profile-pic me-1 profilegroup1"
              style={{ borderRadius: '50%', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.location.href = '/my-profile-edit-page';
              }}
            >
              {this.getProfileImage(this.state.profilePhoto)}
            </Link>
            <div>
              <button
                className="d-none d-md-block premium-menu-button"
                onClick={this.toggleProfileDropdown}
                style={{
                  border: 'none',
                  background: 'linear-gradient(145deg, #2062C4, #3B82F6)',
                  padding: '14px 18px', // Increased padding
                  borderRadius: '12px',
                  boxShadow: '0 8px 20px rgba(102, 126, 234, 0.25)',
                  cursor: 'pointer',
                  marginTop: '12px', // Adjusted margin
                  marginLeft: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.3s ease',
                  minWidth: '50px', // Increased width
                  position: 'relative',
                  zIndex: 1001,
                  transform: this.state.showProfileDropdown
                    ? 'scale(0.95)'
                    : 'scale(1)',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow =
                    '0 10px 25px rgba(102, 126, 234, 0.35)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = this.state
                    .showProfileDropdown
                    ? 'scale(0.95)'
                    : 'scale(1)';
                  e.currentTarget.style.boxShadow =
                    '0 8px 20px rgba(102, 126, 234, 0.25)';
                }}
              >
                <i
                  className="fas fa-bars"
                  style={{
                    fontSize: '22px',
                    color: '#ffffff',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.3))',
                    transition: 'transform 0.3s ease',
                    transform: this.state.showProfileDropdown
                      ? 'rotate(90deg)'
                      : 'none',
                  }}
                  title="Menu"
                ></i>
              </button>
            </div>
          </div>

          {this.state.showProfileDropdown && (
            <ul
              className="dropdown-menu profile_dropdown_menu"
              style={{
                display: 'block',
                position: 'absolute',
                top: '80px',
                right: '2px',
                whiteSpace: 'normal',
                overflow: 'visible',
                minWidth: '250px',
                zIndex: 1000,
                background: 'white',
                borderRadius: '16px',
                boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                border: '1px solid rgba(102, 126, 234, 0.1)',
                animation: 'fadeIn 0.2s ease-out',
              }}
              ref={this.wrapperRef}
            >
              <div className="role-menu">
                <div className="role-header">
                  <span className="menu-section-title">
                    Play A Role @ MettaMate
                  </span>
                </div>
                <hr className="my-2" />
                <div className="role-options">
                  <Link
                    to="/self-mentor-page"
                    className="role-option"
                    onClick={() =>
                      this.setState({ showProfileDropdown: false })
                    }
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={mentorIcon}
                        alt="Mentor"
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                      <span>Mentor</span>
                    </div>
                  </Link>
                  <Link
                    to="/self-mentee-page"
                    className="role-option"
                    onClick={() =>
                      this.setState({ showProfileDropdown: false })
                    }
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={menteeIcon}
                        alt="Mentee"
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                      <span>Mentee</span>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="role-option"
                    onClick={() =>
                      this.setState({ showProfileDropdown: false })
                    }
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={serviceProviderIcon}
                        alt="Service Provider"
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                      <span>Service Provider</span>
                    </div>
                  </Link>
                </div>
                <hr className="my-2" />
              </div>
              <div className="role-header py-2 px-1">
                <span className="fw-medium text-dark">Menu</span>
              </div>
              <hr className="my-2" />
              <Link
                to="/connections"
                className="role-option"
                onClick={() => this.setState({ showProfileDropdown: false })}
              >
                <div className="d-flex align-items-center p-2">
                  <img
                    src={networknavicon}
                    alt="My Network"
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                  <span>My Network</span>
                </div>
              </Link>
              <Link
                to="/job-listing"
                className="role-option"
                onClick={() => this.setState({ showProfileDropdown: false })}
              >
                <div className="d-flex align-items-center p-2">
                  <img
                    src={jobIcon}
                    alt="Jobs"
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                  <span>Jobs</span>
                </div>
              </Link>
              <Link
                to="/event-listing-page"
                className="role-option"
                onClick={() => this.setState({ showProfileDropdown: false })}
              >
                <div className="d-flex align-items-center p-2">
                  <img
                    src={eventIcon}
                    alt="Events"
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                  <span>Events</span>
                </div>
              </Link>
              <Link
                to="/blogs"
                className="role-option"
                onClick={() => this.setState({ showProfileDropdown: false })}
              >
                <div className="d-flex align-items-center p-2">
                  <img
                    src={blogIcon}
                    alt="blogs"
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                  <span>Blogs</span>
                </div>
              </Link>
              <Link
                to="/mentorship-page"
                className="role-option"
                onClick={() => this.setState({ showProfileDropdown: false })}
              >
                <div className="d-flex align-items-center p-2">
                  <img
                    src={mentorIcon}
                    alt="Explore Mentors"
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                  <span>Explore Mentors</span>
                </div>
              </Link>
              <Link
                to="/mentees-page"
                className="role-option"
                onClick={() => this.setState({ showProfileDropdown: false })}
              >
                <div className="d-flex align-items-center p-2">
                  <img
                    src={menteeIcon}
                    alt="Explore Mentees"
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  />
                  <span>Explore Mentees</span>
                </div>
              </Link>
              <hr className="my-2" />
              <div className="role-header py-2 px-1">
                <span className="fw-medium text-dark">Setting</span>
              </div>
              <hr className="my-2" />
              <Link
                to="/settings"
                className="settings-option"
                onClick={() => this.setState({ showProfileDropdown: false })}
              >
                <div className="d-flex align-items-center p-2">
                  <i className="fas fa-cog me-2"></i>
                  <span>Account Settings</span>
                </div>
              </Link>
              <Link
                to="/user-login-page"
                onClick={(e) => {
                  this.setState({ showProfileDropdown: false }); // Close dropdown
                  this.logout(e); // Call logout
                }}
                className="logout-option"
              >
                <div className="d-flex align-items-center p-2 text-danger">
                  <i className="fas fa-sign-out-alt me-2"></i>
                  <span>Logout</span>
                </div>
              </Link>
            </ul>
          )}
        </div>
      </li>,
    ];
  };

  getKeyMenus = () => {
    //   if (this.state.isMobile) {
    //   return null;
    // }
    let keyMenus = keyConstantMenus;
    if (this.state.isLoggedIn) {
      return this.getAfterLoginKeyMenus();
    }
    return keyMenus.map((item, key) => {
      const hasChildren = item.child && item.child.length;
      return (
        <li className="list-inline-item me-2" title={item.title} key={key}>
          <Link
            style={item.styles}
            to={item.link}
            className={item.linkClassName}
          >
            <i>
              <FeatherIcon icon={item.icon} className="fea icon-sm " />
            </i>
            <span
              style={{ fontSize: `16px` }}
              className=" d-none d-md-block mx-1"
            ></span>
            {item.title}
          </Link>
        </li>
      );
    });
    return [];
  };

  render() {
    return <ul className="list-inline">{this.getKeyMenus()}</ul>;
  }

  getKeyMenus = () => {
    let keyMenus = keyConstantMenus;
    if (this.state.isLoggedIn) {
      return this.getAfterLoginKeyMenus();
    }
    if (this.props?.router?.location?.pathname == '/user-login-page') {
      keyMenus = keyMenus.filter((item) => item.id !== 1);
    }

    if (this.props?.router?.location?.pathname == '/user-registration-page') {
      keyMenus = keyMenus.filter((item) => item.id !== 2);
    }
    return keyMenus.map((item, key) => {
      const hasChildren = item.child && item.child.length;
      return (
        <li className="list-inline-item me-2" title={item.title} key={key}>
          <Link
            // style={{
            //   width: `100%`,
            //   padding: `0px 10px`,
            //   pointerEvents: 'auto',
            // }}
            style={item.styles}
            to={item.link}
            className={item.linkClassName}
          >
            <i>
              <FeatherIcon icon={item.icon} className="fea icon-sm " />
            </i>
            <span
              style={{ fontSize: `16px` }}
              className=" d-none d-md-block mx-1"
            >
              {item.title}
            </span>
          </Link>
        </li>
      );
    });
    return [];
  };

  /* Render the Given top-bar now */
  render() {
    const { isOffcanvasOpen } = this.state;
    const { isLoggedIn } = this.state;
    const { isMobile, isOpen, modalMessage } = this.state;
    return (
      <React.Fragment>
        <style>
          {`
            @keyframes fadeIn {
              from {
                opacity: 0;
                transform: translateY(-10px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }
            
            .role-option, .settings-option, .logout-option {
              transition: all 0.2s ease;
              display: block;
              text-decoration: none;
              color: #333;
            }
            
            .role-option:hover, .settings-option:hover {
              background: linear-gradient(135deg, #f6f8ff 0%, #f1f4ff 100%);
              transform: translateX(5px);
            }

            .logout-option:hover {
              background: linear-gradient(135deg, #fff5f5 0%, #ffe6e6 100%);
              transform: translateX(5px);
            }

            .menu-section-title {
              color: #667eea;
              font-weight: 600;
              font-size: 0.9rem;
            }
          `}
        </style>
        {this.state.modalMessage && (
          <CommonModal
            title={this.state.modalMessage.title}
            message={this.state.modalMessage.message}
            onClose={() => this.setState({ modalMessage: null })}
            status="warning"
          />
        )}
        {isMobile ? (
          <MobileHeader
            getTargetURL={this.getTargetURL}
            getKeyMenus={this.getKeyMenus}
            getMenuItems={this.getMenuItems}
            isLoggedIn={this.state.isLoggedIn} // Pass isLoggedIn state
            logout={this.logout}
            // isOpen={isOpen}
            // toggleLine={this.toggleLine}
          />
        ) : (
          <DesktopHeader
            getTargetURL={this.getTargetURL}
            getKeyMenus={this.getKeyMenus}
            getMenuItems={this.getMenuItems}
            // isOpen={isOpen}
            // toggleLine={this.toggleLine}
          />
        )}
      </React.Fragment>
    );
  }
}

// Export wrapped component with both withRouter and withApi
export default withRouter(withApi(Topbar));
