import React, { useState, useEffect, useRef } from 'react'; // Add useRef
import { useNavigate, useLocation } from 'react-router-dom'; // Add useLocation
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Select, message } from 'antd';
import {
  FaBriefcase,
  FaBuilding,
  FaMapMarkerAlt,
  FaMoneyBillWave,
  FaCalendarAlt,
  FaSuitcase,
  FaInfoCircle,
} from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import './JobPostForm.css'; // Add this import
import {
  fetchJobCategories,
  addJobPosting,
  editJobPosting,
} from './jobServices'; // Add this import
import { useApi } from '../../../hooks/useApi';

const { Option } = Select;
const iconStyle = { color: '#0D6EFD' };
const requiredStar = { color: '#dc3545' }; // Add red color for required field asterisks

// Add currency symbols mapping
const CURRENCY_BY_COUNTRY = {
  US: '$',
  GB: '£',
  IN: '₹',
  EU: '€',
  // Add more as needed
};

const JobPostForm = ({ jobData, onClose, onSubmitSuccess }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editingJob = location.state?.job;
  const isEditMode = !!jobData;
  const [loading, setLoading] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [eventAddress, setEventAddress] = useState('');
  const [addressComponents, setAddressComponents] = useState({
    city: '',
    state: '',
    country: '',
  });
  const quillRef = useRef(null);
  const [jobCategories, setJobCategories] = useState([]); // Add this state
    const { callApi } = useApi();

  // Add function to get email from configurationData
  const getEmailFromConfiguration = () => {
    try {
      const configData = localStorage.getItem('configurationData');
      if (configData) {
        const parsedData = JSON.parse(configData);
        return parsedData.emailid || '';
      }
      return '';
    } catch (error) {
      console.error('Error parsing configuration data:', error);
      return '';
    }
  };

  // Update initial form state
  const defaultExpiryDate = new Date();
  defaultExpiryDate.setDate(defaultExpiryDate.getDate() + 7);
  // alert("localStorage = " + JSON.stringify(localStorage));
  // alert("localStorage.getItem('emailid') = " + localStorage.getItem('emailid'));
  const [formData, setFormData] = useState({
    job_uuid: jobData?.job_uuid || '',
    company_name: jobData?.company_name || '',
    company_url: jobData?.company_url || '', // Add this line
    job_title: jobData?.job_title || '',
    description: jobData?.description || '',
    location_address: jobData?.location_address || '',
    place_id: jobData?.place_id || '',
    experience_required: jobData?.experience_required || '',
    skills_required: jobData?.skills_required || [],
    category_uuids: jobData?.category_uuids || [], // Changed to array for multiple selection
    employment_type: jobData?.employment_type || '',
    salary_range: jobData?.salary_range || '',
    contact_email: jobData?.contact_email || getEmailFromConfiguration(), // Update this line
    posting_url: jobData?.posting_url || '',
    expires_at:
      jobData?.expires_at || defaultExpiryDate.toISOString().split('T')[0],
    is_active: jobData?.is_active || true,
    created_at: jobData?.created_at || new Date().toISOString(),
    updated_at: jobData?.updated_at || new Date().toISOString(),
  });

  // Add useEffect to update email if localStorage changes
  useEffect(() => {
    const email = getEmailFromConfiguration();
    if (email && email !== formData.contact_email) {
      setFormData((prev) => ({
        ...prev,
        contact_email: email,
      }));
    }
  }, []);

  const employmentTypes = [
    'Full-time',
    'Part-time',
    'Contract',
    'Freelance',
    'Internship',
    'Government Full-time',
    'Government Contract',
  ];

  // Add useEffect to fetch categories
  useEffect(() => {
    const loadJobCategories = async () => {
      try {
        const categories = await fetchJobCategories(callApi);
        setJobCategories(categories);
      } catch (error) {
        message.error('Failed to load job categories');
        console.error('Error loading job categories:', error);
      }
    };

    loadJobCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSkillsChange = (value) => {
    setFormData((prev) => ({ ...prev, skills_required: value }));
  };

  // Modify category handler for single selection
  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData((prev) => ({ ...prev, category_uuids: selectedCategory }));
  };

  // Add employment type handler
  const handleEmploymentTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      employment_type: type,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const jobData = {
        ...formData,
        memberuuid: localStorage.getItem('memberID'),
        status: 'Active',
      };

      if (isEditMode) {
        await editJobPosting(callApi, jobData);
        message.success('Job updated successfully!');
      } else {
        await addJobPosting(callApi, jobData);
        message.success('Job posted successfully!');
      }

      onSubmitSuccess?.();
    } catch (error) {
      message.error(error.message || 'Failed to save job');
    } finally {
      setLoading(false);
    }
  };

  // Format date for display
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  // Style for section headings
  const sectionHeaderStyle = {
    fontSize: '1rem',
    fontWeight: '500',
    color: '#495057',
  };

  const titleHeaderStyle = {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: '#495057',
  };

  // Handle location selection
  const handleLocationSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const placeId = results[0].place_id;
      const addressComponents = results[0].address_components;

      // Get location components
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.short_name || '';

      // Update currency symbol based on country
      if (country && CURRENCY_BY_COUNTRY[country]) {
        setCurrencySymbol(CURRENCY_BY_COUNTRY[country]);
      }

      // Store full address components
      setAddressComponents({
        city,
        state,
        country,
      });

      setEventAddress(address);
      setFormData((prev) => ({
        ...prev,
        place_id: placeId,
        location_address: [city, state, country].filter(Boolean).join(', '),
      }));
    } catch (error) {
      console.error('Error selecting address:', error);
      message.error('Error selecting location');
    }
  };

  // Handle experience input
  const handleExperienceChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setFormData((prev) => ({
      ...prev,
      experience_required: value,
    }));
  };

  // Handle rich text editor
  const handleDescriptionChange = (content) => {
    setFormData((prev) => ({
      ...prev,
      description: content,
    }));
  };

  // Add Quill modules and formats configuration
  const quillModules = {
    toolbar: {
      container: [
        [{ header: ['1', '2', false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
      handlers: {
        // Add any custom handlers here
      },
    },
    clipboard: {
      matchVisual: false, // Prevents the deprecated mutation events
    },
  };

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
  ];

  // Add effect to handle Quill initialization
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.root.setAttribute('spellcheck', false);
    }
  }, []);

  return (
    <Card
      className="border-0 shadow-sm"
      style={{
        border: '2px solid #e8f3ff !important',
        borderRadius: '12px',
        overflow: 'hidden',
      }}
    >
      <div style={{ borderTop: '4px solid #0d6efd' }} />{' '}
      <CardBody className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <CardTitle tag="h4" style={titleHeaderStyle}>
            <FaSuitcase className="me-2 text-primary" />
            <span className="fs-4">
              {jobData ? 'Edit Your Job' : 'Post a New Job'}
            </span>
          </CardTitle>
          <Button
            color="light"
            size="sm"
            onClick={onClose}
            className="bg-soft-primary px-3 fs-6"
          >
            ← Back to Jobs
          </Button>
        </div>

        <Form onSubmit={handleSubmit}>
          {/* Company Details */}
          <div className="mb-4 p-4 bg-light rounded-3">
            <h5
              className="mb-3 d-flex align-items-center"
              style={sectionHeaderStyle}
            >
              <FaBuilding className="me-2 text-primary" />
              Company Information
            </h5>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Company Name <span style={requiredStar}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="company_name"
                    required
                    value={formData.company_name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Company URL</Label>
                  <Input
                    type="text"
                    name="company_url"
                    placeholder="e.g. https://www.company.com"
                    value={formData.company_url}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Contact Email <span style={requiredStar}>*</span>
                  </Label>
                  <Input
                    type="email"
                    name="contact_email"
                    required
                    value={formData.contact_email}
                    onChange={handleInputChange}
                    className="bg-light" // Optional: add visual indication that field is disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          {/* Job Details */}
          <div className="mb-4 p-4 bg-light rounded-3">
            <h5 className="mb-3 d-flex align-items-center">
              <FaBriefcase className="me-2 text-primary" />
              Job Details
            </h5>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Job Title <span style={requiredStar}>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="job_title"
                    required
                    value={formData.job_title}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Employment Type <span style={requiredStar}>*</span>
                  </Label>
                  <div className="d-flex flex-wrap gap-2">
                    {employmentTypes.map((type) => (
                      <Button
                        key={type}
                        color={
                          formData.employment_type === type
                            ? 'primary'
                            : 'light'
                        }
                        className="d-flex align-items-center gap-2 px-3 py-2 rounded-pill"
                        onClick={() => handleEmploymentTypeChange(type)}
                        style={{
                          whiteSpace: 'nowrap',
                          transition: 'all 0.3s ease',
                          borderColor:
                            formData.employment_type === type
                              ? 'transparent'
                              : '#edf2ff',
                          margin: '0.2rem',
                          fontSize: '0.875rem',
                          backgroundColor:
                            formData.employment_type === type
                              ? '#4263eb'
                              : '#edf2ff',
                          fontWeight:
                            formData.employment_type === type ? '500' : '400',
                          color:
                            formData.employment_type === type
                              ? '#fff'
                              : '#2b3044',
                        }}
                      >
                        <FaBriefcase
                          size={14}
                          style={{
                            opacity:
                              formData.employment_type === type ? 1 : 0.85,
                            color:
                              formData.employment_type === type
                                ? '#fff'
                                : '#2b3044',
                          }}
                        />
                        <span>{type}</span>
                      </Button>
                    ))}
                  </div>
                  {/* Add error message display if needed */}
                  {formData.employment_type === '' && (
                    <small className="text-danger">
                      Please select an employment type
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Job Category <span style={requiredStar}>*</span>
                  </Label>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select categories"
                    value={formData.category_uuids}
                    onChange={(value) =>
                      setFormData((prev) => ({
                        ...prev,
                        category_uuids: value,
                      }))
                    }
                    required
                    loading={!jobCategories.length} // Add loading state
                  >
                    {jobCategories.map((category) => (
                      <Option
                        key={category.category_uuid}
                        value={category.category_uuid}
                      >
                        {category.category_name}
                      </Option>
                    ))}
                  </Select>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="quill-container">
                  <Label>
                    Job Description <span style={requiredStar}>*</span>
                  </Label>
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={formData.description}
                    onChange={handleDescriptionChange}
                    modules={quillModules}
                    formats={quillFormats}
                    preserveWhitespace={true}
                    bounds=".quill-container"
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          {/* Requirements */}
          <div className="mb-4 p-4 bg-light rounded-3">
            <h5 className="mb-3 d-flex align-items-center">
              <FaCalendarAlt className="me-2 text-primary" />
              Requirements & Skills
            </h5>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Experience Required <span style={requiredStar}>*</span>
                  </Label>
                  <Input
                    type="number"
                    min="0"
                    name="experience_required"
                    value={formData.experience_required}
                    onChange={handleExperienceChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    Required Skills <span style={requiredStar}>*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="skills_required"
                    rows="3"
                    placeholder="List required skills (e.g., JavaScript, React, Node.js)"
                    value={formData.skills_required.join(', ')}
                    onChange={(e) =>
                      handleSkillsChange(
                        e.target.value.split(',').map((skill) => skill.trim()),
                      )
                    }
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          {/* Location & Compensation */}
          <div className="mb-4 p-4 bg-light rounded-3">
            <h5 className="mb-3 d-flex align-items-center">
              <FaMapMarkerAlt className="me-2 text-primary" />
              Location & Compensation
            </h5>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Location <span style={requiredStar}>*</span>
                  </Label>
                  <PlacesAutocomplete
                    value={eventAddress}
                    onChange={setEventAddress}
                    onSelect={handleLocationSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <Input
                          {...getInputProps({
                            placeholder: 'Enter job location',
                            className: 'form-control',
                          })}
                          required
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div className="p-2">Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style: {
                                    cursor: 'pointer',
                                    padding: '8px 12px',
                                    backgroundColor: suggestion.active
                                      ? '#fafafa'
                                      : '#ffffff',
                                    borderBottom: '1px solid #eee',
                                  },
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Salary Range</Label>
                  <div className="input-group">
                    <span className="input-group-text">{currencySymbol}</span>
                    <Input
                      type="text"
                      name="salary_range"
                      placeholder="e.g., 60,000 - 80,000"
                      value={formData.salary_range}
                      onChange={handleInputChange}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>

          {/* Additional Details */}
          <div className="mb-4 p-4 bg-light rounded-3">
            <h5 className="mb-3 d-flex align-items-center">
              <FaInfoCircle className="me-2 text-primary" />
              Additional Details
            </h5>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>External Posting URL</Label>
                  <Input
                    type="url"
                    name="posting_url"
                    value={formData.posting_url}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Expires At <span style={requiredStar}>*</span>
                  </Label>
                  <Input
                    type="date"
                    name="expires_at"
                    value={formData.expires_at}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          {/* Submit Buttons */}
          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button
              color="light"
              type="button"
              className="px-4"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button color="secondary" onClick={onClose}>
              Back
            </Button>
            <Button
              color="primary"
              type="submit"
              className="px-4"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  Posting...
                </>
              ) : isEditMode ? (
                'Update'
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default JobPostForm;
