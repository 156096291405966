import React, { useState, useEffect } from 'react';
import UnifiedMenteeCard from './UnifiedMenteeCard';
// import SearchFilters from './SearchFiltersNew';
import Pagination from './../Pagination';
import { Link } from 'react-router-dom';
import { useApi } from '../../../../hooks/useApi';
import {
  fetchMenteesByCategory,
  fetchCategoriesAndSubcategories,
  fetchMenteeCategoryStats,
} from './../mentorService';
import {
  Col,
  Card,
  CardBody,
  Badge,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Alert,
} from 'reactstrap';

import automationIcon from '../../../../assets/images/mentorship/categoryIcons/automation.png';
import bussinessIcon from '../../../../assets/images/mentorship/categoryIcons/bussiness.png';
import bussinessDevelopmentIcon from '../../../../assets/images/mentorship/categoryIcons/business-development.png';
import chemicalIcon from '../../../../assets/images/mentorship/categoryIcons/chemical.png';
import artsIcon from '../../../../assets/images/mentorship/categoryIcons/arts.png';
import academicsIcon from '../../../../assets/images/mentorship/categoryIcons/academics.png';
import financeIcon from '../../../../assets/images/mentorship/categoryIcons/finance.png';
import healthIcon from '../../../../assets/images/mentorship/categoryIcons/health.png';
import leadershipIcon from '../../../../assets/images/mentorship/categoryIcons/leadership-and-managment.png';
import materialIcon from '../../../../assets/images/mentorship/categoryIcons/material-science.png';
import medicalIcon from '../../../../assets/images/mentorship/categoryIcons/medical-and-healthcare.png';
import personalDevelopmentIcon from '../../../../assets/images/mentorship/categoryIcons/personal-development.png';
import pharmaIcon from '../../../../assets/images/mentorship/categoryIcons/pharmaneutical.png';
import spritualIcon from '../../../../assets/images/mentorship/categoryIcons/spiritual-growth.png';
import technologyIcon from '../../../../assets/images/mentorship/categoryIcons/technology.png';
const caterogyIcons = {
  1: automationIcon,
  2: bussinessIcon,
  3: bussinessDevelopmentIcon,
  4: chemicalIcon,
  5: artsIcon,
  6: academicsIcon,
  7: financeIcon,
  8: healthIcon,
  9: leadershipIcon,
  10: materialIcon,
  11: medicalIcon,
  12: personalDevelopmentIcon,
  13: pharmaIcon,
  14: spritualIcon,
  15: technologyIcon,
};

const CategoryCard = ({ category, categoryStats, isSelected, onClick, index }) => {
  return (
    <Card
      className={`category-card shadow-hover h-100 transition-all duration-300 border-2 ${
        isSelected ? 'border-primary shadow-lg' : 'border-primary border-opacity-25'
      } ${index % 2 === 0 ? 'bg-soft-warning' : 'bg-soft-primary'}`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <CardBody className="p-4">
        {/* Updated Header Section with consistent font styling */}
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="category-icon-wrapper rounded-circle p-3 bg-light-primary">
            <img
              src={caterogyIcons[parseInt(category.categoryid)]}
              style={{ height: '40px', width: '40px' }}
              alt={category.categoryname}
              className="transition-transform hover:scale-110"
            />
          </div>
          <div className="category-info flex-grow-1 px-3">
            <h5 className="mb-1 category-title">{category.categoryname}</h5>
            <small className="text-muted">
              {category.subcategories.length} specializations
            </small>
          </div>
          {categoryStats[category.categoryid] && (
            <div className="mentor-stats bg-white rounded-pill border border-primary border-opacity-25 px-3 py-2">
              <div className="text-center">
                <div className="fs-5 fw-bold text-primary mb-0">
                  {categoryStats[category.categoryid]}
                </div>
                <div className="text-xs text-primary-dark">Mentees</div>
              </div>
            </div>
          )}
        </div>

        {/* Simplified Subcategories Section */}
        <div className="subcategories-section mt-4">
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-certificate text-warning me-2"></i>
            <span className="text-dark fw-medium fs-6">Specializations</span>
          </div>
          <div className="d-flex flex-wrap gap-2">
            {category.subcategories?.map((sub) => (
              <Link
                key={`sub-${sub.subcategoryid}`}
                className="rounded bg-soft-primary py-1 px-2 fs-7 text-dark font-weight-medium subcategory-item"
                title={sub.subcategoryname}
              >
                {sub.subcategoryname}
              </Link>
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const MenteePage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [mentees, setMentees] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryStats, setCategoryStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { callApi } = useApi();

  const [filters, setFilters] = useState({
    categories: [],
    subCategories: [],
    searchText: '',
    minExperience: '',
    maxExperience: '',
    isPaidMentorship: null,
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalMentees: 0,
    menteesPerPage: 20,
  });

  // Load initial data
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    try {
      setLoading(true);

      // Fetch categories and their stats in parallel
      const [categoriesData, statsData] = await Promise.all([
        fetchCategoriesAndSubcategories(callApi),
        fetchMenteeCategoryStats(callApi),
      ]);

      setCategories(categoriesData.data);
      setCategoryStats(statsData);
    } catch (error) {
      console.error('Error loading initial data:', error);
      setError('Failed to load categories');
    } finally {
      setLoading(false);
    }
  };

  // Load mentors only when filters change or pagination
  useEffect(() => {
    if (searchPerformed) {
      loadMentees();
    }
  }, [filters, pagination.currentPage]);

  const loadMentees = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetchMenteesByCategory(callApi, 
        selectedCategory,
        pagination.currentPage,
        pagination.menteesPerPage,
      );
      // alert("Mentees Data = "+ JSON.stringify(response));
      setMentees(response.data);
      setPagination((prev) => ({
        ...prev,
        currentPage: response.pagination.currentPage,
        totalPages: response.pagination.totalPages,
        totalMentees: response.pagination.totalCount,
      }));
    } catch (err) {
      setError('Failed to load mentees');
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setSearchPerformed(true);
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* Header Section */}
          <Row className="mb-4">
            <Col className='text-center mb-2'>
              <h4 className="text-center mb-3">
                Find Active Mentees seeking mentorship
              </h4>
              <p className="text-center fs-5">
                Connect with active mentees and guide them in their journey
              </p>
                <Link 
                              to="/subscriptionplan" 
                              className="text-decoration-none subscription-link"
                              style={{
                                background: 'linear-gradient(45deg, #2d5fb4, #0d6efd)',
                                fontWeight: '800',
                                fontSize: '1.1rem',
                                color: 'white',
                              }}
                            >
                              ✨ Subscribe and Support MettaMate ✨
                            </Link>
            </Col>
          </Row>
          {/* {alert(caterogyIcons[1])} */}
          {/* Categories Grid */}
          {!searchPerformed && (
            <Row className="mb-4">
              <Col>
                <h4 className="mb-3">Browse by Category</h4>
                <Row className="g-4">
                  {categories.map((category, index) => (
                    <Col key={category.categoryid} xs={12} md={6} lg={4}>
                      <CategoryCard
                        category={category}
                        categoryStats={categoryStats}
                        isSelected={selectedCategory === category.categoryid}
                        index={index}
                        onClick={() => {
                          setSelectedCategory(category.categoryid);
                          handleFilterChange({
                            ...filters,
                            categories: [category.categoryid],
                          });
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          )}

          {/* Active Filters */}
          {selectedCategory && (
            <Row className="mb-3">
              <Col>
                <div className="d-flex flex-wrap gap-2 align-items-center">
                  <strong>Active Filters:</strong>
                  {filters.categories.map((catId) => {
                    const category = categories.find(
                      (c) => c.categoryid === catId,
                    );
                    return (
                      <Badge
                        key={catId}
                        color="primary"
                        className="d-flex align-items-center gap-2"
                      >
                        {category?.categoryname}
                        <Button
                          close
                          onClick={() => {
                            setSearchPerformed(false);
                            setSelectedCategory(null);
                            setFilters({
                              categories: [],
                              subCategories: [],
                              searchText: '',
                            });
                          }}
                        />
                      </Badge>
                    );
                  })}
                  {/* Add other active filters here */}
                  {selectedCategory && (
                    <Button
                      color="link"
                      size="sm"
                      onClick={() => {
                        setSearchPerformed(false);
                        setSelectedCategory(null);
                        setFilters({
                          categories: [],
                          subCategories: [],
                          searchText: '',
                        });
                      }}
                    >
                      Clear All
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {/* Results Section */}
          {selectedCategory && loading ? (
            <div className="text-center py-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {error && <Alert color="danger">{error}</Alert>}

              {selectedCategory && (
                <Row className="gy-4">
                  {mentees.map((mentee) => (
                    <Col 
                      key={`mentee-${mentee.mentee_uuid}`} // Ensure unique key
                      className="pt-2 text-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                    >
                      <UnifiedMenteeCard
                        completeMenteeDetails={mentee}
                        isAuthenticated={isAuthenticated}
                      />
                    </Col>
                  ))}
                </Row>
              )}

              {/* Pagination and No Results messaging remains same */}
              <hr />
              <div className="w-full flex justify-center my-8">
                <div className="flex space-x-2">
                  <Pagination
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default MenteePage;
