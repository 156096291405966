import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Pagination, message, Spin } from 'antd';
import axios from 'axios';
import apiUrl from '../../../apppages/server';
import AsMemberCard from '../as_members/AsMemberCard';
import CmMemberCard from '../cm_members/CmMemberCard';
import { useApi } from '../../../hooks/useApi';
import { referralService } from '../../../services/api';

const MyReferralsFunction = () => {
  const { callApi } = useApi();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0,
  });
  const [configurationData, setConfigurationData] = useState({});

  useEffect(() => {
    fetchRecommendedMembers(1);
  }, []);

  const fetchRecommendedMembers = async (page) => {
    const configData = localStorage.getItem('configurationData');
    if (!configData) {
      window.location.href = '/index';
      return;
    }

    const parsedConfigData = JSON.parse(configData);
    setConfigurationData(parsedConfigData);

    try {
      setLoading(true);
      const response = await callApi(() =>
        referralService.getRecommendedMembers(page, pagination.pageSize)
      );

      if (response?.members) {
        setMembers(response.members);
        setPagination({
          ...pagination,
          current: page,
          total: response.pagination.totalCount
        });
      }
    } catch (error) {
      message.error('Failed to fetch recommended members');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchRecommendedMembers(page);
  };

  return (
    <Container fluid className="py-3">
      {loading ? (
        <div className="text-center py-5">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row className="g-4">
            {members.map((member) => (
              <Col key={member.memberuuid} xs={12} sm={6} md={4} lg={3}>
                {member.isalliance === true ? (
                  <AsMemberCard
                    member={member}
                    currentPage={pagination.current}
                    configurationData={configurationData}
                  />
                ) : (
                  <CmMemberCard
                    member={member}
                    currentPage={pagination.current}
                    configurationData={configurationData}
                  />
                )}
              </Col>
            ))}
          </Row>

          {members.length > 0 ? (
            <Row className="mt-4">
              <Col className="d-flex justify-content-center">
                <Pagination
                  current={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  showQuickJumper
                  style={{
                    background: 'white',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  }}
                />
              </Col>
            </Row>
          ) : (
            <div className="text-center py-5">
              <h5 className="text-muted">No recommendations found</h5>
              <p>Start exploring to see more recommended members</p>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default MyReferralsFunction;
