import React from 'react';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'; // Add a separate file for ProtectedRoute (shown below)

// Import all components
import MainIndex from '../apppages/Home/index';
import UserRegistrationPage from '../apppages/Home/UserRegistration';
import UserLoginPageFunction from '../apppages/Home/UserLoginPage';
import UserHomePageFunction from '../apppages/Home/UserHomePage';
import OTPInputGroup from '../apppages/Home/ConfirmUserRegistration';
import PageForgotPassword from '../apppages/Home/PageForgotPassword';
import SubscriptionPlan from '../apppages/Home/subscription';
import BecomeMentor from '../apppages/Home/mentorship/BecomeMentor';
import BecomeMentee from '../apppages/Home/mentorship/Mentee/BecomeMentee';
import MyFavourites from '../apppages/Home/connection/FavouriteListing';
import Myconnections from '../apppages/Home/Connections';
import MyWorld from '../apppages/Home/MyWorld';
import MapFunction from '../apppages/Home/maps';
import MatchMakerFunction from '../apppages/Home/matchmaker/Configurematchmaker';
import SBpartnerFunction from '../apppages/Home/sbpartner/ConfigureSBpartner';
import ConfigureCommunityMember from '../apppages/Home/CommunityMember/configureCommunitymember';
import AdminPortal from '../apppages/Home/AdminPortal';
import ChatFunction from '../apppages/Home/chat/chats';
import SettingFunction from '../apppages/Home/Profile/Settings';
import CommunityMemberFunction from '../apppages/Home/CommunityMember/communityMemberdetails';
import MyProfileUpdateFunction from '../apppages/Home/PageProfileEdit';
import PartnerPreferenceFunction from '../apppages/Home/Preference/PartnerPreference';
import UserProfileEditFunction from '../apppages/Home/ProfileEditForUserSection';
import Mychat from './Home/chat/Mychat';
import Chat from './Home/chat/samplechat';
import SubscriptionManagement from './Home/SubscriptionManagement';
import Aboutus from './Home/AboutUs';
import PaymentSuccess from './Home/payment/paymentsuccess';
import PaymentFail from './Home/payment/paymentfail';
import Services from './Home/Services';
import Mentor from './Home/mentor_menty/Mentor';
import Blogs from './Home/blogs/Blogs';
import BlogDetails1 from './Home/blogs/Blogdetails1';
import BlogDetails2 from './Home/blogs/Blogdetails2';
import BlogDetails3 from './Home/blogs/Blogdetails3';
import BlogDetails4 from './Home/blogs/Blogdetails4';
import BlogDetails5 from './Home/blogs/Blogdetails5';
import BlogDetails6 from './Home/blogs/Blogdetails6';
import BlogDetails7 from './Home/blogs/Blogdetails7';
import BlogDetails8 from './Home/blogs/Blogdetails8';
import BlogDetails9 from './Home/blogs/Blogdetails9';
import PagePrivacy from './Home/PrivacyPolicy';
import ServicePage from './Home/ServicePage';
import TransformLivesPage from './Home/TransformLivesPage';
import EducationPage from './Home/EducationPage';
import ProfessionalDevelopmentPage from './Home/ProfessionalDevelopmentPage';
import ScholarshipPage from './Home/ScholarshipPage';
import ScholarshipListPage from './Home/ScholarshipListPage';
import TermsAndConditions from './Home/TermsAndConditions';
import ViewProfileDetails from './Home/ViewProfileDetails';
import ViewProfileDetails2 from './Home/ViewProfileDetails2';
import PageContactDetail from './Home/ContactUs';
import RefundAndCancellation from './Home/RefundAndCancellation';
import ShippingandDelivery from './Home/ShippingAndDelivery';
import CheckoutPayment from './CheckoutPayment';
import LeadershipTeam from './Home/leadership_team/LearshipTeam';
import MentorshipPage from './Home/mentorship/MentorshipPage';
import SelfMentorPage from './Home/mentorship/SelfMentorPage';
import SelfMenteePage from './Home/mentorship/Mentee/SelfMenteePage';
import MenteePage from './Home/mentorship/Mentee/MenteePage';

// Event-related components
import EventListingPage from './Home/ai_events/EventListingPage';
import NewEventForm from './Home/ai_events/NewEventForm';
import EventDetailsPage from './Home/ai_events/EventDetailsPage';
// Jobs Related Components
import JobListingPage from './Home/jobs/JobListingPage';
import JobDetailsPage from './Home/jobs/JobDetailsPage';
import RenamedJobPostForm from './Home/jobs/RenamedJobPostForm';
// AS Members Components
import AsMembersHomePage from './Home/as_members/AsMembersHomePage';
import CmMembersHomePage from './Home/cm_members/CmMembersHomePage';
import GenericMemberDetails from './Home/GenericMemberDetails';

// Function to determine the redirection path based on user's state
const getRedirectPath = () => {
  const token = localStorage.getItem('token');
  const configurationData = localStorage.getItem('configurationData');
  console.log('Token:', token, 'Configuration Data:', configurationData);

  if (token && configurationData) {
    try {
      const parsedData = JSON.parse(configurationData); // Parse the configurationData JSON
      const membertypeid = parsedData.membertypeid; // Extract membertypeid from parsedData

      console.log('Member Type ID:', membertypeid); // Debugging log to verify membertypeid

      // Redirect based on the membertypeid value
      if (membertypeid === 1) return '/asmembers';
      if (membertypeid === 4) return '/cmmembers';
    } catch (error) {
      console.error('Failed to parse configurationData:', error);
    }
  }

  return null; // No redirection if not logged in or no membertypeid
};

const routes = [
  { path: '/', exact: true, component: <Navigate to="/index" /> },
  {
    path: '/index',
    component: (
      <ProtectedRoute
        component={<MainIndex />}
        condition={!getRedirectPath()} // Allow access only if no redirection is needed
        redirectTo={getRedirectPath()} // Redirect if logged in
      />
    ),
    isTopbarDark: true,
  },
  {
    path: '/user-login-page',
    component: (
      <ProtectedRoute
        component={<UserLoginPageFunction />}
        condition={!getRedirectPath()} // Allow access only if no redirection is needed
        redirectTo={getRedirectPath()} // Redirect if logged in
      />
    ),
    isTopbarDark: true,
  },
  {
    path: '/ServicePage',
    component: <ServicePage />,
    isTopbarDark: true,
  },
  {
    path: '/TransformLivesPage',
    component: <TransformLivesPage />,
    isTopbarDark: true,
  },
  {
    path: '/EducationPage',
    component: <EducationPage />,
    isTopbarDark: true,
  },
  {
    path: '/ProfessionalDevelopmentPage',
    component: <ProfessionalDevelopmentPage />,
    isTopbarDark: true,
  },
  {
    path: '/ScholarshipPage',
    component: <ScholarshipPage />,
    isTopbarDark: true,
  },
  {
    path: '/scholarshipListPage',
    component: <ScholarshipListPage />,
    isTopbarDark: true,
  },
  {
    path: '/contactus',
    component: <PageContactDetail />,
    isTopbarDark: true,
  },
  {
    path: '/user-registration-page',
    component: <UserRegistrationPage />,
    isTopbarDark: true,
  },
  {
    path: '/confirm-user-registration',
    component: <OTPInputGroup />,
    isTopbarDark: true,
  },
  {
    path: '/reset-password',
    component: <PageForgotPassword />,
    isTopbarDark: true,
  },

  {
    path: '/subscriptionplan',
    component: <SubscriptionPlan />,
    isTopbarDark: true,
  },
  {
    path: '/become_mentor',
    component: <BecomeMentor />,
    isTopbarDark: true,
  },
  {
    path: '/become_mentee',
    component: <BecomeMentee />,
    isTopbarDark: true,
  },

  { path: '/myFavourites', component: <MyFavourites />, isTopbarDark: true },
  { path: '/connections', component: <Myconnections />, isTopbarDark: true },
  { path: '/myworld', component: <MyWorld />, isTopbarDark: true },
  {
    path: '/configuregeneralmember',
    component: <ConfigureCommunityMember />,
    isTopbarDark: true,
  },
  {
    path: '/communitymember-home-page',
    component: <CommunityMemberFunction />,
    isTopbarDark: true,
  },
  { path: '/adminportal', component: <AdminPortal />, isTopbarDark: true },
  { path: '/settings', component: <SettingFunction />, isTopbarDark: true },

  {
    path: '/my-profile-edit-page1',
    component: <MyProfileUpdateFunction />,
    isTopbarDark: true,
  },
  {
    path: '/my-profile-edit-page',
    component: <UserProfileEditFunction />,
    isTopbarDark: true,
  },
  {
    path: '/partner-preference-page',
    component: <PartnerPreferenceFunction />,
    isTopbarDark: true,
  },
  { path: '/maps', component: <MapFunction /> },
  {
    path: '/matchmaker',
    component: <MatchMakerFunction />,
    isTopbarDark: true,
  },
  { path: '/sbpartner', component: <SBpartnerFunction />, isTopbarDark: true },
  { path: '/chats/:id', component: <ChatFunction />, isTopbarDark: true },
  { path: '/chats', component: <Mychat />, isTopbarDark: true },
  { path: '/samplechats', component: <Chat />, isTopbarDark: true },
  {
    path: '/subscribe',
    component: <SubscriptionManagement />,
    isTopbarDark: true,
  },
  { path: '/aboutus', component: <Aboutus />, isTopbarDark: true },
  {
    path: '/leadership-team',
    component: <LeadershipTeam />,
    isTopbarDark: true,
  },
  {
    path: '/mentorship-page',
    component: <MentorshipPage />,
    isTopbarDark: true,
  },
  {
    path: '/mentees-page',
    component: <MenteePage />,
    isTopbarDark: true,
  },
  {
    path: '/self-mentor-page',
    component: <SelfMentorPage />,
    isTopbarDark: true,
  },
  {
    path: '/self-mentee-page',
    component: <SelfMenteePage />,
    isTopbarDark: true,
  },
  {
    path: '/event-listing-page',
    component: <EventListingPage />,
    isTopbarDark: true,
  },
  {
    path: '/events/:eventuuid',
    component: <EventDetailsPage />,
    isTopbarDark: true,
  },
  {
    path: '/event/:slug', // Add new route for shareable URLs
    component: <EventDetailsPage />,
    isTopbarDark: true,
  },
  {
    path: '/events/create',
    component: <NewEventForm />,
    isTopbarDark: true,
  },
  {
    path: '/eventsedit/:eventuuid',
    component: <NewEventForm />,
    isTopbarDark: true,
  },
  {
    path: '/paymenthandler',
    component: <PaymentSuccess />,
    isTopbarDark: true,
  },
  { path: '/cancel', component: <PaymentFail />, isTopbarDark: true },
  { path: '/services', component: <Services />, isTopbarDark: true },
  { path: '/blogs', component: <Blogs />, isTopbarDark: true },
  { path: '/blogdetails1', component: <BlogDetails1 />, isTopbarDark: true },
  { path: '/blogdetails2', component: <BlogDetails2 />, isTopbarDark: true },
  { path: '/blogdetails3', component: <BlogDetails3 />, isTopbarDark: true },
  { path: '/blogdetails4', component: <BlogDetails4 />, isTopbarDark: true },
  { path: '/blogdetails5', component: <BlogDetails5 />, isTopbarDark: true },
  { path: '/blogdetails6', component: <BlogDetails6 />, isTopbarDark: true },
  { path: '/blogdetails7', component: <BlogDetails7 />, isTopbarDark: true },
  { path: '/blogdetails8', component: <BlogDetails8 />, isTopbarDark: true },
  { path: '/blogdetails9', component: <BlogDetails9 />, isTopbarDark: true },
  { path: '/mentors', component: <Mentor />, isTopbarDark: true },

  { path: '/privacypolicy', component: <PagePrivacy />, isTopbarDark: true },
  {
    path: '/termsandconditions',
    component: <TermsAndConditions />,
    isTopbarDark: true,
  },
  {
    path: '/viewprofiledetails',
    component: <ViewProfileDetails2 />,
    isTopbarDark: true,
  },
  {
    path: '/refundandcancellation',
    component: <RefundAndCancellation />,
    isTopbarDark: true,
  },
  {
    path: '/shippinganddelivery',
    component: <ShippingandDelivery />,
    isTopbarDark: true,
  },
  {
    path: '/job-listing',
    component: <JobListingPage />,
    isTopbarDark: true,
  },
  {
    path: '/job-details-page',
    component: <JobDetailsPage />,
    isTopbarDark: true,
  },
  {
    path: '/jobedit/:jobuuid',
    component: <RenamedJobPostForm />,
    isTopbarDark: true,
  },
  {
    path: '/asmembers',
    component: <AsMembersHomePage />,
    isTopbarDark: true,
  },
  {
    path: '/cmmembers',
    component: <CmMembersHomePage />,
    isTopbarDark: true,
  },
  {
    path: '/member/:memberuuid',
    component: <GenericMemberDetails />,
    isTopbarDark: true,
  },
  {
    path: '/jobs/:jobuuid', // Change to match the parameter name
    component: <JobDetailsPage />,
    isTopbarDark: true,
  },
  {
    path: '/job/:job_share_url', 
    component: <JobDetailsPage />,
    isTopbarDark: true,
  },
];

export default routes;
