import React, { useState, useEffect } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import {
  FaBriefcase,
  FaUser,
  FaBookmark,
  FaPlusCircle,
  FaSuitcase,
} from 'react-icons/fa';
import { Calendar as CalendarIcon, PlusCircle } from 'lucide-react';
import JobCard from './JobCard';
import SearchJobs from './SearchJobs';
import { fetchJobPostings } from './jobServices';
import { message } from 'antd';
import JobPostForm from './RenamedJobPostForm';
import { useNavigate, Link } from 'react-router-dom';
import { Pagination } from 'antd';
import { useApi } from '../../../hooks/useApi';

const JobListingPage = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastFilters, setLastFilters] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 6, // Changed from 12 to 9
    totalCount: 0,
    pageCount: 0,
  });
  const navigate = useNavigate();
  const [showJobPostForm, setShowJobPostForm] = useState(false);
  const [isFormVisible, setFormVisible] = useState(false);
  const [editJob, setEditJob] = useState(null);
  const { callApi } = useApi();

  const fetchJobData = async (page = 1, filters = {}) => {
    setLoading(true);
    try {
      const jobType =
        activeTab === '1'
          ? 'available'
          : activeTab === '3'
            ? 'bookmarked'
            : 'my';

      const response = await fetchJobPostings(callApi, {
        pageIndex: page,
        pageSize: pagination.pageSize,
        searchText: filters.searchText || '',
        categories: filters.categories || [],
        location: filters.location || null,
        isBookmarked: jobType === 'bookmarked',
        isMyJobs: jobType === 'my',
      });
      // alert("response: " + JSON.stringify(response));
      if (response && response.jobs) {
        setJobs(response.jobs);
        // Update pagination with the response data
        if (response.pagination) {
          setPagination((prev) => ({
            ...prev,
            currentPage: parseInt(response.pagination.pageIndex),
            totalCount: parseInt(response.pagination.totalCount),
            pageCount: parseInt(response.pagination.totalPages),
          }));
        }
      }
    } catch (error) {
      message.error('Failed to fetch jobs');
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle tab changes
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      // If we have last used filters, apply them to the new tab
      if (lastFilters) {
        fetchJobData(1, lastFilters); // Changed from fetchJobs to fetchJobData
      }
    }
  };

  // Handle search from SearchJobs component
  const handleSearch = (filters) => {
    setLastFilters(filters);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1, // Reset to first page on new search
    }));
    fetchJobData(1, filters);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage !== pagination.currentPage) {
      setPagination((prev) => ({
        ...prev,
        currentPage: newPage,
      }));
      fetchJobData(newPage, lastFilters || {});
    }
  };

  // Initial fetch on component mount
  useEffect(() => {
    const initialFilters = {
      searchText: '',
      categories: [],
      location: '',
    };
    fetchJobData(1, initialFilters);
  }, [activeTab]); // Refetch when tab changes

  const tabStyle = {
    transition: 'all 0.3s ease',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    minWidth: '140px',
    border: '1px solid #dee2e6',
    marginTop: '2px', // Add margin to compensate for the upward movement
    WebkitOverflowScrolling: 'touch', // Correct camelCase format
  };

  const activeTabStyle = {
    transform: 'translateY(-2px)',
    boxShadow: '0 2px 4px rgba(0,123,255,0.2)',
    border: '1px solid #007bff',
    position: 'relative',
    zIndex: 1,
    marginTop: '2px', // Reset margin when active
  };

  const handleEditJob = (job) => {
    setEditJob(job);
    setActiveTab('2');
    setShowJobPostForm(true);
  };

  const handleCloseForm = () => {
    setEditJob(null);
    setShowJobPostForm(false);
  };

  const renderJobsList = () => (
    <div>
      <Row className="g-4">
        {loading ? (
          <Col key="loading-spinner" xs={12} className="text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </Col>
        ) : jobs.length > 0 ? (
          <>
            {jobs.map((job, index) => (
              <Col 
                key={job.jobuuid || `job-${index}`} // Add index as fallback
                lg={4} 
                md={6}
              >
                <JobCard
                  job={job}
                  canEdit={activeTab === '2'}
                  onEdit={handleEditJob}
                />
              </Col>
            ))}

            {/* Replace existing pagination with this premium version */}
            {pagination.pageCount > 1 && (
              <Col key="pagination" xs={12} className="mt-5">
                <div className="d-flex justify-content-center">
                  <Pagination
                    current={pagination.currentPage}
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    showQuickJumper
                    showTotal={(total, range) => (
                      <span className="text-muted me-3">
                        Showing {range[0]}-{range[1]} of {total} jobs
                      </span>
                    )}
                    className="custom-pagination"
                  />
                </div>
              </Col>
            )}
          </>
        ) : (
          <Col key="no-jobs" xs={12} className="text-center py-5">
            <p className="text-muted">No jobs found</p>
          </Col>
        )}
      </Row>
    </div>
  );

  // Add handler for job post button
  const handleJobPostClick = () => {
    setShowJobPostForm(true);
  };

  const handleCloseJobPostForm = () => {
    setShowJobPostForm(false);
    // Optionally refresh the jobs list after posting
    fetchJobData(1, lastFilters || {});
  };

  const handleAddNewJob = () => {
    setEditJob(null);
    setShowJobPostForm(true);
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* Header Section */}
          <Row className="">
            <Col className="text-center mb-2">
              <p className="text-center fs-5 text-dark">
                Discover and apply for your dream job!
              </p>
                <Link 
                              to="/subscriptionplan" 
                              className="text-decoration-none subscription-link"
                              style={{
                                background: 'linear-gradient(45deg, #2d5fb4, #0d6efd)',
                                fontWeight: '800',
                                fontSize: '1.1rem',
                                color: 'white',
                              }}
                            >
                              ✨ Subscribe and Support MettaMate ✨
                            </Link>
            </Col>
          </Row>

          {/* Tabs Section */}
          <Row className="mb-4">
            <Col xs={12}>
              <div
                className="d-flex overflow-auto pb-2"
                style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
              >
                <div className="d-flex gap-3">
                  {[
                    { id: '1', icon: FaBriefcase, title: 'Available Jobs' },
                    { id: '2', icon: FaUser, title: 'My Posted Jobs' },
                    { id: '3', icon: FaBookmark, title: 'Bookmarked Jobs' },
                  ].map((tab) => (
                    <Card
                      key={tab.id}
                      className={classnames({
                        'bg-light': activeTab === tab.id,
                      })}
                      onClick={() => toggle(tab.id)}
                      style={{
                        ...tabStyle,
                        ...(activeTab === tab.id ? activeTabStyle : {}),
                        cursor: 'pointer',
                      }}
                    >
                      <CardBody className="d-flex align-items-center gap-2 py-2 px-3">
                        <tab.icon
                          size={20}
                          className={
                            activeTab === tab.id ? 'text-primary' : 'text-dark'
                          }
                        />
                        <span
                          className={`${activeTab === tab.id ? 'text-primary' : 'text-dark'}`}
                        >
                          {tab.title}
                        </span>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              </div>
            </Col>
          </Row>

          {/* TabContent */}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <SearchJobs onSearch={handleSearch} />
              {renderJobsList()}
            </TabPane>

            <TabPane tabId="2">
              {!showJobPostForm ? (
                <>
                  <Row className="mb-3">
                    <Col xs={12}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Button
                        color="primary"
                        className="d-flex align-items-center gap-2 px-3 py-2 w-100 w-sm-auto"
                        onClick={handleAddNewJob}
                        style={{
                          background: 'linear-gradient(135deg, #2563EB, #1E40AF)',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: '0 2px 4px rgba(37, 99, 235, 0.2)',
                          transition: 'all 0.3s ease',
                          maxWidth: '100%',
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'translateY(-1px)';
                          e.currentTarget.style.boxShadow = '0 4px 12px rgba(37, 99, 235, 0.3)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = '0 2px 4px rgba(37, 99, 235, 0.2)';
                        }}
                      >
                        <PlusCircle className="flex-shrink-0" size={20} />
                        <span className="fw-semibold">Post New Job</span>
                      </Button>
                    </div>
                    </Col>
                  </Row>
                  <SearchJobs onSearch={handleSearch} />
                  {renderJobsList()}
                </>
              ) : (
                <div className="px-2">
                  <JobPostForm
                    jobData={editJob}
                    onClose={handleCloseJobPostForm}
                    onSubmitSuccess={() => {
                      handleCloseJobPostForm();
                      fetchJobData(1, lastFilters || {});
                    }}
                  />
                </div>
              )}
            </TabPane>

            <TabPane tabId="3">
              <SearchJobs onSearch={handleSearch} />
              {renderJobsList()}
            </TabPane>
          </TabContent>
        </Container>
      </section>
      <style>
        {`
          .custom-pagination {
            .ant-pagination-item,
            .ant-pagination-prev,
            .ant-pagination-next,
            .ant-pagination-jump-prev,
            .ant-pagination-jump-next {
              margin: 0 4px;
              border-radius: 8px;
              min-width: 36px;
              height: 36px;
              line-height: 36px;
              transition: all 0.3s ease;
            }

            .ant-pagination-item {
              border: 1px solid #e2e8f0;
              background: white;
              box-shadow: 0 1px 2px rgba(0,0,0,0.05);
              
              &:hover {
                border-color: #0D6EFD;
                color: #0D6EFD;
                transform: translateY(-1px);
                box-shadow: 0 4px 12px rgba(13, 110, 253, 0.15);
              }
              
              &-active {
                background: #0D6EFD;
                border-color: #0D6EFD;
                color: white;
                font-weight: 500;
                
                &:hover {
                  background: #0b5ed7;
                  color: white;
                }
              }
            }

            .ant-pagination-prev,
            .ant-pagination-next {
              &:hover button {
                border-color: #0D6EFD;
                color: #0D6EFD;
              }
            }

            .ant-pagination-item-link {
              border-radius: 8px;
            }

            .ant-pagination-options {
              .ant-select-selector {
                border-radius: 8px;
              }
            }

            .ant-pagination-quick-jumper {
              input {
                border-radius: 6px;
                transition: all 0.3s ease;
                
                &:hover, &:focus {
                  border-color: #0D6EFD;
                  box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.1);
                }
              }
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default JobListingPage;
