import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Input, Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Search, Sliders, Clock, CheckCircle } from 'lucide-react';
import { FaUsers } from 'react-icons/fa';
import { BiWorld } from 'react-icons/bi';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { fetchEventCategories } from './eventServices';
import * as ReactIcons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { message } from 'antd';
import { useApi } from '../../../hooks/useApi';

const eventTypes = [
  { value: 'virtual', label: 'Virtual' },
  { value: 'in-person', label: 'In-person' },
  { value: 'both', label: 'Hybrid' }
];

const timeFilters = [
  { id: 'all', label: 'All Events', icon: Clock },
  { id: 'today', label: 'Today', icon: Clock },
  { id: 'tomorrow', label: "Tomorrow", icon: Clock },
  { id: 'thisWeek', label: 'This Week', icon: Clock },
  { id: 'weekend', label: 'Weekend', icon: Clock },
  { id: 'nextWeek', label: 'Next Week', icon: Clock },
];

const getCurrentDateMinusOne = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today.toISOString().split('T')[0];
};

const SearchEvent = ({ onSearch }) => {
  const [eventCategories, setEventCategories] = useState([]);
  const [filters, setFilters] = useState({
    searchText: '',
    eventType: '',
    location: '',
    locationAddress: '',
    categories: [],
    startDate: getCurrentDateMinusOne(),
    endDate: '',
    timeFilter: 'all',
  });
  
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState(false);
  const searchTextRef = useRef(null);
  const { callApi } = useApi();

  useEffect(() => {
    loadEventCategories();
  }, []);

  const loadEventCategories = async () => {
    try {
      const categories = await fetchEventCategories(callApi);
      setEventCategories(categories);
    } catch (error) {
      message.error('Failed to load event categories');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLocationSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;
      
      const city = addressComponents.find(component => 
        component.types.includes('locality'))?.long_name || '';
      const state = addressComponents.find(component => 
        component.types.includes('administrative_area_level_1'))?.long_name || '';
      const country = addressComponents.find(component => 
        component.types.includes('country'))?.long_name || '';

      const locationName = [city, state, country].filter(Boolean).join(", ");
      
      setFilters(prev => ({
        ...prev,
        location: latLng,
        locationAddress: locationName
      }));
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchText = searchTextRef.current.value;
    setFilters(prev => ({ ...prev, searchText }));

    onSearch({
      ...filters,
      searchText,
    });
  };

  const toggleCategory = (categoryId) => {
    setFilters(prev => ({
      ...prev,
      categories: prev.categories.includes(categoryId)
        ? prev.categories.filter(id => id !== categoryId)
        : [...prev.categories, categoryId]
    }));
  };

  const handleAdvancedFilters = () => {
    setIsAdvancedModalOpen(false);
    onSearch(filters);
  };

  const handleTimeFilterChange = (filterId) => {
    setFilters(prev => ({
      ...prev,
      timeFilter: filterId,
      // Reset date range when time filter is selected
      startDate: '',
      endDate: ''
    }));
    // Trigger search immediately when time filter changes
    onSearch({
      ...filters,
      timeFilter: filterId,
      startDate: '',
      endDate: ''
    });
  };

  const handleResetFilters = () => {
    const defaultFilters = {
      searchText: '',
      eventType: '',
      location: '',
      locationAddress: '',
      categories: [],
      startDate: getCurrentDateMinusOne(),
      endDate: '',
      timeFilter: 'all',
    };
    setFilters(defaultFilters);
    onSearch(defaultFilters);
  };

  const renderAdvancedFiltersModal = () => (
    <Modal isOpen={isAdvancedModalOpen} toggle={() => setIsAdvancedModalOpen(!isAdvancedModalOpen)} size="md">
      <ModalHeader toggle={() => setIsAdvancedModalOpen(false)}>
        Advanced Filters
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <Label className="mb-2">Event Type</Label>
          <Input
            type="select"
            name="eventType"
            value={filters.eventType}
            onChange={handleChange}
            className="form-control"
          >
            <option value="">All Event Types</option>
            {eventTypes.map(type => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </Input>
        </div>

        <div className="mb-4">
          <Label className="mb-2">Location</Label>
          <PlacesAutocomplete
            value={filters.locationAddress}
            onChange={(address) => setFilters(prev => ({ ...prev, locationAddress: address }))}
            onSelect={handleLocationSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Search location...',
                    className: 'form-control',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div className="p-2">Loading...</div>}
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className: 'suggestion-item',
                        style: {
                          backgroundColor: suggestion.active ? '#f8f9fa' : '#fff',
                          padding: '8px 12px',
                          cursor: 'pointer'
                        },
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <div className="mb-4">
          <Label className="mb-2">Date Range</Label>
          <Row className="g-2">
            <Col xs={6}>
              <Input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleChange}
                placeholder="Start Date"
                className="form-control"
              />
            </Col>
            <Col xs={6}>
              <Input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleChange}
                placeholder="End Date"
                className="form-control"
              />
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setIsAdvancedModalOpen(false)}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleAdvancedFilters}>
          Apply Filters
        </Button>
        <Button color="danger" onClick={handleResetFilters}>
          Reset Filters
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <Card 
      className="border-1 mb-4" 
      style={{ 
        backgroundColor: '#f8f9fa',
        boxShadow: '0 8px 24px rgba(140,152,164,.2)'
      }}
    >
      <CardBody className="p-4">
        {/* Category Section with responsive behavior */}
        <div className="mb-4 position-relative">
          <div className="category-container">
            <div className="category-wrapper">
              {eventCategories.map((category) => {
                const IconComponent = category.categoryicon ? 
                  (ReactIcons[category.categoryicon] || BiIcons[category.categoryicon] || FaUsers) : 
                  FaUsers;
                return (
                  <Button
                    key={category.categoryuuid}
                    color={filters.categories.includes(category.categoryuuid) ? 'primary' : 'light'}
                    className="category-button d-flex align-items-center gap-2 px-3 py-2 rounded-pill"
                    onClick={() => toggleCategory(category.categoryuuid)}
                    style={{
                      whiteSpace: 'nowrap',
                      transition: 'all 0.3s ease',
                      borderColor: filters.categories.includes(category.categoryuuid) ? 'transparent' : '#dee2e6',
                      margin: '0.25rem',
                    }}
                  >
                    <Search size={16} className={filters.categories.includes(category.categoryuuid) ? 'text-white' : 'text-primary'} />
                    <IconComponent 
                      style={{ 
                        color: filters.categories.includes(category.categoryuuid) ? 
                          'white' : category.color 
                      }} 
                    />
                    <span>{category.categoryname}</span>
                    {filters.categories.includes(category.categoryuuid) && <CheckCircle size={16} className="text-white" />}
                  </Button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Search form */}
        <form onSubmit={handleSubmit}>
          <Row className="g-3 mb-4">
            <Col xs={8} md={10}>
              <div className="input-group">
                <span className="input-group-text bg-light border-0">
                  <Search size={18} />
                </span>
                <Input
                  type="text"
                  name="searchText"
                  placeholder="Search events..."
                  defaultValue={filters.searchText}
                  innerRef={searchTextRef}
                  className="border-start-0"
                />
              </div>
            </Col>
            <Col xs={4} md={2}>
              <div className="d-flex gap-2 h-100">
                <Button 
                  color="primary" 
                  type="submit" 
                  className="w-100 d-flex align-items-center justify-content-center"
                >
                  <Search size={18} className="d-block d-md-none" />
                  <span className="d-none d-md-block">Search</span>
                </Button>
                <Button
                  color="light"
                  className="w-100 border d-flex align-items-center justify-content-center"
                  onClick={() => setIsAdvancedModalOpen(true)}
                >
                  <Sliders size={18} />
                </Button>
              </div>
            </Col>
          </Row>
        </form>

        {/* Time filter section with divider */}
        <div className="time-filters">
          <div className="d-flex align-items-center mb-3">
            <div className="flex-grow-1 border-bottom"></div>
            <span className="px-3 text-muted small">Time Filter</span>
            <div className="flex-grow-1 border-bottom"></div>
          </div>
          <div className="d-flex overflow-auto pb-2" 
               style={{ 
                 msOverflowStyle: 'none', 
                 scrollbarWidth: 'none',
                 WebkitOverflowScrolling: 'touch'
               }}>
            {timeFilters.map((filter) => (
              <Button
                key={filter.id}
                color={filters.timeFilter === filter.id ? 'primary' : 'light'}
                className="d-flex align-items-center gap-2 px-3 py-2"
                onClick={() => handleTimeFilterChange(filter.id)}
                style={{
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s ease',
                  borderColor: filters.timeFilter === filter.id ? 'transparent' : '#dee2e6',
                  minWidth: 'fit-content',
                  boxShadow: filters.timeFilter === filter.id ? '0 2px 4px rgba(0,123,255,0.2)' : 'none'
                }}
              >
                <filter.icon 
                  size={16} 
                  className={filters.timeFilter === filter.id ? 'text-white' : 'text-primary'} 
                />
                <span className={filters.timeFilter === filter.id ? 'text-white' : ''}>
                  {filter.label}
                </span>
              </Button>
            ))}
          </div>
        </div>

        {/* Advanced Filters Modal */}
        {renderAdvancedFiltersModal()}
      </CardBody>

      <style>
        {`
          .category-container {
            width: 100%;
            position: relative;
          }

          .category-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            justify-content: flex-start;
          }

          /* Mobile styles */
          @media (max-width: 768px) {
            .category-wrapper {
              flex-wrap: nowrap;
              overflow-x: auto;
              -webkit-overflow-scrolling: touch;
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none;  /* Internet Explorer 10+ */
              padding-bottom: 0.5rem;
            }

            .category-wrapper::-webkit-scrollbar {
              display: none; /* WebKit */
            }

            .category-button {
              flex: 0 0 auto;
            }
          }
        `}
      </style>
    </Card>
  );
};

export default SearchEvent;
