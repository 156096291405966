import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { registrationService } from '../../services/api';
import Section from './RegistrationSection';
import '../Home/css/register.css';
import CommonModal from '../Home/modal.js';
import { useNavigate } from 'react-router-dom';
import mainBanner from '../../assets/images/mainBG-image.jpg';

import {
  Input,
  Container,
  Col,
  FeatherIcon,
  Label,
  Button,
  Form,
  Row,
  Card,
} from 'reactstrap';

const OTPInputGroup = () => {
  const { callApi } = useApi();
  const [success, setSuccess] = useState();
  const [inputValues, setInputValues] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
  });
  const handleInputChange = (inputId, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }));
  };
  const [error, setError] = useState();
  const emailid = localStorage.getItem('email');
  const navigate = useNavigate();

  const handleSubmit = () => {};
  async function confirmEmail() {
    const otpcode =
      inputValues.input1 +
      inputValues.input2 +
      inputValues.input3 +
      inputValues.input4 +
      inputValues.input5 +
      inputValues.input6;

    try {
      const response = await callApi(() =>
        registrationService.validateOtp({
          otp: otpcode,
          memberUUID: localStorage.getItem('memberID')
        })
      );

      setSuccess({
        title: 'SUCCESS!',
        message: response.message
      });

      setTimeout(() => {
        navigate('/user-login-page');
      }, 2000);

    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.data?.message || 'Failed to validate OTP'
      });
    }
  }
  const errorHandler = () => {
    setError(null);
  };

  const successHandler = () => {
    setSuccess(null);
  };

  const resendOtp = async () => {
    try {
      const response = await callApi(() =>
        registrationService.resendOtp({
          memberUUID: localStorage.getItem('memberID'),
          email: localStorage.getItem('email')
        })
      );

      setSuccess({
        title: 'SUCCESS!',
        message: response.message
      });

      setInputValues({
        input1: '',
        input2: '',
        input3: '',
        input4: '',
        input5: '',
        input6: '',
      });

    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: error.response?.data?.message || 'Failed to resend OTP'
      });
    }
  };
  return (
    <>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}
      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <section
        className="d-table w-100 pt-5 mt-5"
        style={{
          background: `url(${mainBanner}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
        id="registration"
      >
        <Container className="section-three">
          <Row className="position-relative align-items-center pt-5">
            <Col lg={6} className="offset-lg-6">
              <Card
                className="title-heading studio-home rounded shadow mb-5 "
                style={{
                  marginBottom: '50px',
                  backgroundColor: '#ffc107',
                }}
              >
                <Row>
                  <Col lg={12}>
                    <Form
                      class="my-2"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    ></Form>
                    <Col lg={12} md={12} className="my-4 text-center">
                      <Row>
                        <Col md={12} xs={12}>
                          <div className="d-flex features feature-primary p-4">
                            <div className="flex-1">
                              <h4 className="title">
                                <span className="text-primary">JaiBheem </span>{' '}
                                {emailid}
                                {''}
                              </h4>
                              <p className="para mb-0">
                                Thanks for creating your MettaMate account !.
                                <br />
                                To continue, please confirm your email address
                                by entering secrete code sent you on your email
                                address and then click the button below :
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md={12} xs={12} className="my-4 text-center">
                          <div className="d-flex features feature-primary p-4">
                            <div className="flex-1">
                              <div id="OTPInputGroup" data-autosubmit="false">
                                <OTPInput
                                  id="input1"
                                  value={inputValues.input1}
                                  onValueChange={handleInputChange}
                                  previousId={null}
                                  handleSubmit={handleSubmit}
                                  nextId="input2"
                                />
                                <OTPInput
                                  id="input2"
                                  value={inputValues.input2}
                                  onValueChange={handleInputChange}
                                  previousId="input1"
                                  handleSubmit={handleSubmit}
                                  nextId="input3"
                                />
                                <OTPInput
                                  id="input3"
                                  value={inputValues.input3}
                                  onValueChange={handleInputChange}
                                  previousId="input2"
                                  handleSubmit={handleSubmit}
                                  nextId="input4"
                                />
                                {/* Seperator */}
                                <span>&ndash;</span>
                                <OTPInput
                                  id="input4"
                                  value={inputValues.input4}
                                  onValueChange={handleInputChange}
                                  previousId="input3"
                                  handleSubmit={handleSubmit}
                                  nextId="input5"
                                />
                                <OTPInput
                                  id="input5"
                                  value={inputValues.input5}
                                  onValueChange={handleInputChange}
                                  previousId="input4"
                                  handleSubmit={handleSubmit}
                                  nextId="input6"
                                />
                                <OTPInput
                                  id="input6"
                                  value={inputValues.input6}
                                  onValueChange={handleInputChange}
                                  previousId="input5"
                                  handleSubmit={handleSubmit}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={12} xs={12} className="my-4 text-center">
                          <div className="d-flex features feature-primary">
                            <div className="flex-1">
                              <Button color="primary" onClick={confirmEmail}>
                                Confirm Your Email{' '}
                              </Button>
                            </div>
                          </div>
                          <div className="text-center features feature-primary pt-1">
                            <p className="resend_div" onClick={resendOtp}>
                              Resend OTP
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

//Our child component
const OTPInput = ({
  id,
  previousId,
  nextId,
  value,
  onValueChange,
  handleSubmit,
}) => {
  //This callback function only runs when a key is released
  const handleKeyUp = (e) => {
    //check if key is backspace or arrowleft
    if (e.keyCode === 8 || e.keyCode === 37) {
      //find the previous element
      const prev = document.getElementById(previousId);
      if (prev) {
        //select the previous element
        prev.select();
      }
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) || //check if key is numeric keys 0 to 9
      // (e.keyCode >= 65 && e.keyCode <= 90) || //check if key is alphabetical keys A to Z
      (e.keyCode >= 96 && e.keyCode <= 105) || //check if key is numeric keypad keys 0 to 9
      e.keyCode === 39 //check if key is right arrow key
    ) {
      //find the next element
      const next = document.getElementById(nextId);
      if (next) {
        //select the next element
        next.select();
      } else {
        const inputGroup = document.getElementById('OTPInputGroup');
        if (inputGroup && inputGroup.dataset['autosubmit']) {
          handleSubmit();
        }
      }
    }
  };
  return (
    <Input
      id={id}
      name={id}
      type="text"
      value={value}
      maxLength="1"
      onChange={(e) => onValueChange(id, e.target.value)}
      onKeyUp={handleKeyUp}
      style={{
        width: '50px',
        margin: '10px',
        display: 'inline-block',
        border: '1px solid lightslategrey',
      }}
    />
  );
};
export default OTPInputGroup;
