import React, { useEffect, useMemo, useState } from 'react';
import '../../Home/css/userHomePage.css';
import CommonModal from '../modal.js';

import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, CardBody, PaginationLink, PaginationItem } from 'reactstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg'
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg'

import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';

import ContentLoader, { List } from 'react-content-loader';
import ModalBodySkeleton from '../../Home/skeleton.jsx';
import {
    Button, Modal, ModalFooter, ModalHeader, ModalBody
} from 'reactstrap';
import ProfileModal from '../Profile_modal.jsx';
import { useApi } from '../../../hooks/useApi';
import { connectionService } from '../../../services/api';

const PendingConnectionsFunction = () => {
    const { callApi } = useApi();
    const [pendingConnections, setPendingConnections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        fetchPendingConnections();
    }, []);


    const displayImage = (imagepath, gender) => {
        if (!imagepath) {
          if (gender.toLowerCase() == 'male') {
            return manavatar;
          } else if (gender.toLowerCase() == 'female') {
            return femaleavatar;
          } else {
            return transgenderavatar;
          }
        } else {
          return imagepath;
        }
      };

    const fetchPendingConnections = async () => {
        setLoading(true);
        try {
            const response = await callApi(() =>
                connectionService.getPendingConnections({memberuuid: localStorage.getItem('memberID')})
            );

            if (response?.length > 0) {
                setPendingConnections(response);
            } else {
                setPendingConnections([]);
            }
        } catch (error) {
            setError({
                title: 'An Error Occurred!',
                message: error.response?.data?.message || 'Failed to fetch pending connections'
            });
        } finally {
            setLoading(false);
        }
    };

    const updateConnectionStatus = async (connectmemberuuid, status) => {
        try {
            const request = {
                connectedmemberuuid: connectmemberuuid,
                memberuuid: localStorage.getItem('memberID'),
                status
            };

            const response = await callApi(() =>
                connectionService.updateConnection(request)
            );

            if (response) {
                await fetchPendingConnections();
                setSuccess({
                    title: 'SUCCESS!',
                    message: response.message
                });
            }
        } catch (error) {
            setError({
                title: 'An Error Occurred!',
                message: error.response?.data?.message || 'Failed to update connection status'
            });
        }
    };

    const errorHandler = () => {
        setError(null);
    };

    const successHandler = () => {
        setSuccess(null);
    };

    return (
        <>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}
            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            <Col lg="12" className="Col_12">
                <Row>
                    <Col md={12} xs={12} className="inner_row pb-4">
                        {loading ? (
                            <Row className="mt-3">
                                <Col md={12}>
                                    <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">
                                        <Row className="w-100">
                                            <Col md={4}>
                                                <List />
                                            </Col>
                                            <Col md={4}></Col>
                                            <Col md={4}>
                                                <List />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                {pendingConnections.length === 0 ? (
                                    <div className="text-center mt-3 label_text">
                                        No Pending Connections!
                                    </div>
                                ) : (
                                    <Row>
                                        {pendingConnections.map((connection, key) => (
                                            <Col xs={12} className="mt-4 pt-2" key={key}>
                                                <div className="job-box bg-white d-md-flex align-items-center shadow rounded p-4 position-relative ">
                                                    {/* Render connection details */}
                                                    <img
                                                        src={displayImage(
                                                            connection.photothumb1,
                                                            connection.gender,
                                                        )}
                                                        className="avatar avatar-md-sm favourite_avatar_img"
                                                        alt=""
                                                    />
                                                    <div className="ms-md-4 mt-4 mt-sm-0">
                                                        <a className="text-dark h5">
                                                            {connection.firstname} {connection.lastname}
                                                        </a>
                                                        <ul className="list-unstyled mb-0 mt-2">
                                                            <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                                                                <i className="uil uil-user-plus text-primary"></i>
                                                                <Link to="#" className="text-dark">
                                                                    {connection.membertype}
                                                                </Link>
                                                            </li>
                                                            <li className="d-lg-inline text-muted h6 mb-0">
                                                                <i className="uil uil-bill text-primary"></i>
                                                                {connection.gender}
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="position-absolute top-0 end-0 mt-3 me-3">
                                                        <button
                                                            title="Accept"
                                                            className="btn text-white btn-success"
                                                            onClick={() => updateConnectionStatus(connection.memberuuid, 2)}
                                                        >
                                                            <i className="uil uil-check align-middle"></i>
                                                            Accept
                                                        </button>
                                                        <button
                                                            title="Ignore"
                                                            className="btn btn-danger text-white"
                                                            onClick={() => updateConnectionStatus(connection.memberuuid, 3)}
                                                        >
                                                            <i className="uil uil-x align-middle"></i>
                                                            Ignore
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default PendingConnectionsFunction;
