import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardImg,
  Modal, // Change ReactstrapModal to just Modal
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as Icons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { message, Input, Tooltip, Tag, Collapse } from 'antd';
import { CaretRightOutlined, DownOutlined } from '@ant-design/icons'; // Add this import
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import ProfileModal from '../../Home/Profile_modal.jsx';
import ModalBodySkeleton from '../../Home/skeleton.jsx';
import apiUrl from '../../server.js';
import CmMemberCard from '../cm_members/CmMemberCard';
import { fetchJobDetails, fetchJobDetailsByShareUrl } from './jobServices.js';
import { useApi } from '../../../hooks/useApi';
import { Share2, Copy, Check, ExternalLink } from 'lucide-react';

// Instead, use a placeholder image URL
const defaultJobImage =
  'https://techinnovations.com/wp-content/uploads/2021/09/cropped-TI_logo-e1632418080367.png';

const styles = {
  header: {
    position: 'relative',
    width: '100%',
    height: '40vh',
    minHeight: '300px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4rem',
  },
  headerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.8))',
  },
  contentCard: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '1.5rem',
    marginBottom: '2rem',
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    border: '1px solid #E2E8F0',
  },
  infoItem: {
    backgroundColor: '#F8FAFC',
    borderRadius: '12px',
    padding: '1rem',
    marginBottom: '1rem',
    border: '1px solid #E2E8F0',
  },
  premiumHeader: {
    background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
    padding: '3rem 0',
    color: 'white',
    marginBottom: '2rem',
  },
  jobTitle: {
    fontSize: '2.5rem',
    fontWeight: '700',
    marginBottom: '1rem',
    color: '#1A202C',
  },
  companySection: {
    background: 'white',
    borderRadius: '16px',
    padding: '2rem',
    boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
    marginBottom: '2rem',
  },
  highlightBox: {
    background: '#F7FAFC',
    borderRadius: '12px',
    padding: '1.5rem',
    border: '1px solid #E2E8F0',
    marginBottom: '1rem',
  },
  skillBadge: {
    background: '#EBF8FF',
    color: '#2B6CB0',
    borderRadius: '20px',
    padding: '0.5rem 1rem',
    margin: '0.25rem',
    display: 'inline-block',
    fontSize: '0.9rem',
    fontWeight: '500',
  },
};

const enhancedStyles = {
  mainContainer: {
    maxWidth: '100%',
    padding: '0',
    margin: '0',
    backgroundColor: '#f8fafc',
  },
  heroSection: {
    background: 'linear-gradient(135deg, #1A2980 0%, #26D0CE 100%)',
    padding: '6rem 0 8rem',
    color: 'white',
    width: '100%',
    marginBottom: '-4rem',
    position: 'relative',
    boxShadow: '0 4px 20px rgba(26, 41, 128, 0.2)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.343 0L13.857 8.485 15.272 9.9l7.9-7.9h-.83zm5.657 0L19.515 8.485 20.93 9.9l8.485-8.485h-1.415zM32.372 0L26.515 5.657 27.93 7.07 34.2 0h-1.829zm5.657 0l-6.485 6.485 1.414 1.414L38.444 0h-.415zm5.657 0l-7.071 7.071 1.414 1.414L44 0h-1zM0 0l.828.828-1.414 1.414L0 2.828V0zm54.627 41.373l.83-.828-1.415-1.415-1.414 1.415 2 .828zm-48.254 0l-.83-.828 1.415-1.415 1.414 1.415-2 .828zm41.597 0l3.657-3.657-1.414-1.414-3.657 3.657 1.414 1.414zm-34.94 0l-3.657-3.657 1.415-1.414 3.657 3.657-1.415 1.414zm28.283 0l6.485-6.485-1.414-1.414-7.071 7.071 2 .828zm-21.626 0l-6.485-6.485 1.414-1.414-7.071 7.071-2 .828zm16.686 0L35.03 29.03l-1.414 1.414-7.071 7.071.828 2.828zm-11.03 0l-8.485-8.485 1.415-1.414 7.07 7.07.829 2.829zm-5.656 0L11.03 29.03l-1.414 1.414-5.657 5.657 1.828 2.828zm11.313 0L25.444 29.03l-1.414 1.414L20.273 41.373h2.828zm5.657 0L31.1 29.03l-1.414 1.414-1.414 10.929h2zm5.657 0l4.243-4.243-1.415-1.414v5.657h-2.828zM0 41.373l.828-.828-1.414-1.415L0 39.13v2.243z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E")`,
      opacity: 0.3,
      mixBlendMode: 'overlay',
    },
  },
  contentWrapper: {
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '0 2rem',
    position: 'relative',
  },
  mainCard: {
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    border: 'none',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
    margin: '-4rem auto 3rem', // Increased bottom margin
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  section: {
    borderBottom: '1px solid #E2E8F0',
    padding: '2rem',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  heroShareButton: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    color: 'white',
    padding: '0.75rem 1.5rem',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      transform: 'translateY(-2px)',
    },
  },
};

// Add a new styles object for section headers
const sectionHeaderStyles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: '1.5rem',
  },
  iconContainer: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    backgroundColor: 'rgba(66, 153, 225, 0.1)',
    color: '#2B6CB0',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#2D3748',
    margin: 0,
  },
};

const JobDetailsPage = () => {
  const { jobuuid, job_share_url } = useParams(); // Changed from id to jobuuid
  const location = useLocation(); // Add this
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  // alert(JSON.stringify(job.company_url));
  const [loading, setLoading] = useState(true);

  // Reuse the same states from JobCard
  const [memberModal, setModal] = useState(false);
  const [memberFullData, setMemberFullData] = useState([]);
  const [isModalLoading, setModalLoading] = useState(true);

  const [currentLocation, setCurrentLocation] = useState({});
  const [nativeLocation, setNativeLocation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [skillsExpanded, setSkillsExpanded] = useState(false);
  const { callApi } = useApi();

  const fetchJobData = async () => {
    try {
      let jobDetails;
      // alert("Location Pathname: " + location.pathname);
      if (location.pathname.startsWith('/job/')) {
        // Shared URL based fetch
        jobDetails = await fetchJobDetailsByShareUrl(callApi, job_share_url);
      } else {
        // UUID-based URL
        jobDetails = await fetchJobDetails(callApi, jobuuid);
      }
      // alert("Event Response: " + JSON.stringify(response));
      setJob(jobDetails);
      if (jobDetails.eventorgurl) {
        fetchUrlPreview(jobDetails.eventorgurl);
      }
    } catch (error) {
      message.error('Failed to fetch jobs');
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch job details
  useEffect(() => {
    fetchJobData();
  }, [jobuuid]);

  // Reuse all helper functions from JobCard
  const formatSalary = (salary, currency = '₹') => {
    return salary ? `${currency}${salary}` : 'Not Disclosed';
  };

  const createMarkup = (content) => {
    return { __html: content };
  };

  const handleShare = () => {
    const shareUrl = `${window.location.protocol}//${window.location.host}/job/${job.share_url || job.id}`;
    setShareUrl(shareUrl);
    setIsShareModalVisible(true);
  };

  const handleCopy = () => {
    setCopied(true);
    message.success('Link copied to clipboard!');
    setTimeout(() => setCopied(false), 3000);
  };

  const handleNativeShare = async () => {
    try {
      await navigator.share({
        title: job.job_title,
        text: `Check out this job: ${job.job_title}`,
        url: shareUrl,
      });
      message.success('Job shared successfully!');
      setIsShareModalVisible(false);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }
      message.error('Failed to share job');
      console.error('Share failed:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <div style={enhancedStyles.mainContainer}>
      {/* Hero Section */}
      <div style={enhancedStyles.heroSection}>
        <div style={enhancedStyles.contentWrapper} className="hero-wrapper">
          {/* Desktop Share Button */}
          <Button
            color="light"
            className="share-button desktop-share"
            onClick={handleShare}
            style={{
              position: 'absolute',
              top: '2rem',
              right: '2rem',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255, 255, 255, 0.2)',
              color: 'white',
              padding: '0.75rem 1.5rem',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              zIndex: 10,
            }}
          >
            <Share2 size={18} />
            Share Job
          </Button>

          <div className="mobile-hero-content">
            <div className="hero-content">
              {/* Job Title */}
              <h1 className="job-title gradient-text">{job.job_title}</h1>

              {/* Company Info */}
              <div className="company-details">
                <h2 className="company-name">
                  <Icons.FaBuilding className="company-icon" />
                  {job.company_name}
                </h2>
                <div className="job-meta">
                  <span className="location">
                    <Icons.FaMapMarkerAlt />
                    {job.location_address}
                  </span>
                  <span className="divider">•</span>
                  <span className="job-type">
                    <Icons.FaBriefcase />
                    {job.employment_type}
                  </span>
                </div>
              </div>
            </div>

            {/* Mobile Share Button */}
            <Button
              color="primary"
              className="share-button mobile-share"
              onClick={handleShare}
              style={{
                display: 'none', // Will be shown via media query
                width: '100%',
                marginTop: '1rem',
                padding: '0.75rem',
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Changed to transparent white
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                color: 'white',
                borderRadius: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <Share2 size={18} />
              Share Job
            </Button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div style={enhancedStyles.contentWrapper}>
        <Row>
          {/* Job Details Column */}
          <Col lg={8}>
            {/* Quick Stats Card */}
            <Card style={enhancedStyles.mainCard}>
              <div className="quick-stats p-4">
                <div className="section-header">
                  <div className="section-header-content">
                    <div className="section-icon">
                      <Icons.FaChartBar size={20} />
                    </div>
                    <h3 className="section-title">Quick Overview</h3>
                  </div>
                </div>
                <Row>
                  {[
                    {
                      icon: <Icons.FaMoneyBillWave />,
                      label: 'Salary',
                      value: formatSalary(job.salary_range),
                    },
                    {
                      icon: <Icons.FaUserClock />,
                      label: 'Experience',
                      value: `${job.experience_required} Years`,
                    },
                    // {
                    //   icon: <Icons.FaUserFriends />,
                    //   label: 'Openings',
                    //   value: job.openings || 'Not Specified',
                    // },
                    // Add this new entry

                    {
                      icon: <Icons.FaGlobe />,
                      label: 'Company URL',
                      value: (
                        <a
                          href={job.company_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary text-decoration-none"
                          style={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {job.company_url || 'Not Available'}
                        </a>
                      ),
                    },
                    {
                      icon: <Icons.FaCalendarAlt />,
                      label: 'Expires at',
                      value: job.expires_at
                        ? new Date(job.expires_at).toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })
                        : 'Open Until Filled',
                    },
                    // {
                    //   icon: <Icons.FaLinkedin />,
                    //   label: 'LinkedIn',
                    //   value: (
                    //     <a
                    //       href={job.linkedin_url}
                    //       target="_blank"
                    //       rel="noopener noreferrer"
                    //       className="text-primary text-decoration-none"
                    //       style={{
                    //         maxWidth: '200px',
                    //         overflow: 'hidden',
                    //         textOverflow: 'ellipsis',
                    //       }}
                    //     >
                    //       {job.linkedin_url ? 'View Profile' : 'Not Available'}
                    //     </a>
                    //   ),
                    // },
                  ].map((stat, index) => (
                    <Col key={index} md={6} className="mb-4">
                      <div className="stat-card p-3 rounded-3 bg-light">
                        <div className="d-flex align-items-center gap-3">
                          <div className="stat-icon">{stat.icon}</div>
                          <div className="flex-grow-1">
                            <div className="stat-label text-muted">
                              {stat.label}
                            </div>
                            <div className="stat-value fw-bold text-truncate">
                              {stat.value}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Card>

            {/* Job Description Card */}
            <Card style={{ ...enhancedStyles.mainCard, margin: '2rem 0' }}>
              <div className="job-description p-4">
                <div className="section-header">
                  <div className="section-header-content">
                    <div className="section-icon">
                      <Icons.FaFileAlt size={20} />
                    </div>
                    <h3 className="section-title">Job Description</h3>
                  </div>
                  <button
                    className="mobile-toggle-btn"
                    onClick={() => setDescriptionExpanded(!descriptionExpanded)}
                  >
                    <DownOutlined
                      className={`toggle-icon ${descriptionExpanded ? 'expanded' : ''}`}
                    />
                  </button>
                </div>
                <div
                  className={`content-wrapper ${descriptionExpanded ? 'expanded' : ''}`}
                >
                  <div
                    className="description-content"
                    dangerouslySetInnerHTML={createMarkup(job.description)}
                  />
                </div>
              </div>
            </Card>

            {/* Skills Section Card */}
            <Card
              style={{
                ...enhancedStyles.mainCard,
                margin: '2rem 0 3rem', // Added bottom margin of 3rem
              }}
            >
              <div className="skills-section p-4">
                <div className="section-header">
                  <div className="section-header-content">
                    <div className="section-icon">
                      <Icons.FaCogs size={20} />
                    </div>
                    <h3 className="section-title">Required Skills</h3>
                  </div>
                  <button
                    className="mobile-toggle-btn"
                    onClick={() => setSkillsExpanded(!skillsExpanded)}
                  >
                    <DownOutlined
                      className={`toggle-icon ${skillsExpanded ? 'expanded' : ''}`}
                    />
                  </button>
                </div>
                <div
                  className={`content-wrapper ${skillsExpanded ? 'expanded' : ''}`}
                >
                  <div className="d-flex flex-wrap gap-2 mb-3">
                    {' '}
                    {/* Added bottom margin */}
                    {job.skills_required.map((skill, index) => (
                      <div
                        key={index}
                        className="skill-tag"
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          padding: '0.75rem 1.25rem',
                          borderRadius: '12px',
                          backgroundColor: 'rgba(66, 153, 225, 0.08)',
                          color: '#2B6CB0',
                          border: '1px solid rgba(66, 153, 225, 0.2)',
                          margin: '0.375rem',
                          transition: 'all 0.2s ease',
                          cursor: 'default',
                        }}
                      >
                        <Icons.FaCheckCircle size={14} className="me-2" />
                        {skill}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
          </Col>

          {/* Sidebar Column */}
          <Col lg={4}>
            <div className="sticky-sidebar">
              <Card style={enhancedStyles.mainCard}>
                <CardBody>
                  {/* Add this section header */}
                  <div className="section-header mb-4">
                    <div className="section-header-content">
                      <div className="section-icon">
                        <Icons.FaUserTie size={20} />
                      </div>
                      <h3 className="section-title">Posted By</h3>
                    </div>
                  </div>

                  <CmMemberCard
                    member={{
                      memberuuid: job.referrer_uuid,
                      firstname: job.firstname,
                      currentage: job.currentage || 0,
                      height: job.height || 0,
                      gender: job.gender == 1 ? 'Male' : 'Female',
                      currentlocationname: job.currentlocationname || '',
                      jobtitle: job.jobtitle,
                      jobprofession: job.jobprofession || '',
                      skillset: job.skillset || [],
                      photothumb1: job.photothumb1 || null,
                      photothumb2: job.photothumb2 || null,
                      photothumb3: job.photothumb3 || null,
                      likedstatus: false,
                      ismentor: false,
                      companyname: job.companyname || '',
                    }}
                    currentPage={1}
                    searchCriteria={{}}
                    configurationData={{
                      memberPhotosBaseUrl: '', // Add your base URL if needed
                      defaultPhotoUrl: defaultJobImage,
                    }}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        {/* Add Back Button */}
        <div className="back-button-container">
          <Button
            color="light"
            className="back-button"
            onClick={() => navigate(-1)}
          >
            <Icons.FaArrowLeft className="me-2" />
            Back to Jobs
          </Button>
        </div>
      </div>

      {/* Modals */}
      <Modal
        isOpen={isShareModalVisible}
        toggle={() => setIsShareModalVisible(false)}
        centered
      >
        <ModalHeader toggle={() => setIsShareModalVisible(false)}>
          Share Job Opening
        </ModalHeader>
        <ModalBody className="p-4">
          <div className="d-flex gap-2 align-items-center mb-4">
            <Input
              value={shareUrl}
              readOnly
              className="flex-grow-1"
              style={{
                backgroundColor: '#f8f9fa',
                padding: '0.75rem',
                borderRadius: '8px',
              }}
            />
            <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
              <Button
                color={copied ? 'success' : 'primary'}
                className="d-flex align-items-center gap-2"
                style={{ whiteSpace: 'nowrap' }}
              >
                {copied ? (
                  <>
                    <Check size={16} />
                    Copied!
                  </>
                ) : (
                  <>
                    <Copy size={16} />
                    Copy
                  </>
                )}
              </Button>
            </CopyToClipboard>
          </div>

          {navigator.share && (
            <div className="mt-4 border-top pt-4">
              <h6 className="mb-3">Share via</h6>
              <Button
                color="primary"
                outline
                className="w-100"
                onClick={handleNativeShare}
              >
                <Share2 size={16} className="me-2" />
                Share on your device
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>

      {/* CSS */}
      <style>
        {`
          .gradient-text {
            background: linear-gradient(45deg, #fff, rgba(255,255,255,0.8));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .stat-icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(13, 110, 253, 0.1);
            border-radius: 10px;
            color: #0d6efd;
          }

          .section-title {
            position: relative;
            padding-bottom: 1rem;
            margin-bottom: 2rem;
            font-size: 1.5rem;
            font-weight: 600;
            color: #2D3748;
          }

          .section-title::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60px;
            height: 4px;
            background: linear-gradient(90deg, #0d6efd, #26D0CE);
            border-radius: 2px;
          }

          .description-content {
            line-height: 1.8;
            color: #4A5568;
          }

          .sticky-sidebar {
            position: sticky;
            top: 2rem;
          }

          @media (max-width: 991px) {
            .sticky-sidebar {
              position: static;
              margin-top: 2rem;
            }
          }

          /* Add responsive styles */
          @media (max-width: 768px) {
            .hero-content {
              padding: 1rem;
            }

            .company-info {
              flex-direction: column;
              align-items: flex-start;
            }

            .stat-card {
              margin-bottom: 1rem;
            }
          }

          .stat-value a:hover {
            text-decoration: underline !important;
          }

          .stat-card {
            transition: transform 0.2s ease;
          }

          .stat-card:hover {
            transform: translateY(-2px);
          }

          .stat-value {
            word-break: break-word;
          }

          .hero-share-button {
            transition: all 0.3s ease !important;
          }

          .hero-share-button:hover {
            background-color: rgba(255, 255, 255, 0.2) !important;
            transform: translateY(-2px);
          }

          @media (max-width: 768px) {
            .hero-share-button {
              position: static !important;
              top: 0 !important;
              right: 0 !important;
              margin-bottom: 1rem;
              width: 100%;
              justify-content: center;
            }
          }

          .hero-content {
            padding: 2rem 0;
            position: relative;
            z-index: 1;
          }

          .job-title {
            font-size: 2.75rem;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 1.5rem;
            max-width: 800px;
          }

          .company-details {
            margin-top: 1.5rem;
          }

          .company-name {
            font-size: 1.5rem;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.9);
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;
          }

          .company-icon {
            font-size: 1.25rem;
            opacity: 0.9;
          }

          .job-meta {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            color: rgba(255, 255, 255, 0.8);
            flex-wrap: wrap;
          }

          .job-meta span {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .divider {
            color: rgba(255, 255, 255, 0.4);
          }

          @media (max-width: 768px) {
            .hero-content {
              padding: 1rem 0 2rem;
            }

            .job-title {
              font-size: 2rem;
              margin-bottom: 1rem;
            }

            .company-name {
              font-size: 1.25rem;
              gap: 0.5rem;
            }

            .company-icon {
              font-size: 1.1rem;
            }

            .job-meta {
              gap: 1rem;
            }

            .divider {
              display: none;
            }

            .job-meta {
              flex-direction: column;
              align-items: flex-start;
              gap: 0.75rem;
            }

            .hero-share-button {
              position: static !important;
              margin-bottom: 1.5rem;
              width: 100%;
              justify-content: center;
            }

            .hero-section {
              padding: 4rem 0 6rem !important;
            }
          }

          @media (max-width: 480px) {
            .job-title {
              font-size: 1.75rem;
            }

            .hero-content {
              padding: 0;
            }
          }

          .mobile-share-container {
            display: none;
            padding: 1rem 2rem;
            background: #fff;
            margin-top: -2rem;
            margin-bottom: 2rem;
            position: relative;
            z-index: 10;
          }

          .mobile-share-button {
            width: 100%;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 8px;
            background: linear-gradient(135deg, #1A2980 0%, #26D0CE 100%);
            border: none;
            transition: transform 0.2s ease;
            margin-top: auto; // Push to bottom
            margin-bottom: 1.5rem; // Added bottom margin
          }

          @media (max-width: 768px) {
            .desktop-share {
              display: none !important;
            }

            .mobile-share-container {
              display: block;
            }

            .hero-section {
              margin-bottom: 0 !important;
            }
          }

          .hero-section {
            padding: 3rem 0 4rem !important; // Reduced padding
          }

          .hero-wrapper {
            display: flex;
            flex-direction: column;
          }

          .mobile-hero-content {
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }

          .mobile-share-button {
            display: none;
            width: 100%;
            padding: 1rem;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 8px;
            background: linear-gradient(135deg, #1A2980 0%, #26D0CE 100%);
            border: none;
            transition: transform 0.2s ease;
            margin-top: auto; // Push to bottom
          }

          .job-description {
            margin-bottom: 2rem; // Added margin to job description section
          }

          @media (max-width: 768px) {
            .hero-section {
              padding: 2rem 0 3rem !important;
              margin-bottom: 0;
            }

            .mobile-hero-content {
              min-height: 200px; // Ensure enough space for content
              justify-content: space-between;
              padding-bottom: 1.5rem; // Added padding at bottom
            }

            .desktop-share {
              display: none !important;
            }

            .mobile-share-button {
              display: flex;
              margin-top: 2rem;
              margin-bottom: 1rem; // Adjusted for mobile
            }

            .hero-content {
              padding: 0;
            }

            .job-title {
              font-size: 1.75rem;
              margin-bottom: 1rem;
            }
          }

          @media (max-width: 768px) {
            .skills-section {
              margin-bottom: 2rem !important;
            }
          }

          .mobile-toggle-btn {
            display: none;
            background: none;
            border: none;
            color: #0d6efd;
            padding: 0.5rem;
            cursor: pointer;
          }

          .toggle-icon {
            font-size: 1.2rem;
            transition: transform 0.3s ease;
          }

          .toggle-icon.expanded {
            transform: rotate(180deg); // Changed from 90deg to 180deg for up/down rotation
          }

          @media (max-width: 768px) {
            .mobile-toggle-btn {
              display: block;
              margin-left: 1rem;
            }

            .section-title {
              margin-bottom: 0;
            }

            .section-header {
              padding-bottom: 0;
            }

            .content-wrapper {
              max-height: 0;
              opacity: 0;
              margin-top: 0;
              padding-top: 0;
            }

            .content-wrapper.expanded {
              max-height: 2000px;
              opacity: 1;
              margin-top: 1.5rem;
            }

            .section-title::after {
              display: none;
            }

            .toggle-icon {
              color: #0d6efd;
            }
          }

          .section-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: rgba(13, 110, 253, 0.1);
            border-radius: 10px;
            color: #0d6efd;
          }

          @media (max-width: 768px) {
            .section-icon {
              width: 32px;
              height: 32px;
            }
            
            .section-icon svg {
              width: 16px;
              height: 16px;
            }
          }

          .section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.5rem;
          }

          .section-header-content {
            display: flex;
            align-items: center;
            gap: 1rem;
          }

          .section-title {
            font-size: 1.5rem;
            font-weight: 600;
            color: #2D3748;
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          }

          @media (max-width: 768px) {
            .section-title {
              font-size: 1.25rem;
              font-weight: 600;
              letter-spacing: -0.01em;
              color: #1A202C;
            }

            .section-icon {
              width: 44px;
              height: 44px;
            }

            .section-icon svg {
              width: 16px;
              height: 16px;
            }

            .section-header {
             
              margin-bottom: 0;
            }
          }

          .sticky-sidebar .section-header {
            padding: 0;
            margin-bottom: 1.5rem;
          }

          .sticky-sidebar .section-title {
            font-size: 1.25rem;
            color: #2D3748;
            margin: 0;
          }

          @media (max-width: 768px) {
            .sticky-sidebar .section-header {
              margin-bottom: 1rem;
            }
          }

          .back-button-container {
            display: flex;
            justify-content: center;
            margin: 2rem 0 4rem;
          }

          .back-button {
            display: flex;
            align-items: center;
            padding: 0.75rem 1.5rem;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 8px;
            border: 1px solid #e2e8f0;
            background: white;
            color: #475569;
            transition: all 0.3s ease;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          }

          .back-button:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            background: #f8fafc;
            color: #1e293b;
          }

          @media (max-width: 768px) {
            .back-button-container {
              margin: 1.5rem 0 3rem;
            }
            
            .back-button {
              width: 100%;
              justify-content: center;
            }
          }

          /* Share button styles */
          .share-button {
            transition: all 0.3s ease !important;
          }

          .share-button:hover {
            transform: translateY(-2px);
            background-color: rgba(255, 255, 255, 0.2) !important;
          }

          /* Mobile styles */
          @media (max-width: 768px) {
            .desktop-share {
              display: none !important;
            }

            .mobile-share {
              display: flex !important;
              margin: 1rem 0;
              background-color: rgba(255, 255, 255, 0.1) !important;
              backdrop-filter: blur(10px);
              border: 1px solid rgba(255, 255, 255, 0.2);
              color: white !important;
            }

            .mobile-share:hover {
              background-color: rgba(255, 255, 255, 0.2) !important;
              color: white !important;
            }

            .mobile-hero-content {
              padding: 1rem;
            }
          }

          /* Modal styles */
          .modal-content {
            border-radius: 12px;
            border: none;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          }

          .modal-header {
            border-bottom: 1px solid #e2e8f0;
            padding: 1.5rem;
          }

          .modal-body {
            padding: 1.5rem;
          }

          .copy-button {
            min-width: 100px;
          }
        `}
      </style>
    </div>
  );
};

export default JobDetailsPage;
