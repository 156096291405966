import React, { useEffect, useMemo, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import { favouriteService } from '../../../services/api';
import {
    Row, Col
} from 'reactstrap';
import { Link } from 'react-router-dom';
import CommonModal from '../modal.js';
import ContentLoader, { List } from 'react-content-loader';
import '../css/userHomePage.css';
import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';

const FavouriteListingFunction = () => {
  const { callApi } = useApi();
  const [openModal, setOpenModal] = useState(false);
  const [likedmemberuuid, setlikedmemberuuid] = useState({});
  const [likeStatus, setLikedStatus] = useState('Like');
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [favouriteList, setFavouriteList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showSetting, setShowSettings] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const itemsPerPage = 10;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await callApi(() => 
        favouriteService.getFavouriteMembers(localStorage.getItem('memberID'))
      );

      if (response?.details?.length > 0) {
        setFavouriteList(response.details);
        setTotalPages(Math.ceil(response.totalCount / itemsPerPage));
      } else {
        setFavouriteList([]);
      }
    } catch (error) {
      console.error('Error fetching favourites:', error);
      setError({
        title: 'Error',
        message: error.response?.data?.message || 'Failed to fetch favourites'
      });
    } finally {
      setLoading(false);
    }
  };

  const favoriteMember = async () => {
    try {
      const request = {
        likedmemberuuid: likedmemberuuid,
        memberuuid: localStorage.getItem('memberID')
      };

      await callApi(() => 
        favouriteService.addFavouriteMember(request)
      );

      await fetchData();
      setOpenModal(false);
      setSuccess({
        title: 'Success',
        message: 'Member added to favourites'
      });
    } catch (error) {
      setError({
        title: 'Error',
        message: error.response?.data?.message || 'Failed to update favourite'
      });
    }
  };

  // Client side pagination logic with useMemo
  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return favouriteList.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, itemsPerPage, favouriteList]);

  const pageNumbers = useMemo(() => {
    const pages = [1];
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(currentPage + 1, totalPages - 1);

    if (startPage > 2) pages.push('...');
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    if (endPage < totalPages - 1) pages.push('...');
    if (totalPages > 1) pages.push(totalPages);
    return pages;
  }, [currentPage, totalPages]);

  // ...rest of the component's UI rendering code remains unchanged...
  // This includes the display logic, pagination UI, modals etc.

  const displayImage = (imagepath, gender) => {
    if (!imagepath) {
      if (gender.toLowerCase() == 'male') {
        return manavatar;
      } else if (gender.toLowerCase() == 'female') {
        return femaleavatar;
      } else {
        return transgenderavatar;
      }
    } else {
      return imagepath;
    }
  };

  return (
    <>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={() => setError(null)}
          status="error"
        />
      )}
      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={() => setSuccess(null)}
          status="success"
        />
      )}
      <Col lg="12" className="Col_12">
        <Row>
          <Col md={12} xs={12} className="inner_row pb-4">
            {isLoading ? (
              <Row className="mt-3">
                <Col md={12}>
                  <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">
                    <Row className="w-100">
                      <Col md={4}>
                        <List />
                      </Col>
                      <Col md={4}></Col>
                      <Col md={4}>
                        <List />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                {favouriteList.length === 0 ? (
                  <div className="text-center mt-3 label_text">
                    No Favourite Members!
                  </div>
                ) : (
                  <Row>
                    {currentItems.map((member, key) => (
                      <Col xs={12} className="mt-4 pt-2" key={key}>
                        <div className="job-box bg-white d-md-flex align-items-center shadow rounded p-4 position-relative ">
                          {/* Render member details */}
                          <img
                            src={displayImage(
                                member.photothumb1,
                                member.gender,
                            )}
                            className="avatar avatar-md-sm favourite_avatar_img"
                            alt=""
                            />
                          <div className="ms-md-4 mt-4 mt-sm-0">
                            <a className="text-dark h5">
                              {member.firstname} {member.lastname}
                            </a>
                            <ul className="list-unstyled mb-0 mt-2">
                              <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                                <i className="uil uil-user-plus text-primary"></i>
                                <Link to="#" className="text-dark">
                                  {member.membertype}
                                </Link>
                              </li>
                              <li className="d-lg-inline text-muted h6 mb-0">
                                <i className="uil uil-bill text-primary"></i>
                                {member.gender}
                              </li>
                            </ul>
                          </div>

                          <div className="position-absolute top-0 end-0 mt-3 me-3">
                            <button
                              title="Remove"
                              className="btn btn-danger text-white"
                              onClick={() => handleRemoveFavourite(member.memberuuid)}
                            >
                              <i className="uil uil-trash align-middle"></i>
                              Remove
                            </button>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default FavouriteListingFunction;
