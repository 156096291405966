import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Input, Button, Card, CardBody, Label } from 'reactstrap';
import { Search, CheckCircle, MapPin } from 'lucide-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { fetchJobCategories } from './jobServices';
import { message } from 'antd';
import { useApi } from '../../../hooks/useApi';

const SearchJobs = ({ onSearch }) => {
  const [jobCategories, setJobCategories] = useState([]);
  const [filters, setFilters] = useState({
    searchText: '',
    location: '',
    locationAddress: '',
    categories: [],
  });
  const searchTextRef = useRef(null);
  const { callApi } = useApi();

  useEffect(() => {
    loadJobCategories();
  }, []);

  const loadJobCategories = async () => {
    try {
      const categories = await fetchJobCategories(callApi);
      setJobCategories(categories);
    } catch (error) {
      message.error('Failed to load job categories');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLocationSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const placeId = results[0].place_id;
      const addressComponents = results[0].address_components;

      // Only get city, state and country
      const city = addressComponents.find((component) =>
        component.types.includes('locality'),
      )?.long_name;
      const state = addressComponents.find((component) =>
        component.types.includes('administrative_area_level_1'),
      )?.long_name;
      const country = addressComponents.find((component) =>
        component.types.includes('country'),
      )?.long_name;

      // Filter out undefined values and create location string
      const locationParts = [city, state, country].filter(Boolean);
      const locationName = locationParts.join(', ');

      setFilters((prev) => ({
        ...prev,
        location: { place_id: placeId },
        locationAddress: locationName || address, // fallback to full address if no structured data
      }));
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchText = searchTextRef.current.value;
    setFilters((prev) => ({ ...prev, searchText }));

    onSearch({
      ...filters,
      searchText,
    });
  };

  const toggleCategory = (categoryId) => {
    setFilters((prev) => ({
      ...prev,
      categories: prev.categories.includes(categoryId)
        ? prev.categories.filter((id) => id !== categoryId)
        : [...prev.categories, categoryId],
    }));
  };

  const handleResetFilters = () => {
    const defaultFilters = {
      searchText: '',
      location: '',
      locationAddress: '',
      categories: [],
    };
    setFilters(defaultFilters);
    onSearch(defaultFilters);
  };

  return (
    <Card
      className="border-1 mb-3 shadow-md"
      style={{
        backgroundColor: '#ffffff',
        boxShadow: '0 8px 24px rgba(140,152,164,.2)',
      }}
    >
      <CardBody className="p-3">
        {/* Category Section with responsive behavior */}
        <div className="mb-3 position-relative">
          <div className="category-container">
            <div className="category-wrapper">
              {jobCategories.map((category) => (
                <Button
                  key={category.category_uuid}
                  color={
                    filters.categories.includes(category.category_uuid)
                      ? 'primary'
                      : 'light'
                  }
                  className="category-button d-flex align-items-center gap-2 px-2 py-1 rounded-pill"
                  onClick={() => toggleCategory(category.category_uuid)}
                  style={{
                    whiteSpace: 'nowrap',
                    transition: 'all 0.3s ease',
                    borderColor: filters.categories.includes(
                      category.category_uuid,
                    )
                      ? 'transparent'
                      : '#edf2ff',
                    margin: '0.2rem',
                    fontSize: '0.875rem',
                    backgroundColor: filters.categories.includes(
                      category.category_uuid,
                    )
                      ? '#4263eb'
                      : '#edf2ff',
                    fontWeight: filters.categories.includes(
                      category.category_uuid,
                    )
                      ? '500'
                      : '400',
                    color: filters.categories.includes(category.category_uuid)
                      ? '#fff'
                      : '#2b3044',
                  }}
                >
                  <Search
                    size={16}
                    className={
                      filters.categories.includes(category.category_uuid)
                        ? 'text-white'
                        : ''
                    }
                    style={{
                      opacity: filters.categories.includes(
                        category.category_uuid,
                      )
                        ? 1
                        : 0.85,
                      color: filters.categories.includes(category.category_uuid)
                        ? '#fff'
                        : '#2b3044',
                    }}
                  />
                  <span>{category.category_name}</span>
                  {filters.categories.includes(category.category_uuid) && (
                    <CheckCircle size={16} className="text-white" />
                  )}
                </Button>
              ))}
            </div>
          </div>
        </div>

        {/* Search form */}
        <form onSubmit={handleSubmit}>
          <Row className="g-3 mb-0">
            <Col xs={12} md={5}>
              <div className="search-input-group">
                <span className="search-icon">
                  <Search size={18} className="search-icon-svg" />
                </span>
                <Input
                  type="text"
                  name="searchText"
                  placeholder="Search jobs by title, company, or skills..."
                  defaultValue={filters.searchText}
                  innerRef={searchTextRef}
                  className="premium-input"
                />
              </div>
            </Col>
            <Col xs={12} md={5}>
              <PlacesAutocomplete
                value={filters.locationAddress}
                onChange={(address) =>
                  setFilters((prev) => ({ ...prev, locationAddress: address }))
                }
                onSelect={handleLocationSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="search-input-group">
                    <span className="search-icon">
                      <MapPin size={18} className="search-icon-svg" />
                    </span>
                    <Input
                      {...getInputProps({
                        placeholder: 'Search location...',
                        className: 'premium-input',
                      })}
                    />
                    <div>
                      {loading && <div className="p-2">Loading...</div>}
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className: `suggestion-item ${
                              suggestion.active ? 'active' : ''
                            }`,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
            <Col xs={12} md={2}>
              <Button color="primary" type="submit" className="search-button">
                <Search size={16} />
                <span>Search</span>
              </Button>
            </Col>
          </Row>
        </form>
      </CardBody>

      <style>
        {`
          .category-container {
            width: 100%;
            position: relative;
          }

          .category-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            justify-content: flex-start;
          }

          /* Mobile styles */
          @media (max-width: 768px) {
            .category-wrapper {
              flex-wrap: nowrap;
              overflow-x: auto;
              -webkit-overflow-scrolling: touch;
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none;  /* Internet Explorer 10+ */
              padding-bottom: 0.5rem;
            }

            .category-wrapper::-webkit-scrollbar {
              display: none; /* WebKit */
            }

            .category-button {
              flex: 0 0 auto;
            }
          }

          .search-input-group {
            position: relative;
            transition: all 0.3s ease;
          }

          .search-icon {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
          }

          .search-icon-svg {
            color: #6b7280;
          }

          .premium-input {
            height: 48px;
            padding-left: 40px !important;
            border-radius: 12px !important;
            border: 1px solid #e5e7eb !important;
            background-color: #f9fafb !important;
            font-size: 0.95rem !important;
            transition: all 0.3s ease !important;
          }

          .premium-input:focus {
            background-color: #ffffff !important;
            border-color: #4263eb !important;
            box-shadow: 0 0 0 4px rgba(66, 99, 235, 0.1) !important;
          }

          .premium-input::placeholder {
            color: #9ca3af;
          }

          .search-button {
            height: 48px;
            width: auto;
            min-width: 120px;
            padding: 0 24px;
            border-radius: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            transition: all 0.3s ease;
            background: linear-gradient(135deg, #4263eb 0%, #3b5bdb 100%);
            border: none;
          }

          .search-button:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba(66, 99, 235, 0.2);
          }

         

          .suggestion-item {
            padding: 12px 16px;
            cursor: pointer;
            transition: all 0.2s ease;
          }

          .suggestion-item.active {
            background-color: #f3f4f6;
          }

          .suggestion-item:hover {
            background-color: #f3f4f6;
          }

          @media (max-width: 768px) {
            .premium-input {
              height: 44px;
            }

            .search-button {
              height: 44px;
              width: 100%;
            }
          }
        `}
      </style>
    </Card>
  );
};

export default SearchJobs;
