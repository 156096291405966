import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CommonModal from '../Home/modal.js';
import PersonalDetails from '../Home/Profile/Personal.js';
import CareerDetails from '../Home/Profile/Career.js';
import EducationDetails from '../Home/Profile/Education.js';
import RegionalDetails from '../Home/Profile/Regional.js';
import LifestyleDetails from '../Home/Profile/Lifestyle.js';
import SpiritualDetails from '../Home/Profile/Spiritual.js';
import FamilyDetails from '../Home/Profile/FamilyB.js';
import SocialDetails from '../Home/Profile/Socials.js';
import UploadAds from '../Home/Profile/UploadAds.jsx';

import Profilecard from '../Home/Profile_card.jsx';
import 'react-datepicker/dist/react-datepicker.css';
//Import Icons
import '../Home/css/userHomePage.css';
import ImgCrop from 'antd-img-crop';
import { Button, Upload, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import DefaultPicture from './DefaultPicture.js';
import apiUrl from '../server';
import photosIcon from '../../assets/images/MettaMateImages/phtotframe.png';
import personalInfoIcon from '../../assets/images/MettaMateImages/personalInfo.jpg';
import careerInfo from '../../assets/images/MettaMateImages/career.png';
import educationIcon from '../../assets/images/MettaMateImages/education.png';
import locationIcon from '../../assets/images/MettaMateImages/location.png';
import lifestyleIcon from '../../assets/images/MettaMateImages/lifestyle.png';
import spiritualIcon from '../../assets/images/MettaMateImages/spiritual.png';
import socialsIcon from '../../assets/images/MettaMateImages/socialIcon.png';
import adsIcon from '../../assets/images/MettaMateImages/ads.png';
import familyIcon from '../../assets/images/MettaMateImages/familyb.png';
import { useApi } from '../../hooks/useApi';
import { profileService } from '../../services/api';

const UserProfileEditFunction = () => {
  const { callApi } = useApi();
  let configurationData = JSON.parse(localStorage.getItem('configurationData'));


  const [visibleSection, setVisibleSection] = useState(null);
  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);
  const [file3, setFile3] = useState([]);
  const [imageIndex, setImageIndex] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [previewmodalVisible, previewsetModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileToRemove, setFileToRemove] = useState(null);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();


  const [configuredata, setConfigureData] = useState({
    memberuuid: '',
    firstname: '',
    lastname: '',
    photopath1: '',
    photopath2: '',
    photopath3: '',
    subscription: '',
    jobprofession: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    recommended: '',
  });

  useEffect(() => {
    configurationData = JSON.parse(localStorage.getItem('configurationData'));
    fetchconfigureDetails();
  }, []);


  const toggleVisibility = (section) => {
    setVisibleSection(visibleSection === section ? null : section);
  };

  const fetchconfigureDetails = async () => {
    setFile1([]);
    setFile2([]);
    setFile3([]);
    try {
      const response = await callApi(() =>
        profileService.getShortProfile(localStorage.getItem('memberID'))
      );

      let fileurl1 = response.photopath1
        ? await getPhotoUrlDetails(response.photopath1)
        : undefined;
      let fileurl2 = response.photopath2
        ? await getPhotoUrlDetails(response.photopath2)
        : undefined;
      let fileurl3 = response.photopath3
        ? await getPhotoUrlDetails(response.photopath3)
        : undefined;

      setConfigureData(response);
      localStorage.setItem('configurationData', JSON.stringify(response));

      // Update files state based on URLs
      if (fileurl1) {
        setFile1([{ name: response.photopath1, url: fileurl1 }]);
      }
      if (fileurl2) {
        setFile2([{ name: response.photopath2, url: fileurl2 }]);
      }
      if (fileurl3) {
        setFile3([{ name: response.photopath3, url: fileurl3 }]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getPhotoUrlDetails = async (fileName) => {
    try {
      const response = await callApi(() => 
        profileService.getPhotoUrl(fileName)
      );
      return response.filepath;
    } catch (error) {
      console.error('Error fetching photo URL:', error);
    }
  };

  const handleChange =
    (fileListSetter, imageIndex) =>
      ({ fileList: newFileList }) => {
        fileListSetter(newFileList);
        setImageIndex(imageIndex);
      };
  const handleUploadImage = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('memberUUID', localStorage.getItem('memberID'));
    formData.append('imageindex', imageIndex);

    try {
      const response = await callApi(() => 
        profileService.uploadImage(formData)
      );
      
      onSuccess();
      fetchconfigureDetails();

      if (imageIndex == 0) {
        setConfigureData({ ...configuredata, photopath1: response.filename });
        localStorage.setItem('profilePicture', response.filePath);
      } else if (imageIndex == 1) {
        setConfigureData({ ...configuredata, photopath2: response.filename });
      } else if (imageIndex == 2) {
        setConfigureData({ ...configuredata, photopath3: response.filename });
      }

      setSuccess({
        title: 'Upload Complete!',
        message: 'Your Image has been uploaded Successfully!',
      });
    } catch (error) {
      onError();
      setError({
        title: 'Upload Failed!',
        message: error.response?.data?.message || 'Failed to upload image',
      });
    }
  };

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onPreview = async (file) => {
    // const src = file.url || (await getSrcFromFile(file));
    // setPreviewImage(src);
    // previewsetModalVisible(true);
    let originalFileUrl = await getPhotoUrlDetails(
      file.name.replace('_thumb', ''),
    );
    setPreviewImage(originalFileUrl);
    previewsetModalVisible(true);
  };

  const showDeleteConfirm = async (file, index) => {
    setModalVisible(true);
    setFileToRemove(file);
    setImageIndex(index);
  };
  const handleOk = async () => {
    setModalVisible(false);
    const requestBody = {
      memberUUID: localStorage.getItem('memberID'),
      fileName: fileToRemove.name,
      index: imageIndex,
    };

    try {
      const response = await callApi(() =>
        profileService.deleteImage(requestBody)
      );

      if (response.message === 'OK') {
        fetchconfigureDetails();
        setSuccess({
          title: 'Image Deleted!',
          message: 'Your Image has been Deleted Successfully!',
        });
        if (imageIndex == 0) {
          localStorage.setItem('profilePicture', 'undefined');
        }
      }
    } catch (error) {
      setError({
        title: 'An Error Occurred!',
        message: 'Your Image is not Deleted. Please Try Again',
      });
    }

    setFileToRemove(null);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setFileToRemove(null);
  };

  const handlepreviewOk = () => {
    setPreviewImage(''); // Clear the preview image when modal is closed
    setTimeout(() => {
      previewsetModalVisible(false);
    }, 50);
  };
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
  };
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const openSelectionform1 = () => {
    const fileInput = document.getElementById('profileimage');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input element with id 'profileimage' not found");
    }
  };
  const openSelectionform2 = () => {
    const fileInput = document.getElementById('image1');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input element with id 'profileimage' not found");
    }
  };
  const openSelectionform3 = () => {
    const fileInput = document.getElementById('image2');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input element with id 'profileimage' not found");
    }
  };

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <Profilecard />

      <Container
        className={` ${configurationData.memberstatuscode === 3 || configurationData.memberstatuscode === 0 ? 'pointer_event_none' : ''}`}

      >
        {/* <div className='row'> */}
        {/* <div className='col-lg-9 col-md-9'> */}
        <section className="section_user_home_page" >
          <Container>

            <Row className="inner_row mb-3  mt-1">
              <Col md={12} className=" p-0 mt-1" xs={12}>
                <Row className=" m-0  inner_row_personal">
                  <div className='d-flex align-items-center'>
                    <img
                      src={photosIcon}
                      alt="Photo Icon"
                      className="custom-photo-icon me-2"
                    />
                    <h5 className="title title_bar">Upload Your Photos</h5>
                  </div>
                  <Col md={12} className=" text-center">
                    <h4 className="heading mb-3">
                      {configuredata.firstname}, help others to recognize
                      you !
                    </h4>
                  </Col>

                  <Col md={4} className="text-center">
                    <div
                      className="fieldset shadow"
                      style={{ background: `white` }}
                    >
                      <div
                        className="legend text-left text-primary"
                        style={{ top: `-4px` }}
                      >
                        Upload Profile Photo
                      </div>
                      <div className="uploadimg_div mt-2 ">
                        <ImgCrop
                          showGrid
                          rotationSlider
                          showReset
                          remove={false}
                          aspect={1 / 1}
                        >
                          <Upload
                            id="profileimage"
                            listType="picture-card"
                            fileList={file1}
                            onChange={handleChange(setFile1, 0)}
                            onPreview={onPreview}
                            customRequest={(file) =>
                              handleUploadImage(file, 0)
                            }
                            onRemove={() => {
                              showDeleteConfirm(file1[0], 0);
                            }}
                          >
                            {file1.length < 1 && (
                              <div>
                                <DefaultPicture
                                  gendercode={configuredata.gendercode}
                                />
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </div>
                      <div className="text-center">
                        {file1.length < 1 && (
                          <Button
                            onClick={openSelectionform1}
                            icon={<UploadOutlined />}
                          >
                            Upload
                          </Button>
                        )}
                        {file1.length > 0 && (
                          <Button
                            onClick={() => {
                              showDeleteConfirm(file1[0], 0);
                            }}
                            style={{ marginLeft: '5px' }}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col md={8} className="text-center">
                    <div
                      className="fieldset shadow"
                      style={{ background: `white` }}
                    >
                      <div
                        className="legend text-left text-primary"
                        style={{ top: `-4px` }}
                      >
                        Upload More Photos
                      </div>
                      <Row>
                        <Col md={6} className="text-center">
                          <div className="uploadimg_div mx-auto mt-2">
                            <ImgCrop
                              showGrid
                              rotationSlider
                              aspect={1 / 1}
                              showReset
                              remove={false}
                            >
                              <Upload
                                id="image1"
                                listType="picture-card"
                                fileList={file2}
                                onChange={handleChange(setFile2, 1)}
                                onPreview={onPreview}
                                customRequest={handleUploadImage}
                                onRemove={(file) =>
                                  showDeleteConfirm(file, 1)
                                }
                              >
                                {file2.length < 1 && (
                                  <div>
                                    <DefaultPicture
                                      gendercode={
                                        configuredata.gendercode
                                      }
                                    />
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                          <div className="text-center">
                            {file2.length < 1 && (
                              <Button
                                onClick={openSelectionform2}
                                icon={<UploadOutlined />}
                              >
                                Upload
                              </Button>
                            )}
                            {file2.length > 0 && (
                              <Button
                                onClick={() => {
                                  showDeleteConfirm(file2[0], 1);
                                }}
                                style={{ marginLeft: '5px' }}
                              >
                                Delete
                              </Button>
                            )}
                          </div>
                        </Col>

                        <Col
                          md={6}
                          className="sqs-block-html text-center"
                        >
                          <div className="uploadimg_div mx-auto mt-2">
                            <ImgCrop
                              showGrid
                              rotationSlider
                              aspect={1 / 1}
                              showReset
                              remove={false}
                            >
                              <Upload
                                id="image2"
                                listType="picture-card"
                                fileList={file3}
                                onChange={handleChange(setFile3, 2)}
                                onPreview={onPreview}
                                customRequest={handleUploadImage}
                                onRemove={(file) =>
                                  showDeleteConfirm(file, 2)
                                }
                              >
                                {file3.length < 1 && (
                                  <div>
                                    <DefaultPicture
                                      gendercode={
                                        configuredata.gendercode
                                      }
                                    />
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                          <div className="text-center">
                            {file3.length < 1 && (
                              <Button
                                onClick={openSelectionform3}
                                icon={<UploadOutlined />}
                              >
                                Upload
                              </Button>
                            )}
                            {file3.length > 0 && (
                              <Button
                                onClick={() => {
                                  showDeleteConfirm(file3[0], 2);
                                }}
                                style={{ marginLeft: '5px' }}
                              >
                                Delete
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Row className='mx-2'>
                    {/* Personal Details Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                          <img
                            src={personalInfoIcon}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                          <h5 className="title title_bar">Fill Personal Details</h5>
                        </div>

                        <div
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("personal")}>
                          {visibleSection === "personal" ? "Close" : "Edit"}
                        </div>

                      </Col>
                      {visibleSection === "personal" && (
                        <Col md={12} xs={12} className="p-0 mt-2">
                          <PersonalDetails />
                        </Col>
                      )}
                    </Row>

                    {/* Career Preference Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                      <div className='d-flex align-items-center'>
                          <img
                            src={careerInfo}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                        <h5 className="title title_bar me-2">Career</h5>
                        </div>
                        <div
                          color="primary"
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("career")}
                        >
                          {visibleSection === "career" ? "Close" : "Edit"}
                        </div>
                      </Col>
                      {visibleSection === "career" && (
                        <Col md={12} xs={12} className="p-0 mt-2">
                          <CareerDetails />
                        </Col>
                      )}
                    </Row>

                    {/* Educational Preference Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                      <div className='d-flex align-items-center'>
                          <img
                            src={educationIcon}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                        <h5 className="title title_bar me-2">Educational</h5>
                        </div>
                        <div
                          color="primary"
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("education")}
                        >
                          {visibleSection === "education" ? "Close" : "Edit"}
                        </div>
                      </Col>
                      {visibleSection == 'education' && (
                        <Col md={12} xs={12} className=" p-0">
                          <EducationDetails />
                        </Col>
                      )}
                    </Row>


                    {/* Regional Preference Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                      <div className='d-flex align-items-center'>
                          <img
                            src={locationIcon}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                        <h5 className="title title_bar me-2">Region / Location</h5>
                        </div>
                        <div
                          color="primary"
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("regional")}
                        >
                          {visibleSection === "regional" ? "Close" : "Edit"}
                        </div>
                      </Col>
                      {visibleSection == 'regional' && (
                        <Col md={12} xs={12} className=" p-0">
                          <RegionalDetails />
                        </Col>
                      )}
                    </Row>

                    {/*  Spiritual Preference Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                      <div className='d-flex align-items-center'>
                          <img
                            src={spiritualIcon}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                        <h5 className="title title_bar me-2"> Spiritual</h5>
                        </div>
                        <div
                          color="primary"
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("spiritual")}
                        >
                          {visibleSection === "spiritual" ? "Close" : "Edit"}
                        </div>
                      </Col>
                      {visibleSection == 'spiritual' && (
                        <Col md={12} xs={12} className=" p-0">
                          <SpiritualDetails />
                        </Col>
                      )}
                    </Row>


                    {/*LifeStyle Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                      <div className='d-flex align-items-center'>
                          <img
                            src={lifestyleIcon}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                        <h5 className="title title_bar me-2">Lifestyle</h5>
                        </div>
                        <div
                          color="primary"
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("lifestyle")}
                        >
                          {visibleSection === "lifestyle" ? "Close" : "Edit"}
                        </div>
                      </Col>
                      {visibleSection == 'lifestyle' && (
                        <Col md={12} xs={12} className=" p-0">
                          <LifestyleDetails />
                        </Col>
                      )}
                    </Row>


                    {/*   Family Background Preference Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                      <div className='d-flex align-items-center'>
                          <img
                            src={familyIcon}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                        <h5 className="title title_bar me-2"> Family Background </h5>
                        </div>
                        <div
                          color="primary"
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("family")}
                        >
                          {visibleSection === "family" ? "Close" : "Edit"}
                        </div>
                      </Col>
                      {visibleSection == 'family' && (
                        <Col md={12} xs={12} className=" p-0">
                          <FamilyDetails />
                        </Col>
                      )}
                    </Row>

                    {/*   Socials Section */}
                    <Row className="inner_row mt-4">
                      <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                      <div className='d-flex align-items-center'>
                          <img
                            src={socialsIcon}
                            alt="Photo Icon"
                            className="custom-photo-icon me-2"
                          />
                        <h5 className="title title_bar me-2">Socials</h5>
                        </div>
                        <div
                          color="primary"
                          size="sm"
                          className="edit-button"
                          onClick={() => toggleVisibility("socials")}
                        >
                          {visibleSection === "socials" ? "Close" : "Edit"}
                        </div>
                      </Col>
                      {visibleSection == 'socials' && (
                        <Col md={12} xs={12} className=" p-0">
                          <SocialDetails />
                        </Col>
                      )}
                    </Row>

                    {/*   Connections Section */}
                    {/* <Row className="inner_row mt-4">
              <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                <h5 className="title title_bar me-2">Connections</h5>
                <div
                  color="primary"
                  size="sm"
                  className="edit-button"
                  onClick={() => toggleVisibility("connections")}
                >
                  {visibleSection === "connections" ? "Close" : "Edit"}
                </div>
              </Col>
              {visibleSection == 'connections' && (
                <Col md={12} xs={12} className=" p-0">
                <h5 className='text-center'>To Be Designed</h5>
                </Col>
              )}
            </Row> */}

                    {/*   Upload Your Ads*/}
                    {/* <Row className="inner_row mt-4">
                          <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                            <h5 className="title title_bar me-2">Upload Your Ads</h5>
                            <div
                              color="primary"
                              size="sm"
                              className="edit-button"
                              onClick={() => toggleVisibility("uploadAds")}
                            >
                              {visibleSection === "uploadAds" ? "Close" : "Edit"}
                            </div>
                          </Col>
                          {visibleSection == 'uploadAds' && (
                            <Col md={12} xs={12} className=" p-0">
                              <UploadAds />
                            </Col>
                          )}
                        </Row> */}
                  </Row>
                  <Row className="mt-5 text-center">
                    <p className="text-center">
                      <h6>
                        On <span className="text-primary">MettaMate</span>
                        , we require members to use their real identities,
                        so take or upload a photo of yourself. Then crop,
                        filter and adjust it to perfection to have{' '}
                        <span className="text-primary">WoW</span> effect !
                      </h6>
                    </p>
                  </Row>
                </Row>
              </Col>
            </Row>



          </Container>
        </section>
        {/* </div> */}
        {/* </div> */}
      </Container>

      <Modal
        title="Confirm Deletion"
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        onClose={handleOk}
      >
        Are you sure you want to delete this image?
      </Modal>

      <Modal
        title="Avatar Preview"
        open={previewmodalVisible}
        onOk={handlepreviewOk}
        okText="Close"
        onClose={handlepreviewOk}
        onCancel={handlepreviewOk}
      >
        <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </React.Fragment>
  );
};

export default UserProfileEditFunction;
